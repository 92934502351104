import axios from 'axios';

export async function deleteUser(id, token) {
  let config = {
    method: 'delete',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/users/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };
  let response = await axios.request(config).then((response) => {
    return response;
  }).catch((error) => {
    return error.response;
  });
  return response;
}
