import React, { useState } from 'react';
import propTypes from 'prop-types';
import {
  ChevronDownIcon
} from '@heroicons/react/24/solid';

export const Faq = ({ data, className }) => {
  Faq.propTypes = {
    data: propTypes.object.isRequired,
    className: propTypes.string
  };

  const [open, setOpen] = useState(
    data.questions.map((_, index) => index === 0)
  );

  const toggleDropdown = (value) => {
    if (open.includes(value)) {
      setOpen(open.filter((item) => item !== value));
    } else {
      setOpen([...open, value]);
    }
  };

  return (
    <div className={`flex flex-col ${className} w-full`}>
      <h1 className='text-2xl font-medium mb-6 text-white text-center'>
        {data.title}
      </h1>
      <div className='flex flex-col gap-4 w-full'>
        {data.questions.map((item, index) => (
          <div key={index} className='bg-gray-700 rounded-lg pr-4 md:pr-6 w-full'>
            <button
              className='flex flex-row justify-between items-center
              w-full gap-2'
              onClick={() => toggleDropdown(index + 1)}>
              <h2 className='text-lg font-medium text-white py-4 pl-4 md:py-6
              md:pl-6 text-left'>
                {item.question}
              </h2>
              <div>
                <ChevronDownIcon className={`w-6 h-6 text-white transition-all
                ${open.includes(index + 1) ? 'transform rotate-180' : ''}`} />
              </div>
            </button>
            {open.includes(index + 1) && (
              <div className='flex flex-col gap-4'>
                <hr className='border-gray-600 mx-4 md:mx-6 mt-0' />
                <p className='text-gray-200 text-base font-regular pb-4 md:pb-6 px-4
                md:px-6 text-left'>
                  {item.answer}
                </p>
              </div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};
