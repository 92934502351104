import axios from 'axios';

export async function updatePoll(poll, token) {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    data: {
      title: poll.title,
      description: poll.description,
      ended: poll.ended,
      timestamp_close: poll.timestamp_close,
      image: poll.image
    },
    url: `https://api.coodo.xyz/polls/${poll.pollID}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  let response = await axios.request(config).then((response) => {
    return response;
  }).catch((error) => {
    return error.response;
  });

  return response;
}
