import React, { useState, useContext, useEffect } from 'react';
import arrow from '../../assets/icons8-up-96.png';
import { Context } from '../SidebarContext';
import { BetaBadge, NewBadge, PremiumBadge } from '../badges';
import { AuthUser, hasScope } from '../../utils';
import {
  HomeIcon,
  ChartBarIcon,
  BookmarkIcon,
  Cog8ToothIcon,
  ChartPieIcon,
  UserIcon,
  Battery50Icon,
  CreditCardIcon,
  CommandLineIcon,
  UserGroupIcon,
  ChevronDoubleLeftIcon,
  ArrowRightOnRectangleIcon,
  ClockIcon
} from '@heroicons/react/24/solid';
import logo from '../../assets/logo.png';
import { Button } from '../Button';
import iconDiscord from '../../assets/Discord_Logo.svg';
import { getLanguage } from '../../language/getLanguage';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { OnlyPremiumModal } from '../modal';

export const Sidebar = () => {
  const { SidebarState, SetSidebarState } = useContext(Context);
  const [clicked, setClicked] = useState(null);
  const [premiumPopup, setPremiumPopup] = useState(false);
  const user = AuthUser();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  let sectionsData = [
    {
      'name': getLanguage('sidebar_polls'),
      'options': [
        {
          'name': getLanguage('sidebar_polls_home'),
          'href': '/',
          'icon': <HomeIcon className='w-5 h-5 mr-1'/>
        },
        {
          'name': getLanguage('sidebar_polls_my'),
          'href': '/user/polls',
          'icon': <ChartBarIcon className='w-5 h-5 mr-1'/>
        },
        {
          'name': getLanguage('sidebar_recurrentpolls_my'),
          'href': '/user/recurrentpolls',
          'beta': true,
          'icon': <ClockIcon className='w-5 h-5 mr-1'/>
        },
        {
          'name': getLanguage('sidebar_polls_favorites'),
          'href': '/user/favorites',
          'new': true,
          'icon': <BookmarkIcon className='w-5 h-5 mr-1'/>
        }
      ]
    },
    {
      'name': getLanguage('sidebar_profile'),
      'options': [
        {
          'name': getLanguage('sidebar_profile_account'),
          'href': '/user',
          'icon': <UserIcon className='w-5 h-5 mr-1'/>
        },
        {
          'name': getLanguage('sidebar_profile_pu'),
          'href': '/usage',
          'icon': <Battery50Icon className='w-5 h-5 mr-1'/>
        },
        {
          'name': getLanguage('sidebar_profile_billing'),
          'href': '/billing',
          'icon': <CreditCardIcon className='w-5 h-5 mr-1'/>
        }
      ]
    },
    {
      'name': getLanguage('sidebar_global'),
      'options': [
        {
          'name': getLanguage('sidebar_global_users'),
          'href': '/users',
          'icon': <UserGroupIcon className='w-5 h-5 mr-1'/>
        }
      ]
    },
    {
      'name': getLanguage('sidebar_admin'),
      'options': [
        {
          'name': getLanguage('sidebar_admin_roles'),
          'href': '/admin/roles',
          'icon': <Cog8ToothIcon className='w-5 h-5 mr-1'/>
        },
        {
          'name': getLanguage('sidebar_admin_statistics'),
          'href': '/admin/stats',
          'icon': <ChartPieIcon className='w-5 h-5 mr-1'/>
        },
        {
          'name': getLanguage('sidebar_admin_logs'),
          'href': '/admin/logs',
          'icon': <CommandLineIcon className='w-5 h-5 mr-1'/>
        }
      ]
    }
  ];
  const [sections, setSections] = useState(sectionsData
    .filter((section) => section.name !== 'ADMIN'));

  const env = {
    clientId: '823271140410916986',
    redirectUri: 'https://api.coodo.xyz/authorize/callback',
    scopes: [ 'identify', 'guilds', 'guilds.members.read', 'email' ]
  };

  const authorizeUrl = `https://discordapp.com/api/oauth2/authorize?client_id=${
    env.clientId
  }&redirect_uri=${
    encodeURIComponent(env.redirectUri)
  }&response_type=code&scope=${env.scopes.join('%20')}`;

  const handleLogin = () => {
    const state = searchParams.get('state');
    if (state)
      window.location.href = `${authorizeUrl}&state=${state}`;
    else
      window.location.href = authorizeUrl;
  };

  useEffect(() => {
    const fetch = async () => {
      if (await hasScope(user, 'ADMIN_PANEL') === true)
        setSections(sectionsData);
    };
    if (user)
      fetch();
  }, [user]);

  const [open, setOpen] = useState(
    sections.map((_, index) => index + 1)
  );

  const handleSidebar = () => {
    SetSidebarState(!SidebarState);
  };

  useEffect(() => {
    if (!clicked) return;
    if (!clicked.premium) {
      navigate(clicked.href);
      SetSidebarState(!SidebarState);
      setClicked(null);
      return;
    }
    if (user?.roles?.find((r) => r.id === 250)) {
      navigate(clicked.href);
      SetSidebarState(!SidebarState);
      setClicked(null);
      return;
    }
    setPremiumPopup(true);
    setClicked(null);
  }, [clicked]);

  const toggleDropdown = (value) => {
    if (open.includes(value)) {
      setOpen(open.filter((item) => item !== value));
    } else {
      setOpen([...open, value]);
    }
  };

  let className = '';
  if (SidebarState)
    className = '-translate-x-full md:-translate-x-[full]';
  else
    className = 'translate-x-0 md:translate-x-0';

  return (
    <div className='flex w-screen sm:w-auto fixed sm:relative z-50 top-0
    sm:top-auto'>
      {premiumPopup && (
        <OnlyPremiumModal title={getLanguage('only_premium')}
          subtitle={getLanguage('only_premium_subtitle')}
          close={() => setPremiumPopup(false)}>
          <div className='flex flex-row gap-3 w-full justify-between
          flex-wrap sm:flex-nowrap'>
            <Button type='premium' className='w-full'
              onClick={() => {
                navigate('/usage');
                SetSidebarState(!SidebarState);
                setPremiumPopup(false);
              }}>
              {getLanguage('get_premium')}
            </Button>
            <Button type='secondary' className='w-full'
              onClick={() => setPremiumPopup(false)}>
              {getLanguage('close')}
            </Button>
          </div>
        </OnlyPremiumModal>
      )}
      <div className={`bg-secondary-500 border-r border-gray-500 z-30
      absolute h-screen min-h-full overflow-y-auto scrollbar-hide w-full sm:w-72
      transform lg:!translate-x-0 lg:relative transition-all duration-400
      ${className}`}>
        <div className='sm:hidden flex flex-row items-center justify-center
        px-7 mt-4'>
          <button className='w-10 h-10' onClick={handleSidebar}>
            <ChevronDoubleLeftIcon className='w-6 h-6 text-gray-100'/>
          </button>
          <div className='container flex flex-row items-center gap-3 w-fit
          cursor-pointer mx-auto' onClick={() => navigate('/')}>
            <div>
              <div className='bg-main-500 rounded-full w-12 h-12
              absolute drop-shadow-glow -z-10 animate-pulse'/>
              <img src={logo} alt='StrawPoll logo' className='w-12 h-12
              rounded-full' />
            </div>
            <h1 className='text-white text-3xl font-bold m-0'>
              StrawPoll
            </h1>
          </div>
        </div>
        <ul className='rounded-lg py-4 top-12'>
          <div>
            {sections.map((section, index) => (
              <li key={section.name} className='py-2 px-3'>
                <button className='font-bold text-white px-3 text-xs flex
                  flex-row justify-between items-center w-full py-2'
                onClick={() => toggleDropdown(index + 1)}>{section.name}
                  <img
                    src={arrow}
                    alt='Arrow'
                    className={`w-3 h-3 filter invert-[.70] ${
                      open.includes(index + 1) ? 'transform rotate-180' : ''
                    }`}/>
                </button>
                {open.includes(index + 1) && (
                  <ul>
                    {section.options.map((option) => (
                      <li key={option.name} onClick={() => setClicked(option)}
                        onMouseDown={(e) => {
                          e.preventDefault();
                          if (e.button !== 1)
                            return;
                          window.open(option.href, '_blank');
                        }}
                        className='py-2 pl-3 hover:bg-tertiary cursor-pointer
                        opacity-80 font-medium rounded-md
                        text-base transition-colors text-white'>
                        <a className='flex flex-row items-center truncate'>
                          {option.icon && (
                            <div className='h-5 w-5 mr-1 flex-shrink-0'
                              style={{ color: option.color }}>
                              {option.icon}
                            </div>
                          )} {option.name}
                          {option.new && (
                            <NewBadge className='ml-2' />
                          )}
                          {option.premium && (
                            <PremiumBadge className='ml-2' />
                          )}
                          {option.beta && (
                            <BetaBadge className='ml-2' />
                          )}
                        </a>
                      </li>
                    ))}
                  </ul>
                )}
              </li>
            ))}
          </div>
        </ul>
        <hr className='border-gray-400 my-4 mx-4'/>
        {user ? (
          <div className='py-4 px-6 flex flex-row'>
            {user.avatarURL ? (
              <img src={user.avatarURL} alt='User avatar'
                className='w-12 h-12 rounded-md'/>
            ) : (
              <div className='rounded-md w-12 h-12
              bg-secondary-480 flex items-center flex-shrink-0'>
                <p className='text-white text-2xl font-bold
                text-center m-auto'>
                  {user.username[0]}
                </p>
              </div>
            )}
            <div className='flex flex-col ml-3'>
              <h1 className='text-white font-medium m-0'>
                {user.username}
              </h1>
              <p className='text-gray-200 text-xs m-0 mt-1'>
                {user.id}
              </p>
            </div>
            <button className='ml-auto hover:scale-110' onClick={() => {
              navigate('/logout');
            }}>
              <ArrowRightOnRectangleIcon className='w-6 h-6 text-gray-100
              hover:text-red-500'/>
            </button>
          </div>
        ) : (
          <div className='py-4 px-7 flex flex-row'>
            <Button type='primary' onClick={() => handleLogin()} className='bg-opacity-0
            border-2 border-main-500 w-full hover:bg-opacity-20 px-[0px]
            hover:bg-main-500'>
              <img src={iconDiscord}
                alt='Discord' className='w-6 h-6 mr-2'/>
              {getLanguage('login_w_discord')}
            </Button>
          </div>
        )}
      </div>
    </div>
  );
};
