import React from 'react';
import {
  CheckCircleIcon
} from '@heroicons/react/24/solid';
import { Button, Container } from '../../components';
import { Layout } from '../Layout';

export const Success = () => {
  const location = window.location.href;

  const plan = location.split('?plan=')[1]?.split('&')[0];

  return (
    <Layout>
      <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10 min-h-screen'>
        <div className='flex flex-col'>
          <h1 className='text-white text-3xl font-bold m-0'>
            Checkout
          </h1>
          <p className='text-gray-200 text-base'>
            You are almost there!
          </p>
        </div>
        <Container>
          <div className='flex flex-row justify-center gap-2'>
            <CheckCircleIcon className='w-32 h-32 text-green-500' />
          </div>
          <div className='flex flex-col justify-center text-center'>
            <h1 className='text-white text-2xl font-bold m-0'>
              Thank you for your purchase!
            </h1>
            <p className='text-gray-200 text-base mb-5'>
              You just bought the {plan === 'month' ? 'monthly ' : 'lifetime '}
              plan.
            </p>
            <Button name='Go to dashboard' type='primary' href='/' />
          </div>
        </Container>
      </div>
    </Layout>
  );
};
