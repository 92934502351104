import React, { useEffect, useState } from 'react';
import CustomAlert from '../../Alert';
import PropType from 'prop-types';
import { PollsTableSkeleton } from './PollsTableSkeleton';
import moment from '../../../utils/moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ru';
import 'moment/locale/ro';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  CalendarDaysIcon,
  ChartBarIcon,
  EyeIcon,
  EyeSlashIcon,
  LockClosedIcon,
  LockOpenIcon,
  PencilIcon, PlusCircleIcon, QuestionMarkCircleIcon, ShareIcon, TrashIcon
} from '@heroicons/react/24/solid';
import { getLanguage } from '../../../language/getLanguage';
import { useNavigate } from 'react-router-dom';
import { SectionTitle } from '../../SectionTitle';
import BannerRecurrentPoll from '../../banners/BannerRecurrentPoll';
import PopHover from '../../Popover';
import { deletePoll } from '../../../utils';
import Option from './Option';
import Cookies from 'universal-cookie';
import { ShareModal } from '../../modal';

const cookies = new Cookies();
const language = cookies.get('language') ?? 'en';
moment.locale(language);
moment.relativeTimeThreshold('ss', 0);

export const PollsTable = ({
  polls,
  title,
  description,
  emptyMessage,
  loading,
  user
}) => {
  PollsTable.propTypes = {
    polls: PropType.array,
    title: PropType.string,
    description: PropType.string,
    emptyMessage: PropType.string,
    loading: PropType.bool,
    user: PropType.object
  };
  const navigate = useNavigate();

  const [active, setActive] = useState(0);
  const [pages, setPages] = useState([]);
  const [shareModal, setShareModal] = useState(false);

  const itemsPerPage = 7;

  const next = () => {
    setActive(prev => Math.min(prev + 1, Math.ceil(polls.length / itemsPerPage) - 1));
  };

  const prev = () => {
    setActive(prev => Math.max(prev - 1, 0));
  };

  useEffect(() => {
    if (!polls)
      return;

    const totalPages = Math.ceil(polls.length / itemsPerPage);
    const paginatedPolls = Array.from({ length: totalPages }, (_, index) =>
      polls.slice(index * itemsPerPage, (index + 1) * itemsPerPage)
    );
    setPages(paginatedPolls);
  }, [polls]);

  if (loading) return (
    <div className='flex flex-col flex-1'>
      <SectionTitle title={title} subtitle={description} />
      <BannerRecurrentPoll />
      <PollsTableSkeleton height={6} />
    </div>
  );

  if (!polls) return (
    <div className='flex flex-col flex-1'>
      <SectionTitle title={title} subtitle={description} />
      <BannerRecurrentPoll />
      <CustomAlert type='alert' button='logout'
        title='Failed to load your polls !'
        message='An error occurred. Please try reconnecting. Ensure that you
        have a stable internet connection. If the problem persists, please
        contact our support team for further assistance.' />
    </div>
  );

  if (polls && polls.length < 1) return (
    <div className='flex flex-col flex-1'>
      <SectionTitle title={title} subtitle={description} />
      <BannerRecurrentPoll />
      <CustomAlert type='info' title={getLanguage('nothing_to_display')}
        message={emptyMessage} />
    </div>
  );

  return (
    <div className='flex flex-col flex-1'>
      <SectionTitle title={title} subtitle={description} />
      <BannerRecurrentPoll />
      {shareModal && (
        <ShareModal
          close={() => setShareModal(false)}
          title={getLanguage('share')}
          options={{
            twitter: true,
            mail: true,
            link: document.location.href
          }}
        />
      )}
      <div className='justify-center flex flex-col'>
        <ul className='flex flex-col gap-3'>
          {pages[active] && pages[active].map((poll) => (
            <li key={poll.pollID} className='flex bg-secondary-500
            rounded-lg h-fit border border-secondary-480
            hover:border-main-500 transition-all duration-200
            hover:cursor-pointer' id={`li-${poll.pollID}`}>
              <div className='flex h-full w-full p-4 pr-0 flex-row
              gap-5 lg:gap-0' onClick={() => {
                navigate(`/polls/${poll.pollID}/results`);
              }}>
                <div className='flex flex-col flex-auto gap-2'>
                  <div className='text-white text-base font-medium'>
                    {poll.title}
                  </div>
                  <div className='text-gray-200 text-sm font-medium'>
                    {moment(poll.timestamp * 1000).format('lll')}
                  </div>
                </div>
                <div className='flex-row flex-shrink-0 hidden sm:flex gap-5'>
                  <Option tooltip={poll.timestamp_close ? (getLanguage('end') + ' ' +
                    moment(poll.timestamp_close * 1000).fromNow()) :
                    getLanguage('no_deadline')}>
                    <CalendarDaysIcon className='w-4 h-4' />
                    {poll.timestamp_close ? (
                      moment(poll.timestamp_close * 1000).fromNow(true)
                    ) : (
                      'N/A'
                    )}
                  </Option>
                  <Option tooltip={poll.ended ? getLanguage('tooltip_ended') :
                    getLanguage('tooltip_active')} className='hidden md:flex'>
                    {poll.ended === true ? (
                      <>
                        <LockClosedIcon className='w-4 h-4' />
                        {getLanguage('ended')}
                      </>
                    ) : (
                      <>
                        <LockOpenIcon className='w-4 h-4' />
                        {getLanguage('active')}
                      </>
                    )}
                  </Option>
                  <Option tooltip={poll.anonymous ? getLanguage('anonymous_votes') :
                    getLanguage('public_votes')} className='hidden lg:flex'>
                    {poll.anonymous === true ? (
                      <>
                        <EyeSlashIcon className='w-4 h-4' />
                        {getLanguage('anonymous')}
                      </>
                    ) : (
                      <>
                        <EyeIcon className='w-4 h-4' />
                        {getLanguage('public')}
                      </>
                    )}
                  </Option>
                  <Option tooltip={poll.hide_results ? getLanguage('results_hidden') :
                    getLanguage('results_visible')} className='hidden xl:flex'>
                    <QuestionMarkCircleIcon className='w-4 h-4' />
                    {poll.hide_results === true ? (
                      getLanguage('hidden')
                    ) : (
                      getLanguage('visible')
                    )}
                  </Option>
                </div>
              </div>
              <div className='flex-col flex-shrink-0 text-center
                flex justify-center w-24 lg:w-24 items-center'>
                <PopHover values={[
                  {
                    name: getLanguage('edit'),
                    icon: <PencilIcon className='w-5 h-5' />,
                    onClick: () => {
                      navigate(`/polls/${poll.pollID}/edit`);
                    }
                  },
                  {
                    separator: true,
                    name: getLanguage('results'),
                    icon: <ChartBarIcon className='w-5 h-5' />,
                    onClick: () => {
                      navigate(`/polls/${poll.pollID}/results`);
                    }
                  },
                  {
                    name: getLanguage('Vote'),
                    icon: <PlusCircleIcon className='w-5 h-5' />,
                    onClick: () => {
                      navigate(`/polls/${poll.pollID}`);
                    }
                  },
                  {
                    name: getLanguage('share'),
                    icon: <ShareIcon className='w-5 h-5' />,
                    closeDelay: 0,
                    onClick: function () {
                      setShareModal(true);
                      return 1;
                    }
                  },
                  {
                    separator: true,
                    name: getLanguage('delete'),
                    icon: <TrashIcon className='w-5 h-5' />,
                    colors: {
                      danger: true
                    },
                    onClick: async () => {
                      const liItem = document.getElementById(
                        `li-${poll.pollID}`
                      );
                      const result = await deletePoll(poll.pollID, user.token);
                      if (result.status === 200) {
                        liItem.classList.add('hidden');
                        const newPages = [...pages];
                        newPages[active] = newPages[active].filter(
                          (item) => item.pollID !== poll.pollID
                        );
                        setPages(newPages);
                        return 1;
                      }
                      return 0;
                    }
                  }
                ]} />
              </div>
            </li>
          ))}
        </ul>
        <div className='flex mx-auto mt-6'>
          <div className='flex gap-4 items-center'>
            <button className='flex flex-row items-center gap-2 bg-gray-480
            disabled:bg-gray-480 disabled:opacity-50 hover:bg-gray-440
            transition-all duration-300 disabled:cursor-not-allowed
            rounded-lg px-4 py-2 text-white font-medium text-bas'
            onClick={prev} disabled={active === 0}>
              <ArrowLeftIcon className='w-4 h-4 filter invert-[.50]'/>
            </button>
            <h3 className='font-regular text-gray-100 m-0'>
              {getLanguage('page')}
              <strong className='text-main-500'> {active + 1}</strong>
              {' '}{getLanguage('page_of')}{' '}
              <strong className='text-main-500'>
                {pages.length > 0 ? pages.length : 1}
              </strong>
            </h3>
            <button className='flex flex-row items-center gap-2 bg-gray-480
            disabled:bg-gray-480 disabled:opacity-50 hover:bg-gray-440
            transition-all duration-300 disabled:cursor-not-allowed
            rounded-lg px-4 py-2 text-white font-medium text-bas'
            onClick={next} disabled={!pages[active + 1]}>
              <ArrowRightIcon className='w-4 h-4 filter invert-[.50]'/>
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

