import React from 'react';
import { Login } from './Login';
import { GetStarted } from './GetStarted';
import { Layout } from '../Layout';
import { AuthUser } from '../../utils';

export const Home = () => {
  const user = AuthUser();

  return (
    <Layout>
      {user ? (
        <GetStarted />
      ) : (
        <Login />
      )}
    </Layout>
  );
};
