import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  CheckIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';
import CustomAlert from '../Alert';
import { getLanguage } from '../../language/getLanguage';
import Container from '../Container';

export const PollUsers = ({ poll }) => {
  PollUsers.propTypes = {
    poll: PropType.object
  };

  const [namesType, setNamesType] = useState('username');

  let users = [];

  poll.buttons.forEach(button => {
    button.ids.forEach(user => {
      if (!users.filter(e => e.id === user.id).length)
        users.push(user);
    });
  });

  return (
    users.length < 1 ? (
      <CustomAlert type='info' title={getLanguage('nothing_to_display')}
        message={getLanguage('no_user_voted')} />
    ) : (
      <Container>
        {users.every(user => Object.prototype
          .hasOwnProperty.call(user, 'server_name')) && (
          <div className='flex flex-row items-center gap-1 p-1 rounded-md bg-secondary-480
          mx-auto w-fit'>
            <button className={`flex flex-row items-center gap-2 p-2 rounded-md
            transition-colors duration-300 text-white ${namesType === 'username' ?
            'bg-main-500' : 'bg-secondary-480 hover:bg-secondary-500 '}`}
            onClick={() => setNamesType('username')}>
              {getLanguage('usernames')}
            </button>
            <button className={`flex flex-row items-center gap-2 p-2 rounded-md
            transition-colors duration-300 text-white ${namesType === 'server_name' ?
            'bg-main-500' : 'bg-secondary-480 hover:bg-secondary-500 '}`}
            onClick={() => setNamesType('server_name')}>
              {getLanguage('server_names')}
            </button>
          </div>
        )}
        <div className='relative overflow-x-auto'>
          <table className='w-full text-gray-200 text-base'>
            <thead>
              <tr className='border-b border-gray-400 column'>
                <th className='text-left text-lg text-gray-100 py-3 px-3'>
                  {getLanguage('username')}
                </th>
                {poll.buttons && poll.buttons.map((e, index) => (
                  <th key={index} className='text-center text-lg text-gray-100
                  py-3 px-3'>
                    {e.title}
                  </th>
                ))}
              </tr>
            </thead>
            <tbody>
              {users && users.map((user, index) => (
                <tr key={index} className='border-b border-gray-400'>
                  <td className='text-left text-lg text-gray-100 py-3 px-3'>
                    <a className='text-main-500 cursor-pointer underline
                    hover:text-main-480' href={`/users/${user.id}`}>
                      {namesType === 'username' ?
                        (user.username || user.id) : user.server_name}
                    </a>
                  </td>
                  {poll.buttons && poll.buttons.map((e, index) => (
                    <td key={index} className='text-center text-lg text-gray-100
                    py-3 px-3'>
                      {e.ids.filter(e => e.id === user.id).length > 0 ? (
                        <CheckIcon className='w-7 h-7 text-green-500 mx-auto' />
                      ) : (
                        <XMarkIcon className='w-7 h-7 text-red-500 mx-auto' />
                      )}
                    </td>
                  ))}
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </Container>
    )
  );
};
