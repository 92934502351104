import axios from 'axios';

export async function updateUserState(state, token, user) {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/users/${user.id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: {
      disabled: state
    }
  };
  const response = await axios.request(config).then((response) => {
    return response;
  }).catch((err) => {
    return err.response;
  });
  return response;
}
