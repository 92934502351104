import React, { useState, useEffect } from 'react';
import { RecurrentPollsTable } from '../../../components/polls';
import { AuthUser, getUserRecurrentPolls } from '../../../utils';
import { Layout } from '../../Layout';
import { getLanguage } from '../../../language/getLanguage';

export const RecurrentPolls = () => {
  const [polls, setPolls] = useState([]);
  const user = AuthUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPolls = async () => {
      const polls = await getUserRecurrentPolls(user.id, user.token);
      if (polls)
        polls.reverse();
      setPolls(polls);
      setIsLoading(false);
    };

    setInterval(() => {
      fetchPolls();
    }, 30000);
    fetchPolls();
  }, [user]);

  return (
    <Layout>
      <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
        overflow-auto min-h-screen'>
        <RecurrentPollsTable polls={polls} title={getLanguage('my_recurrentpolls')}
          description={getLanguage('my_recurrentpolls_description')} user={user}
          emptyMessage={getLanguage('my_recurrentpolls_empty')} loading={isLoading} />
      </div>
    </Layout>
  );
};
