import React from 'react';
import PropType from 'prop-types';
import { useNavigate } from 'react-router-dom';
import {
  ChevronLeftIcon
} from '@heroicons/react/24/solid';

export const SectionTitle = ({ title, subtitle, goBack }) => {
  SectionTitle.propTypes = {
    title: PropType.string.isRequired,
    subtitle: PropType.string.isRequired,
    goBack: PropType.bool
  };

  const navigate = useNavigate();

  return (
    <div className='flex flex-row mb-5'>
      {goBack &&
        <button onClick={() => navigate(-1)} className='mr-5'>
          <ChevronLeftIcon className='h-5 w-5 text-white'/>
        </button>
      }
      <div>
        <h1 className='text-3xl font-bold text-white'>
          {title}
        </h1>
        <p className='text-base text-gray-200'>
          {subtitle}
        </p>
      </div>
    </div>
  );
};
