import React from 'react';
import PropTypes from 'prop-types';

export const SidebarSkeleton = ({ height }) => {
  SidebarSkeleton.propTypes = {
    height: PropTypes.number
  };

  return (
    <div className='py-2 px-3'>
      <div className='animate-pulse flex items-start justify-between'>
        <div>
          <div className='h-2.5 rounded-full bg-gray-300 w-24 mb-2.5'/>
          <div className='w-32 h-2 rounded-full bg-gray-400 mb-2'/>
          <div className='w-32 h-2 rounded-full bg-gray-400'/>
        </div>
        <div className='h-2.5 rounded-full bg-gray-400 w-12'/>
      </div>
      {Array.from({ length: height }, (_, i) => (
        <div key={i} className='animate-pulse flex items-start
        justify-between pt-8'>
          <div>
            <div className='h-2.5 rounded-full bg-gray-300 w-24 mb-2.5'/>
            <div className='w-32 h-2 rounded-full bg-gray-400 mb-2'/>
            <div className='w-32 h-2 rounded-full bg-gray-400'/>
          </div>
          <div className='h-2.5 rounded-full bg-gray-400 w-12'/>
        </div>
      ))}
      <div className='h-10'></div>
      {Array.from({ length: height + 2 }, (_, i) => (
        <div key={i} className='animate-pulse flex items-start
        justify-between pt-8'>
          <div>
            <div className='h-2.5 rounded-full bg-gray-300 w-24 mb-2.5'/>
            <div className='w-32 h-2 rounded-full bg-gray-400 mb-2'/>
            <div className='w-32 h-2 rounded-full bg-gray-400'/>
          </div>
          <div className='h-2.5 rounded-full bg-gray-400 w-12'/>
        </div>
      ))}
      <span className='sr-only'>Loading...</span>
    </div>
  );
};
