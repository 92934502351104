import React, { useEffect, useRef, useState } from 'react';

import PropTypes from 'prop-types';
import Cookies from 'universal-cookie';
import ReactCountryFlag from 'react-country-flag';
import {
  ChevronUpIcon,
  PlusCircleIcon
} from '@heroicons/react/24/solid';

export const LanguageSelector = ({ user }) => {
  LanguageSelector.propTypes = {
    user: PropTypes.object
  };

  const [isOpen, setIsOpen] = useState(false);

  const ref = useRef(null);

  useEffect(() => {
    const checkIfClickedOutside = (e) => {
      if (isOpen && ref.current && !ref.current.contains(e.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('click', checkIfClickedOutside);
    return () => {
      document.removeEventListener('click', checkIfClickedOutside);
    };
  }, [isOpen]);

  const cookies = new Cookies();
  const availableLanguages = [
    {
      code: 'en',
      name: 'English',
      img: <ReactCountryFlag countryCode='GB' svg style={{
        width: '100%',
        height: '100%',
        borderRadius: '100%',
        objectFit: 'cover'
      }} title='EN' />
    },
    {
      code: 'fr',
      name: 'Français',
      img: <ReactCountryFlag countryCode='FR' svg style={{
        width: '100%',
        height: '100%',
        borderRadius: '100%',
        objectFit: 'cover'
      }} title='FR' />
    },
    {
      code: 'ro',
      name: 'Română',
      img: <ReactCountryFlag countryCode='RO' svg style={{
        width: '100%',
        height: '100%',
        borderRadius: '100%',
        objectFit: 'cover'
      }} title='RO' />
    },
    {
      code: 'es',
      name: 'Español',
      img: <ReactCountryFlag countryCode='ES' svg style={{
        width: '100%',
        height: '100%',
        borderRadius: '100%',
        objectFit: 'cover'
      }} title='ES' />
    },
    {
      code: 'ru',
      name: 'Русский',
      img: <ReactCountryFlag countryCode='RU' svg style={{
        width: '100%',
        height: '100%',
        borderRadius: '100%',
        objectFit: 'cover'
      }} title='RU' />
    }
  ];
  const userLocale = user?.locale.split('-')[0] || user?.locale || 'en';
  const [language, setLanguage] = useState(cookies.get('language') || userLocale);
  if (!cookies.get('language') && userLocale) {
    setLanguage(userLocale);
    cookies.set('language', userLocale, { path: '/' });
  }

  const languageObj = availableLanguages.find((lang) => lang.code === language)
    || availableLanguages[0];

  return (
    <div ref={ref}>
      <button className='font-medium rounded-lg text-sm text-white
      text-center inline-flex
      items-center' onClick={() => {
        setIsOpen(!isOpen);
      }}>
        <div className='h-6 w-6'>
          {languageObj.img}
        </div>
        <p className='ml-2'>
          {languageObj.code.toUpperCase()}
        </p>
        <ChevronUpIcon className={`w-5 h-5 ml-1 ${isOpen && 'rotate-180'}`} />
      </button>
      {isOpen && (
        <div className={`z-10 rounded-lg shadow-sm w-fit
        bg-gray-700 absolute min-w-[180px] overflow-auto
        translate-y-1 -translate-x-24 sm:translate-x-0`}>
          <ul className='h-fit max-h-[300px] py-2 overflow-y-auto text-gray-200'>
            <li className='px-2'>
              {availableLanguages.map((lang) => (
                <ul key={lang.code} className='flex items-center w-full px-2 py-2
                hover:bg-gray-600 rounded cursor-pointer'
                onClick={() => {
                  setLanguage(lang.code);
                  cookies.set('language', lang.code, { path: '/' });
                  setIsOpen(false);
                  window.location.reload();
                }}>
                  <div className='w-5 h-5 flex items-center'>
                    {lang.img}
                  </div>
                  <p className='ml-2'>
                    {lang.name}
                  </p>
                </ul>
              ))}
              <ul className='flex items-center w-full px-2 py-2
              hover:bg-gray-600 rounded cursor-pointer' onClick={() => {
                setIsOpen(false);
                window.open('http://discord.coodo.xyz/', '_blank');
              }}>
                <PlusCircleIcon className='w-6 h-6' />
                <p className='ml-2'>
                  Suggest
                </p>
              </ul>
            </li>
          </ul>
        </div>
      )}
    </div>
  );
};
