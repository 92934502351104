import React, { useEffect, useState } from 'react';
import { UserData } from './UserData';
import { AuthUser, getUserByToken } from '../../utils';
import { Layout } from '../Layout';
import { UserDataSkeleton } from './UserDataSkeleton';

export const User = () => {
  const reactUser = AuthUser();

  const [user, setUser] = useState(reactUser);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      const user = await getUserByToken(reactUser.token);
      user.token = reactUser.token;
      setUser(user);
      setIsLoading(false);
    };
    if (reactUser)
      fetch();
  }, [reactUser]);

  return (
    <Layout>
      {isLoading ? (
        <UserDataSkeleton />
      ) : (
        <UserData user={user} />
      )}
    </Layout>
  );
};
