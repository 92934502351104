import React from 'react';
import { SectionTitle } from '../../SectionTitle';
import { getLanguage } from '../../../language/getLanguage';
import Container from '../../Container';

export const RecurrentPollEditSkeleton = () => {
  return (
    <div className='w-full flex flex-col'>
      <SectionTitle title={getLanguage('recurrentpoll_information')}
        subtitle={getLanguage('recurrentpoll_information_subtitle')} goBack={true} />
      <Container>
        <div className='animate-pulse mb-10'>
          <div className='h-4 rounded-full bg-gray-300 w-72 mb-2.5'/>
          <div className='h-8 w-10/12 lg:w-8/12 rounded bg-gray-400'/>
        </div>
        <div className='animate-pulse mb-10'>
          <div className='h-4 rounded-full bg-gray-300 w-60 mb-2.5'/>
          <div className='h-16 w-11/12 lg:w-9/12 rounded bg-gray-400'/>
        </div>
        <div className='animate-pulse mb-10'>
          <div className='h-4 rounded-full bg-gray-300 w-44 mb-2.5'/>
          <div className='h-8 w-20 rounded-full bg-gray-400'/>
        </div>
        <div className='animate-pulse mb-10'>
          <div className='h-4 rounded-full bg-gray-300 w-48 mb-2.5'/>
          <div className='flex flex-row gap-3 flex-wrap'>
            <div className='h-8 w-20 rounded-lg bg-gray-400'/>
            <div className='h-8 w-20 rounded-lg bg-gray-400'/>
            <div className='h-8 w-20 rounded-lg bg-gray-400'/>
            <div className='h-8 w-20 rounded-lg bg-gray-400'/>
            <div className='h-8 w-20 rounded-lg bg-gray-400'/>
            <div className='h-8 w-20 rounded-lg bg-gray-400'/>
            <div className='h-8 w-20 rounded-lg bg-gray-400'/>
          </div>
        </div>
        <div className='animate-pulse'>
          <div className='flex flex-col lg:flex-row gap-3'>
            <div className='h-8 w-full rounded-lg bg-gray-400'/>
            <div className='h-8 w-full rounded-lg bg-gray-400'/>
          </div>
        </div>
      </Container>
    </div>
  );
};
