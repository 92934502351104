import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { Layout } from '../Layout';

import { UserProfile } from './UserProfile';
import { UserProfileSkeleton } from './UserProfileSkeleton';

import { AuthUser, getUser, getRoles, getUserPollsCount,
  getUserPolls, getUserGuilds } from '../../utils';

import { CustomAlert, SectionTitle } from '../../components';
import { PollsTable } from '../../components/polls';
import { GuildsTable } from '../../components/guilds';
import { getLanguage } from '../../language/getLanguage';

export const Users = () => {
  const user = AuthUser();
  const [roles, setRoles] = useState([]);
  const [guilds, setGuilds] = useState([]);
  const [userProfile, setUserProfile] = useState({});
  const [limits, setLimits] = useState(0);
  const [polls, setPolls] = useState([]);
  const [isLoading, setIsLoading] = useState([true, true]);
  const { id } = useParams();

  useEffect(() => {
    const fetchUser = async () => {
      const data = await getUser(id, user.token);
      setUserProfile(data);
      const dataRoles = await getRoles([], user.token);
      setRoles(dataRoles);
      const pollsCount = await getUserPollsCount(data.id, user.token);
      setLimits(pollsCount);
      setIsLoading([false, true]);
      const guildsData = await getUserGuilds(id, user.token);
      setGuilds(guildsData);
      const pollsData = await getUserPolls(data.id, user.token);
      pollsData.reverse();
      setPolls(pollsData);
      setIsLoading([false, false]);
    };

    fetchUser();
  }, [user]);

  if (isLoading[0]) return (
    <Layout>
      <div className='flex flex-col flex-1 p-5 lg:p-10
      overflow-auto min-h-screen'>
        <SectionTitle title={getLanguage('user_profile')}
          subtitle={getLanguage('user_profile_subtitle')}/>
        <UserProfileSkeleton/>
      </div>
    </Layout>
  );

  return (
    <Layout>
      <div className='flex flex-col flex-1 p-5 lg:p-10
      overflow-auto min-h-screen'>
        <SectionTitle title={getLanguage('user_profile')}
          subtitle={getLanguage('user_profile_subtitle')}/>
        {userProfile.username && (
          <UserProfile profileUser={userProfile} roles={roles}
            limits={limits} user={user} />
        )}
        {user.id === userProfile.id && (
          <CustomAlert type='info' title={getLanguage('user_informations_alert')}
            message={getLanguage('user_informations_alert_message')}
            button='link' href='/user'
            buttonTitle={getLanguage('go_to_settings')} className='mb-6'/>
        )}
        <PollsTable polls={polls} title={`${userProfile.username}'s polls`}
          description={getLanguage('user_informations_polls_first')
          + ' ' + userProfile.username + ' ' +
          getLanguage('user_informations_polls_second')}
          emptyMessage={getLanguage('user_informations_polls_empty')}
          loading={isLoading[1]} user={user}/>
        {guilds !== null && (
          <GuildsTable guilds={guilds} title={`${userProfile.username}'s guilds`}
            description={getLanguage('user_informations_guilds_first')
            + ' ' + userProfile.username + ' ' +
            getLanguage('user_informations_guilds_second')}
            emptyMessage={getLanguage('user_informations_guilds_empty')}
            loading={isLoading[1]}/>
        )}
      </div>
    </Layout>
  );
};
