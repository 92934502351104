import React, { useEffect, useState } from 'react';

import { AuthUser, hasScope } from '../../../utils';
import { Loading } from '../../../components';
import { AdminLogsList } from './AdminLogsList';
import { Layout, Forbidden } from '../../../pages';

export const AdminLogs = () => {
  const user = AuthUser();

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  const fetchData = async () => {
    if (await hasScope(user, 'ADMIN_PANEL') === true &&
      await hasScope(user, 'ADMIN_LOGS') === true) {
      setIsAuthorized(true);
      setIsLoading(false);
    } else {
      setIsAuthorized(false);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!user) {
      setIsAuthorized(false);
      setIsLoading(false);
    } else
      fetchData();
  }, [user]);

  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : isAuthorized ? (
        <AdminLogsList user={user} />
      ) : (
        <Forbidden />
      )}
    </Layout>
  );
};
