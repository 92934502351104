import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  ArrowTrendingDownIcon,
  ArrowTrendingUpIcon
} from '@heroicons/react/24/solid';
import { formatNumberWithLetter } from '../../../utils/formatNumberWithLetter';

export const AdminStatsCard = ({ stats, title, range }) => {
  AdminStatsCard.propTypes = {
    stats: PropTypes.array.isRequired,
    title: PropTypes.string.isRequired,
    range: PropTypes.number.isRequired
  };

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const actualValue = stats[stats.length - 1];
    const previousValue = stats[stats.length - range] || undefined;
    const percentage = ((actualValue - previousValue) / previousValue) * 100;
    setPercentage(percentage);
  }, [stats]);

  return (
    <div className='flex flex-col h-fit w-full gap-1 bg-secondary-500 p-4
    rounded-lg ring-1 ring-tertiary'>
      <h1 className='text-xl font-regular text-white text-opacity-80'>
        {title}
      </h1>
      <div className='flex flex-row gap-2 items-end'>
        <p className='text-5xl font-extraBold text-white'>
          {formatNumberWithLetter(stats[stats.length - 1])}
        </p>
        <span className={`text-xl font-regular text-gray-200 mb-1
          ${percentage >= 0 ? 'text-green-500' : 'text-red-500'}`}>
          {percentage >= 0 ? '+' : ''}{percentage.toFixed(2)}%
        </span>
        {percentage >= 0
          ? <ArrowTrendingUpIcon className='w-10 h-10 text-green-500' />
          : <ArrowTrendingDownIcon className='w-10 h-10 text-red-500' />}
      </div>
    </div>
  );
};
