import { useLocation, useNavigate } from 'react-router-dom';
import { useSignIn } from 'react-auth-kit';
import { getRoles, getUser } from '../utils';
import Cookies from 'universal-cookie';
import React from 'react';

export const Login = () => {
  const signIn = useSignIn();
  const location = useLocation();
  const navigate = useNavigate();

  const searchParams = new URLSearchParams(location.search);
  const userToken = searchParams.get('token');
  const userId = searchParams.get('id');
  const state = searchParams.get('state');
  const cookies = new Cookies();

  if (!userToken || !userId)
    return navigate('/');

  const authUser = async () => {
    const user = await getUser(userId, userToken);
    user.token = userToken;

    if (user?.roles?.length > 0)
      user.roles = await getRoles(user.roles, userToken);
    if (!user)
      return navigate('/');

    if (signIn({
      token: userToken,
      expiresIn: 43200,
      tokenType: 'Bearer',
      authState: user
    })) {
      const userLocale = user?.locale.split('-')[0] || user?.locale || 'en';
      if (userLocale)
        cookies.set('language', userLocale, { path: '/' });
      console.info('signIn success');
      if (state)
        window.location.replace(state);
      else
        navigate('/');
    } else {
      console.error('signIn failed');
      navigate('/');
    }
  };
  authUser();

  return (
    <div className='bg-secondary-480 h-fit min-h-screen flex justify-center items-center'>
      <div className='bg-main-500 w-10 h-10 rounded-full animate-pulse'/>
    </div>
  );
};
