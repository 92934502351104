import React from 'react';

import { Footer, Button } from '../../components';
import errorCodes from './errorCodes';
import apiCodes from './apiCodes';

export const Error = () => {
  const adress = window.location.href;
  const codeApi = adress.split('&message=')[1];
  const code = adress.split('?code=')[1].split('&message=')[0];

  let title = null;
  for (const errorCode in errorCodes)
    if (errorCodes[errorCode].code === parseInt(code))
      title = errorCodes[errorCode].message;

  let message = null;
  for (const apiCode in apiCodes)
    if (apiCodes[apiCode].code === codeApi)
      message = apiCodes[apiCode].message;

  return (
    <div className='bg-secondary-480 h-fit min-h-screen flex flex-col'>
      <div className='flex flex-row flex-1'>
        <div className='mx-auto w-full text-center py-20 flex flex-col
        items-center px-4'>
          <h1 className='text-main-500 font-extraBold text-7xl md:text-8xl
          lg:text-9xl mb-6'>
            {code}
          </h1>
          <h1 className='mb-4 text-4xl font-extrabold tracking-tight leading-none
          md:text-5xl lg:text-6xl text-white font-bold'>
            {title}
          </h1>
          <p className='mb-8 text-lg font-normal lg:text-xl sm:px-16 lg:px-48
          text-gray-100 font-medium'>
            {codeApi}: {message} Please contact the  <a
              href='http://discord.coodo.xyz'
              className='text-main-500 underline'>
              support
            </a> if you need help.
          </p>
          <div className='flex flex-row gap-5 flex-wrap justify-center'>
            <Button
              name='Go Home'
              href='/'
              type='primary'/>
            <Button
              name='Join Discord Support Server'
              href='http://discord.coodo.xyz'
              type='secondary'/>
          </div>
        </div>
      </div>
      <Footer />
    </div>
  );
};
