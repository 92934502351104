import React from 'react';
import {
  ArrowPathIcon
} from '@heroicons/react/24/solid';

export const Loading = () => {
  return (
    <ArrowPathIcon className='w-12 h-13 text-gray-400 animate-spin m-auto' />
  );
};
