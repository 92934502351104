function logError(message) {
  const date = new Date();
  let time = date.toLocaleTimeString();
  console.error(`${time} | ${message}`);
}

function logWarn(message) {
  const date = new Date();
  let time = date.toLocaleTimeString();
  console.warn(`${time} | ${message}`);
}

function logInfo(message) {
  const date = new Date();
  let time = date.toLocaleTimeString();
  console.info(`${time} | ${message}`);
}

function logGray(message) {
  const date = new Date();
  let time = date.toLocaleTimeString();
  console.info(`${time} | ${message}`);
}

export { logError, logWarn, logInfo, logGray };
