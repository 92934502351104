import React from 'react';
import PropTypes from 'prop-types';

export const Container = ({ children, title }) => {
  Container.propTypes = {
    children: PropTypes.node.isRequired,
    title: PropTypes.string
  };
  return (
    <div className='flex flex-col bg-secondary-500 rounded-xl p-6 w-full
      h-fit border-l-4 border-l-solid border-l-main-500 mb-4'>
      {title && (
        <>
          <h1 className='text-white text-lg font-semiBold'>
            {title}
          </h1>
          <hr className='border-secondary-450 bo my-5' />
        </>
      )}
      {children}
    </div>
  );
};

export default Container;
