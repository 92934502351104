import NewBadge from './NewBadge';
import PremiumBadge from './PremiumBadge';
import VersionBadge from './VersionBadge';
import BetaBadge from './BetaBadge';
import DiscountBadge from './DiscountBadge';

export {
  NewBadge,
  PremiumBadge,
  VersionBadge,
  BetaBadge,
  DiscountBadge
};
