import React, { useState } from 'react';
import PropType from 'prop-types';
import {
  ArrowPathIcon
} from '@heroicons/react/24/solid';

import {
  apiCodeToString,
  deleteUserPolls,
  deleteUser,
  updateUserPrivate
} from '../../utils';

import { DeleteModal } from '../../components/modal';
import { Button, Container, CustomAlert, SectionTitle } from '../../components';
import { getLanguage } from '../../language/getLanguage';
import { useNavigate } from 'react-router-dom';

export const UserData = ({ user }) => {
  UserData.propTypes = {
    user: PropType.object.isRequired
  };

  const navigate = useNavigate();
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [deletePolls, setDeletePolls] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [alert, setAlert] = useState({ title: null, message: null, type: null });
  const [isPrivate, setIsPrivate] = useState(user.privateAccount);

  const showAlert = (title, message, type) => {
    setAlert({title, message, type });
    setTimeout(() => {
      setAlert({title: null, message: null, type: null });
    }, 8000);
  };

  const handleAccount = async () => {
    setDeleting(true);
    const response = await deleteUser(user.id, user.token);
    if (response.status === 200) {
      showAlert('Account deleted', 'Your account has been deleted.', 'success');
      setTimeout(() => {
        navigate('/logout');
      }, 2500);
    } else {
      if (apiCodeToString(response.data.errorCode))
        showAlert(response.statusText, apiCodeToString(response.data.errorCode),
          'alert');
      else
        showAlert(response.statusText, 'An error occured while deleting your\
        account. ErrorCode: ' + response.data.errorCode, 'alert');
      setDeleting(false);
    }
  };

  const handlePolls = async () => {
    setDeleting(true);
    const response = await deleteUserPolls(user.id, user.token);
    if (response.status === 200) {
      showAlert('Polls deleted', 'Your polls have been deleted.', 'success');
      setDeletePolls(false);
    } else {
      showAlert(response.statusText, 'An error occured while deleting your polls.\
      ErrorCode: ' + response.data.errorCode, 'alert');
    }
    setDeleting(false);
  };

  if (user.roles.length > 0) {
    const role = user.roles.reduce((prev, current) => {
      return (current.id < prev.id) ? current : prev;
    });
    if (role.color) {
      const img = document.getElementById('user_icon');
      if (img)
        img.style.border = '4px solid ' + role.color;
    }
  }

  const handlePrivate = async () => {
    setIsPrivate(!isPrivate);
    const response = await updateUserPrivate(!isPrivate, user.token, user);
    if (response.status === 200) {
      setIsPrivate(response.data.data.privateAccount);
      showAlert('Account updated', 'Your account has been updated.', 'success');
    } else {
      showAlert(response.statusText, 'An error occured while updating your\
      account. ErrorCode: ' + response.data.errorCode, 'alert');
    }
  };

  return (
    <>
      {deleteAccount && (
        <DeleteModal title={getLanguage('delete_account_modal_title')}
          subtitle={getLanguage('delete_account_modal_description')}
          close={() => setDeleteAccount(false)}>
          <div className='flex flex-row gap-3 w-full justify-between flex-wrap
          sm:flex-nowrap'>
            <Button type='danger' onClick={() => handleAccount()}
              disabled={deleting} className='w-full'>
              {deleting ? (
                <ArrowPathIcon className='w-6 h-6 animate-spin' />
              ) : (
                getLanguage('delete_account')
              )}
            </Button>
            <Button type='secondary' onClick={() => setDeleteAccount(false)}
              className='w-full' disabled={deleting}>
              {getLanguage('cancel')}
            </Button>
          </div>
        </DeleteModal>
      )}
      {deletePolls && (
        <DeleteModal title={getLanguage('delete_polls_modal_title')}
          subtitle={getLanguage('delete_polls_modal_description')}
          close={() => setDeletePolls(false)}>
          <div className='flex flex-row gap-3 w-full justify-between flex-wrap
          sm:flex-nowrap'>
            <Button type='danger' onClick={() => handlePolls()}
              disabled={deleting} className='w-full'>
              {deleting ? (
                <ArrowPathIcon className='w-6 h-6 animate-spin' />
              ) : (
                getLanguage('delete_polls')
              )}
            </Button>
            <Button type='secondary' onClick={() => setDeletePolls(false)}
              className='w-full' disabled={deleting}>
              {getLanguage('cancel')}
            </Button>
          </div>
        </DeleteModal>
      )}
      <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10 min-h-screen'>
        <SectionTitle title={getLanguage('user_account_title')}
          subtitle={getLanguage('user_account_subtitle')} />
        <Container>
          <div className='flex flex-row gap-4 items-center'>
            {user.avatarURL ? (
              <img id='user_icon' alt='User avatar'
                className='w-24 rounded-full p-1' src={user.avatarURL}/>
            ) : (
              <div id='user_icon' className='rounded-full w-24 h-24
              bg-secondary-480 flex items-center flex-shrink-0'>
                <h1 className='text-white text-2xl font-bold
                  text-center m-auto'>{user.username[0]}</h1>
              </div>
            )}
            <div className='flex flex-col items-start'>
              <h1 className='text-3xl font-bold text-center text-white'>
                {user.username}
              </h1>
              <p className='text-lg text-center text-gray-100 select-all'>
                {user.id}
              </p>
            </div>
          </div>
          <hr className='h-px my-8 bg-gray-400 border-0'/>
          <div className='flex flex-col gap-4'>
            <h1 className='text-2xl font-bold text-white'>
              {getLanguage('user_informations')}
            </h1>
            <ul className='flex flex-col gap-4'>
              <li className='text-lg text-white flex flex-row items-center
              justify-between flex-wrap'>
                <h2 className='font-regular'>
                  {getLanguage('user_informations_username')}
                </h2>
                <p className='text-gray-100'>{user.username}</p>
              </li>
              <li className='text-lg text-white flex flex-row items-center
              justify-between flex-wrap'>
                <h2 className='font-regular'>
                  {getLanguage('user_informations_id')}
                </h2>
                <p className='text-gray-100'>{user.id}</p>
              </li>
              <li className='text-lg text-white flex flex-row items-center
              justify-between flex-wrap'>
                <h2 className='font-regular'>
                  {getLanguage('user_informations_roles')}
                </h2>
                <p className='text-gray-100 flex flex-row gap-2
                flex-wrap justify-end'>
                  {user.roles.map((e, index) => (
                    <span key={index} className='px-2 rounded-md text-base'
                      style={{ backgroundColor: e.color + 35, color: e.color }}>
                      {e.name}
                    </span>
                  ))}
                </p>
              </li>
              <li className='text-lg text-white flex flex-row items-center
              justify-between flex-wrap'>
                <h2 className='font-regular'>
                  {getLanguage('user_informations_email')}
                </h2>
                <p className='text-gray-100 cursor-pointer blur-sm' id='email'
                  onClick={() => {
                    const email = document.getElementById('email');
                    if (!email)
                      return;
                    if (email.classList.contains('blur-sm'))
                      email.classList.remove('blur-sm');
                    else
                      email.classList.add('blur-sm');
                  }}>
                  {user.email}
                </p>
              </li>
              <li className='text-lg text-white flex flex-row items-center
              justify-between flex-wrap'>
                <h2 className='font-regular'>
                  {getLanguage('user_informations_stripe_id')}
                </h2>
                <p className='text-gray-100 cursor-pointer blur-sm' id='stripe_id'
                  onClick={() => {
                    const stripeId = document.getElementById('stripe_id');
                    if (!stripeId)
                      return;
                    if (stripeId.classList.contains('blur-sm'))
                      stripeId.classList.remove('blur-sm');
                    else
                      stripeId.classList.add('blur-sm');
                  }}>
                  {user.stripeCustomerId}
                </p>
              </li>
            </ul>
            <h1 className='text-2xl font-bold text-white mt-5'>
              {getLanguage('user_data')}
            </h1>
            <ul className='flex flex-col gap-4'>
              <li className='text-lg text-white flex flex-row items-center
              justify-between flex-wrap'>
                <h2 className='font-regular'>
                  {getLanguage('user_data_private')}
                </h2>
                <label className='relative inline-flex items-center
                  cursor-pointer'>
                  <input type='checkbox' className='sr-only peer'
                    onChange={() => handlePrivate()} checked={isPrivate} />
                  <div className="w-11 h-6 rounded-full peer
                  peer-focus:ring-4 peer-focus:ring-main-800 bg-secondary-450
                  peer-checked:after:translate-x-full
                  peer-checked:after:border-white after:content-['']
                  after:absolute after:top-1 after:left-[2px] after:bg-white
                  after:border-gray-300 after:border after:rounded-full after:h-5
                  after:w-5 after:transition-all border-gray-600
                  peer-checked:bg-main-600"></div>
                  <span className='ml-3 text-gray-100 '>
                    {isPrivate ? getLanguage('private') : getLanguage('public')}
                  </span>
                </label>
              </li>
              <li className='text-lg text-white flex flex-row items-center
              justify-between flex-wrap'>
                <h2 className='font-regular'>
                  {getLanguage('user_data_account')}
                </h2>
                <Button type='danger' onClick={() => setDeleteAccount(true)}>
                  {getLanguage('delete_account')}
                </Button>
              </li>
              <li className='text-lg text-white flex flex-row items-center
              justify-between flex-wrap'>
                <h2 className='font-regular'>
                  {getLanguage('user_data_polls')}
                </h2>
                <Button type='danger' onClick={() => setDeletePolls(true)}>
                  {getLanguage('delete_polls')}
                </Button>
              </li>
            </ul>
          </div>
        </Container>
      </div>
      {alert.message && (
        <CustomAlert message={alert.message} type={alert.type}
          title={alert.title}
          className='fixed bottom-10 right-4 left-4 z-50' />
      )}
    </>
  );
};
