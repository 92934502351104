import { getLanguage } from '../language/getLanguage';

export function getResultsCSVUsers(poll) {
  let users = [];

  poll.buttons.forEach(button => {
    button.ids.forEach(user => {
      if (!users.filter(e => e.id === user.id).length)
        users.push(user);
    });
  });
  const hasServerName = users.every(user => Object.prototype
    .hasOwnProperty.call(user, 'server_name'));

  let csv = getLanguage('ID') + ',' + getLanguage('usernames');
  if (hasServerName)
    csv += ',' + getLanguage('server_names');
  csv += ',' + getLanguage('choice') + '\n';

  poll.buttons.forEach(button => {
    button.ids.forEach(id => {
      csv += `${poll.pollID}`;
      csv += `,${id.username}`;
      if (hasServerName)
        csv += `,${id.server_name}`;
      csv += `,${button.title}\n`;
    }
    );
  });

  return csv;
}

export function getResultsCSVStats(poll) {
  let csv = getLanguage('choice') + ',' + getLanguage('votes_upp') +
    ',' + getLanguage('percentage') + '\n';

  const totalVotes = poll.buttons.reduce((acc, button) => acc + button.ids.length, 0);

  poll.buttons.forEach(button => {
    csv += `${button.title},${button.ids.length}`;
    csv += `,${(button.ids.length / totalVotes * 100).toFixed(2)}%\n`;
  });

  csv += `\n${getLanguage('total_votes')},${totalVotes}\n`;

  const totalUsers = poll.buttons.reduce((acc, button) => {
    button.ids.forEach(id => {
      if (!acc.includes(id.id))
        acc.push(id.id);
    });
    return acc;
  }, []).length;

  csv += `${getLanguage('total_users')},${totalUsers}`;

  return csv;
}
