import React from 'react';
import { SectionTitle } from '../../SectionTitle';
import { getLanguage } from '../../../language/getLanguage';
import Container from '../../Container';

export const PollEditSkeleton = () => {
  return (
    <div>
      <div className='w-full flex flex-col'>
        <SectionTitle title={getLanguage('poll_information')}
          subtitle={getLanguage('poll_information_subtitle')} />
        <Container>
          <div className='animate-pulse flex items-center justify-between mb-14'>
            <div>
              <div className='h-2.5 rounded-full bg-gray-300 w-72 mb-2.5'/>
              <div className='w-36 lg:w-96 h-2 rounded-full bg-gray-400'/>
            </div>
            <div className='h-2.5 rounded-full bg-gray-400 w-24'/>
          </div>
          <div className='flex flex-row justify-between gap-10 flex-wrap
          md:flex-nowrap'>
            <div className='w-full'>
              <ul className='flex flex-col mt-5 gap-10'>
                {[...Array(5)].map((_, index) => (
                  <li key={index} className='flex flex-row gap-5 animate-pulse'>
                    <div>
                      <div className='h-2.5 rounded-full bg-gray-300 w-72 mb-2.5'/>
                      <div className={`h-2.5 rounded-full bg-gray-400
                      w-${index * 8}`}/>
                    </div>
                    <div className='h-2.5 rounded-full bg-gray-400 w-24'/>
                  </li>
                ))}
              </ul>
            </div>
          </div>
        </Container>
      </div>
      <span className='sr-only'>Loading...</span>
    </div>
  );
};
