import React, { useContext, useEffect, useState } from 'react';
import logo from '../../assets/logo.png';
import { Context } from '../SidebarContext';
import PropTypes from 'prop-types';
import { LanguageSelector } from './LanguageSelector';
import { AuthUser } from '../../utils/AuthUser';
import { Button } from '../Button';
import {
  Bars3Icon, PlusIcon
} from '@heroicons/react/24/solid';
import { getLanguage } from '../../language/getLanguage';
import Cookies from 'universal-cookie';
import { useNavigate } from 'react-router-dom';

export const Header = ({ className }) => {
  Header.propTypes = {
    className: PropTypes.string
  };
  const { SidebarState, SetSidebarState } = useContext(Context);
  const [alertStatus, setAlertStatus] = useState('hidden');
  const user = AuthUser();
  const navigate = useNavigate();

  const handleSidebar = () => {
    SetSidebarState(!SidebarState);
  };

  const handleLogoClick = () => {
    navigate('/');
  };

  const handleCloseAlert = () => {
    const cookies = new Cookies();
    cookies.set('alertForm', '1', { path: '/', maxAge: 86400 * 15 });
    setAlertStatus('translate-y-[-100%] absolute top-0 left-0 right-0 z-10');
  };

  useEffect(() => {
    const cookies = new Cookies();
    const alertCookie = cookies.get('alertForm');
    if (!alertCookie)
      setAlertStatus('flex');
  }, []);

  return (
    <div className={`z-10 ${className}`}>
      <div className={`bg-green-500 text-white ${alertStatus} transition-all
      text-center py-1 px-5 flex flex-row justify-between gap-5 font-medium
      duration-1000 items-center`}>
        <div className='flex flex-row gap-2 items-center justify-center w-full flex-wrap'>
          <p className='text-left'>
            <span className='font-bold'>
              🙌 I need you !
            </span> Please take a few minutes to fill out this form to help me improve
            StrawPoll.
          </p>
          <button className='font-bold underline whitespace-nowrap
          hover:scale-101 transition duration-100' onClick={() => {
            window.open('https://tally.so/r/wMEKWY', '_blank');
          }}>Open Form</button>
        </div>
        <button className='h-full p-2 hover:bg-green-600/80 rounded-lg duration-75'
          onClick={handleCloseAlert}>
          <PlusIcon className='w-5 h-5 rotate-45' />
        </button>
      </div>
      <header className='bg-secondary-500 py-4 lg:px-36 px-5 flex flex-row
      justify-between border-b border-gray-500 items-center'>
        <div className='w-80 flex-row justify-start lg:hidden flex'>
          <button className='w-6 h-6' onClick={handleSidebar}>
            <Bars3Icon className='w-6 h-6 text-gray-100' />
          </button>
        </div>
        <div className='container flex flex-row items-center gap-3 w-40
        cursor-pointer justify-center h-12' onClick={handleLogoClick}>
          <img src={logo} alt='StrawPoll logo' className='w-12
          rounded-full hidden sm:flex' />
          <h1 className='text-white text-3xl font-bold m-0'>
            StrawPoll
          </h1>
        </div>
        <div className='container flex flex-row items-center gap-5 w-80
        justify-end'>
          <LanguageSelector user={user} />
          {/**
          <Button type='premium' className='hidden lg:flex'
            href='/usage'>
            <BoltIcon className='w-5 h-5 mr-2' />
            {getLanguage('get_premium')}
          </Button>
          */}
          <Button type='primary' className='hidden lg:flex'
            href='http://invite.coodo.xyz'>
            {getLanguage('invite_bot')}
          </Button>
        </div>
      </header>
    </div>
  );
};
