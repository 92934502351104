import React from 'react';
import { Button } from '../../components';
import iconDiscord from '../../assets/Discord_Logo.svg';
import { getLanguage } from '../../language/getLanguage';
import { useSearchParams } from 'react-router-dom';

export const Login = () => {
  const [searchParams] = useSearchParams();

  const env = {
    clientId: '823271140410916986',
    redirectUri: 'https://api.coodo.xyz/authorize/callback',
    scopes: [ 'identify', 'guilds', 'guilds.members.read', 'email' ]
  };

  const authorizeUrl = `https://discordapp.com/api/oauth2/authorize?client_id=${
    env.clientId
  }&redirect_uri=${
    encodeURIComponent(env.redirectUri)
  }&response_type=code&scope=${env.scopes.join('%20')}`;

  const handleLogin = () => {
    const state = searchParams.get('state');
    if (state)
      window.location.href = `${authorizeUrl}&state=${state}`;
    else
      window.location.href = authorizeUrl;
  };

  return (
    <div className='mx-auto w-full text-center p-5 lg:p-10 flex flex-col
    items-center min-h-screen'>
      <h1 className='mb-4 text-4xl font-extrabold tracking-tight leading-none
      md:text-5xl lg:text-6xl text-white font-bold'>
        {getLanguage('login_discord_account')}
      </h1>
      <p className='mb-8 text-lg font-normal lg:text-xl sm:px-16 lg:px-48
      text-gray-200 font-medium'>
        {getLanguage('login_discord_account_description')}
      </p>
      <Button type='primary' onClick={() => handleLogin()} className='bg-opacity-0
      border-2 border-main-500 w-full max-w-sm hover:bg-opacity-20
      hover:bg-main-500'>
        <img src={iconDiscord}
          alt='Discord' className='w-6 h-6 mr-2'/>
        {getLanguage('login_w_discord')}
      </Button>
    </div>
  );
};
