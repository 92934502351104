import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { SectionTitle, CustomAlert } from '../../../components';
import moment from '../../../utils/moment';
import { AdminLog } from '../../../components/logs';
import {
  PlusIcon,
  TrashIcon,
  ArrowLeftOnRectangleIcon,
  CursorArrowRaysIcon,
  PencilSquareIcon,
  CreditCardIcon,
  CircleStackIcon,
  ChevronDownIcon
} from '@heroicons/react/24/solid';
import { getLogs } from '../../../utils';

export const AdminLogsList = ({ user }) => {
  AdminLogsList.propTypes = {
    user: PropTypes.object.isRequired
  };

  const [selectedFilter, setSelectedFilter] = useState('');
  const [typeToHide, setTypeToHide] = useState([]);
  const [searchFilter, setSearchFilter] = useState('');
  const [dropdownOpen, setDropdownOpen] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [logs, setLogs] = useState([]);
  const [lastupdate, setLastupdate] = useState(0);
  const types = [
    {
      icon: <PlusIcon key='Plus' className='text-white w-5 h-5'/>,
      text: 'create a poll',
      colors: {
        ring: 'ring-main-500',
        bg: 'bg-main-500',
        text: 'text-main-500'
      }
    },
    {
      icon: <TrashIcon key='Trash' className='text-white w-5 h-5'/>,
      text: 'delete a poll',
      colors: {
        ring: 'ring-red-500',
        bg: 'bg-red-500',
        text: 'text-red-500'
      }
    },
    {
      icon: <ArrowLeftOnRectangleIcon key='Id' className='text-white w-5 h-5'/>,
      text: 'login to dashboard',
      colors: {
        ring: 'ring-secondary-500',
        bg: 'bg-secondary-500',
        text: 'text-secondary-500'
      }
    },
    {
      icon: <CursorArrowRaysIcon key='Cursor' className='text-white w-5 h-5'/>,
      text: 'vote on a poll',
      colors: {
        ring: 'ring-main-500',
        bg: 'bg-main-500',
        text: 'text-main-500'
      }
    },
    {
      icon: <PencilSquareIcon key='Id' className='text-white w-5 h-5'/>,
      text: 'update a poll',
      colors: {
        ring: 'ring-orange-500',
        bg: 'bg-orange-500',
        text: 'text-orange-500'
      }
    },
    {
      icon: <PencilSquareIcon key='Id' className='text-white w-5 h-5'/>,
      text: 'update a role',
      colors : {
        ring: 'ring-orange-500',
        bg: 'bg-orange-500',
        text: 'text-orange-500'
      }
    },
    {
      icon: <CreditCardIcon key='greenId' className='text-white w-5 h-5'/>,
      text: 'buy a plan',
      colors: {
        ring: 'ring-premium-500',
        bg: 'bg-premium-500',
        text: 'text-premium-500'
      }
    },
    {
      icon: <CircleStackIcon key='Id' className='text-white w-5 h-5'/>,
      text: 'status page data posted',
      colors: {
        ring: 'ring-secondary-500',
        bg: 'bg-secondary-500',
        text: 'text-secondary-500'
      }
    },
    {
      icon: <TrashIcon key='Id' className='text-white w-5 h-5'/>,
      text: 'delete a user',
      colors: {
        ring: 'ring-red-500',
        bg: 'bg-red-500',
        text: 'text-red-500'
      }
    },
    {
      icon: <PencilSquareIcon key='Id' className='text-white w-5 h-5'/>,
      text: 'update a user',
      colors: {
        ring: 'ring-orange-500',
        bg: 'bg-orange-500',
        text: 'text-orange-500'
      }
    },
    {
      icon: <PlusIcon key='Id' className='text-white w-5 h-5'/>,
      text: 'create a guild',
      colors: {
        ring: 'ring-main-500',
        bg: 'bg-main-500',
        text: 'text-main-500'
      }
    },
    {
      icon: <PencilSquareIcon key='Id' className='text-white w-5 h-5'/>,
      text: 'update a guild',
      colors: {
        ring: 'ring-orange-500',
        bg: 'bg-orange-500',
        text: 'text-orange-500'
      }
    },
    {
      icon: <TrashIcon key='Id' className='text-white w-5 h-5'/>,
      text: 'delete a guild',
      colors: {
        ring: 'ring-red-500',
        bg: 'bg-red-500',
        text: 'text-red-500'
      }
    }
  ];

  useEffect(() => {
    setIsLoading(true);

    getLogs(user.token, searchFilter, selectedFilter)
      .then((logs) => {
        setLogs(logs);
        setLastupdate(moment().unix());
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [searchFilter, selectedFilter]);

  return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
      overflow-auto min-h-screen'>
      <SectionTitle title='API logs' subtitle='Here you can see all the
      logs of the API.'/>
      <CustomAlert type='info' title='Last update'
        message={`Last update at
        ${moment.unix(lastupdate).format('HH:mm:ss')}`}/>
      <div className='flex flex-row gap-2 sm:gap-10 flex-wrap sm:flex-nowrap'>
        <select className='bg-gray-50 border text-sm rounded-lg block w-full p-2.5
        bg-tertiary border-gray-600 placeholder-gray-400 text-white
        focus:ring-main-500 focus:border-main-500 outline-none font-medium'
        value={selectedFilter} onChange={(e) => {
          if (e.target.value === 'all')
            setSelectedFilter('');
          else
            setSelectedFilter(e.target.value);
        }}>
          <option value='all'>No filter</option>
          {types.map((type, index) => (
            <option key={index} value={index + 1} className={type?.colors?.text}>
              {type.text.charAt(0).toUpperCase() + type.text.slice(1)}
            </option>
          ))}
        </select>
        <div className='relative z-10 w-full'>
          <button className='border text-sm rounded-lg flex w-full p-2.5
          bg-tertiary border-gray-600 placeholder-gray-400 text-white
          focus:ring-main-500 focus:border-main-500 outline-none font-medium
          flex-row justify-between items-center'
          onClick={() => {
            setDropdownOpen(!dropdownOpen);
          }}> Hidden logs <ChevronDownIcon className={`w-5 h-5 ml-2
              ${dropdownOpen ? 'transform rotate-180' : ''}`}/>
          </button>
          {dropdownOpen && (
            <div className='absolute w-max bg-tertiary shadow rounded-sm
              py-4 px-4 border border-gray-300 flex flex-col gap-1'>
              {types.map((type, index) => (
                <div key={index} className='flex flex-row gap-2'>
                  <input type='checkbox' className='w-5 h-5'
                    checked={typeToHide.includes(index + 1)}
                    onChange={() => {
                      if (typeToHide.includes(index + 1))
                        setTypeToHide(typeToHide
                          .filter((item) => item !== index + 1));
                      else
                        setTypeToHide([...typeToHide, index + 1]);
                    }}/>
                  <p className='text-gray-200 font-medium'>
                    {type.text.charAt(0).toUpperCase() + type.text.slice(1)}
                  </p>
                </div>
              ))}
            </div>
          )}
        </div>
        <input type='text' placeholder='Search for a username'
          className='bg-gray-50 border text-sm rounded-lg block w-full p-2.5
          bg-tertiary border-gray-600 placeholder-gray-400 text-white
          focus:ring-main-500 focus:border-main-500 outline-none font-medium'
          onChange={(e) => {
            setSearchFilter(e.target.value);
          }}/>
      </div>
      <ol className='relative border-l-2 border-gray-400'>
        {isLoading && (
          <li className='mb-10 ml-6'>
            <span className='absolute flex items-center justify-center w-6 h-6
              rounded-full -left-3 ring-12 ring-secondary-480 bg-secondary-450'>
              <span className='flex items-center justify-center w-6 h-6
              rounded-full ring-8 ring-opacity-25 ring-tertiary'/>
            </span>
            <div className='flex-1 py-1 space-y-4 ml-2 animate-pulse'>
              <div className='w-3/4 md:w-5/12 h-4 bg-secondary-450 rounded-full'/>
              <div className='space-y-2'>
                <div className='h-4 w-2/4 md:w-3/12 bg-secondary-450
                rounded-full'/>
                <div className='w-4/4 md:w-4/12 h-4 bg-secondary-450
                rounded-full'/>
              </div>
            </div>
          </li>
        )}
        {logs
          .filter((log) => !typeToHide.includes(log.action.id))
          .slice(0, 100)
          .map((log, index) => (
            <AdminLog key={index} log={log} index={index} types={types}/>
          ))}
      </ol>
    </div>
  );
};

