import React, { useState } from 'react';
import PropTypes from 'prop-types';

export const Context = React.createContext();
export const ContextProvider = ({ children }) => {
  const [SidebarState, SetSidebarState] = useState(1);
  ContextProvider.propTypes = {
    children: PropTypes.node.isRequired
  };

  return (
    <Context.Provider value={{ SidebarState, SetSidebarState }}>
      {children}
    </Context.Provider>
  );
};
