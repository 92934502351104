import React, { useEffect, useState } from 'react';
import CustomAlert from '../../Alert';
import PropType from 'prop-types';
import { PollsTableSkeleton } from './PollsTableSkeleton';
import moment from '../../../utils/moment';
import { getLanguage } from '../../../language/getLanguage';
import { SectionTitle } from '../../SectionTitle';
import { useNavigate } from 'react-router-dom';
import {
  ArrowLeftIcon,
  ArrowRightIcon,
  ClockIcon,
  PauseIcon,
  PencilIcon,
  PlayIcon,
  SignalIcon,
  TrashIcon
} from '@heroicons/react/24/solid';
import Option from './Option';
import PopHover from '../../Popover';
import { deleteRecurrentPoll, updateRecurrentPoll } from '../../../utils';
import BannerRecurrentPollHelp from '../../banners/BannerRecurrentPollHelp';

export const RecurrentPollsTable = ({
  polls,
  title,
  description,
  emptyMessage,
  loading,
  user
}) => {
  RecurrentPollsTable.propTypes = {
    polls: PropType.array,
    title: PropType.string,
    description: PropType.string,
    emptyMessage: PropType.string,
    loading: PropType.bool,
    user: PropType.object
  };

  const navigate = useNavigate();

  const [active, setActive] = useState(0);
  const [pages, setPages] = useState([]);

  const itemsPerPage = 7;

  const next = () => {
    setActive(prev => Math.min(prev + 1, Math.ceil(polls.length / itemsPerPage) - 1));
  };

  const prev = () => {
    setActive(prev => Math.max(prev - 1, 0));
  };

  useEffect(() => {
    if (!polls)
      return;

    const totalPages = Math.ceil(polls.length / itemsPerPage);
    const paginatedPolls = Array.from({ length: totalPages }, (_, index) =>
      polls.slice(index * itemsPerPage, (index + 1) * itemsPerPage)
    );
    setPages(paginatedPolls);
  }, [polls]);

  if (loading) return (
    <div className='flex flex-col flex-1'>
      <SectionTitle title={title} subtitle={description} goBack={false} />
      <BannerRecurrentPollHelp />
      <PollsTableSkeleton height={6} />
    </div>
  );

  return (
    <div className='flex flex-col flex-1'>
      <SectionTitle title={title} subtitle={description} goBack={false} />
      <BannerRecurrentPollHelp />
      <div className='justify-center flex flex-col'>
        {polls && polls.length < 1 ? (
          <CustomAlert type='info' title={getLanguage('nothing_to_display')}
            message={emptyMessage} />
        ) : (
          <ul className='flex flex-col gap-3'>
            {pages[active] && pages[active].map((poll) => (
              <li key={poll.id} className='flex bg-secondary-500
              rounded-lg h-fit border border-secondary-480
              hover:border-main-500 transition-all duration-200
              hover:cursor-pointer'>
                <div className='flex h-full w-full py-4 pr-4 flex-row justify-between'
                  onClick={() => {
                    navigate(`/recurrentpolls/${poll.id}/edit`);
                  }}>
                  <div className='flex flex-row items-center'>
                    <div className='flex items-center justify-center w-20 flex-shrink-0'>
                      <div className='relative w-4 h-4'>
                        <div className={`h-4 w-4 rounded-full
                        ${poll.active === true ? 'bg-green-500' : 'bg-red-500'}`} />
                        <div className='absolute top-0 right-0'>
                          {poll.active === true && (
                            <div className='w-4 h-4 rounded-full animate-ping
                            bg-green-500'/>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className='flex flex-col gap-2 overflow-hidden'>
                      <div className='text-white text-base font-medium truncate
                    whitespace-break-spaces break-all'>
                        #{poll.channel.name}
                      </div>
                      <div className='flex flex-row gap-2 items-center text-white'>
                        <span className='text-gray-200 text-sm font-medium'>
                          {new Date(poll.timestamp * 1000).toLocaleString()}
                        </span>
                      </div>
                    </div>
                  </div>
                  <div className='flex-row hidden sm:flex gap-5 flex-shrink-0
                  justify-end'>
                    {poll.cycle?.next && (
                      <Option tooltip={getLanguage('next_recurrence') + ' ' +
                        moment(poll.cycle.next * 1000).fromNow()}>
                        <SignalIcon className='w-4 h-4' />
                        {moment(poll.cycle.next * 1000).fromNow(true)}
                      </Option>
                    )}
                    <Option tooltip={getLanguage('every') + ' ' +
                      moment
                        .duration(poll.options.recurence.value,
                          poll.options.recurence.unit)
                        .days() + 'd ' +
                      moment
                        .duration(poll.options.recurence.value,
                          poll.options.recurence.unit)
                        .hours() + 'h ' +
                      moment
                        .duration(poll.options.recurence.value,
                          poll.options.recurence.unit)
                        .minutes() + 'm'}>
                      <ClockIcon className='w-4 h-4' />
                      {moment
                        .duration(poll.options.recurence.value,
                          poll.options.recurence.unit).humanize()}
                    </Option>
                  </div>
                </div>
                <div className='flex-col flex-shrink-0 text-center
                flex justify-center w-24 lg:w-24 items-center'>
                  <PopHover values={[
                    {
                      name: getLanguage('edit'),
                      icon: <PencilIcon className='w-5 h-5' />,
                      onClick: () => {
                        navigate(`/recurrentpolls/${poll.id}/edit`);
                      }
                    },
                    {
                      name: poll.active ? getLanguage('deactivate') :
                        getLanguage('activate'),
                      icon: poll.active ? <PauseIcon className='w-5 h-5' /> :
                        <PlayIcon className='w-5 h-5' />,
                      onClick: async () => {
                        poll.active = !poll.active;
                        const result = await updateRecurrentPoll(poll, user.token);
                        if (result.status === 200) {
                          const newPages = [...pages];
                          newPages[active] = newPages[active].map(
                            (item) => item.id === poll.id ? poll : item
                          );
                          setPages(newPages);
                          return 1;
                        }
                        return 0;
                      }
                    },
                    {
                      separator: true,
                      name: getLanguage('delete'),
                      colors: {
                        danger: true
                      },
                      icon: <TrashIcon className='w-5 h-5' />,
                      onClick: async () => {
                        const liItem = document.getElementById(
                          `li-${poll.id}`
                        );
                        const result = await deleteRecurrentPoll(poll.id, user.token);
                        if (result.status === 200) {
                          liItem.classList.add('hidden');
                          const newPages = [...pages];
                          newPages[active] = newPages[active].filter(
                            (item) => item.id !== poll.id
                          );
                          setPages(newPages);
                          return 1;
                        }
                        return 0;
                      }
                    }
                  ]}/>
                </div>
              </li>
            ))}
          </ul>
        )}
        <div className='flex mx-auto mt-6'>
          <div className='flex gap-4 items-center'>
            <button className='flex flex-row items-center gap-2 bg-gray-480
            disabled:bg-gray-480 disabled:opacity-50 hover:bg-gray-440
            transition-all duration-300 disabled:cursor-not-allowed
            rounded-lg px-4 py-2 text-white font-medium text-bas'
            onClick={prev} disabled={active === 0}>
              <ArrowLeftIcon className='w-4 h-4 filter invert-[.50]' />
            </button>
            <h3 className='font-regular text-gray-100 m-0'>
              {getLanguage('page')}
              <strong className='text-main-500'> {active + 1}</strong>
              {' '}{getLanguage('page_of')}{' '}
              <strong className='text-main-500'>
                {pages.length > 0 ? pages.length : 1}
              </strong>
            </h3>
            <button className='flex flex-row items-center gap-2 bg-gray-480
            disabled:bg-gray-480 disabled:opacity-50 hover:bg-gray-440
            transition-all duration-300 disabled:cursor-not-allowed
            rounded-lg px-4 py-2 text-white font-medium text-bas'
            onClick={next} disabled={!pages[active + 1]}>
              <ArrowRightIcon className='w-4 h-4 filter invert-[.50]' />
            </button>
          </div>
        </div>
      </div>
      {!polls && (
        <CustomAlert type='alert' button='logout'
          title='Failed to load your polls !'
          message='An error occurred. Please try reconnecting. Ensure that you
          have a stable internet connection. If the problem persists, please
          contact our support team for further assistance.' />
      )}
    </div>
  );
};

