import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { getPoll, getUser } from '../../../utils';
import moment from '../../../utils/moment';
import CustomAlert from '../../Alert';
import { Button } from '../../Button';
import {
  CheckIcon,
  XMarkIcon,
  ArrowPathIcon,
  ChartBarIcon,
  ShareIcon,
  PencilIcon
} from '@heroicons/react/24/solid';
import PropType from 'prop-types';
import { SectionTitle } from '../../SectionTitle';
import { PollInformationsSkeleton } from '../results/PollInformationsSkeleton';
import { votePoll, apiCodeToString } from '../../../utils';
import Container from '../../Container';
import { getLanguage } from '../../../language/getLanguage';
import { ShareModal } from '../../modal';

export const PollVoteComponent = ({ user }) => {
  PollVoteComponent.propTypes = {
    user: PropType.object
  };

  let options = [ '1️⃣', '2️⃣', '3️⃣', '4️⃣', '5️⃣', '6️⃣', '7️⃣', '8️⃣', '9️⃣',
    '🔟', '1️⃣1️⃣', '1️⃣2️⃣' ];
  const [poll, setPoll] = useState([]);
  const [owner, setOwner] = useState([]);
  const [isVoting, setIsVoting] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const [alert, setAlert] = useState({ title: null, message: null, type: null });
  const [shareModal, setShareModal] = useState(false);
  let { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  if (params.get('id') && !id)
    id = params.get('id');

  useEffect(() => {
    const fetch = async () => {
      const poll = await getPoll(id, user.token);
      if (!poll.pollID)
        return setPoll(poll);
      setIsVoting(initializeVoting(poll));
      const owner = await getUser(poll.ownerID, user.token);
      setPoll(poll);
      setOwner(owner);
      setIsLoading(false);
    };

    fetch();
  }, [user]);

  const initializeVoting = (poll) => {
    let isVoting = [];
    poll.buttons.forEach(() => {
      isVoting.push(false);
    });
    return isVoting;
  };

  let dateCreate = new Date(parseInt(poll.timestamp) * 1000);

  let year = dateCreate.getFullYear();
  let month = dateCreate.getMonth();
  let day = dateCreate.getDate();
  let hour = dateCreate.getHours();
  let minute = dateCreate.getMinutes();
  let time = moment([ year, month, day, hour, minute ]).fromNow();

  let ids = [];
  let endIn = null;
  if (poll.buttons) {
    poll.buttons.forEach(button => {
      button.ids.forEach(idItem => {
        const id = poll.anonymous ? idItem : idItem.id;
        if (!ids.includes(id))
          ids.push(id);
      });
    });

    if (poll.timestamp_close) {
      let dateClose = new Date(parseInt(poll.timestamp_close) * 1000);
      let year2 = dateClose.getFullYear();
      let month2 = dateClose.getMonth();
      let day2 = dateClose.getDate();
      let hour2 = dateClose.getHours();
      let minute2 = dateClose.getMinutes();
      endIn = moment([ year2, month2, day2, hour2, minute2 ]).fromNow();
    }
  }

  const showAlert = (title, message, type) => {
    if (alert.message) {
      setAlert({title: null, message: null, type: null });
      setTimeout(() => {
        setAlert({title, message, type });
      }, 500);
      return;
    }

    setAlert({title, message, type });
  };

  const voteFor = async (index) => {
    let newIsVoting = [...isVoting];
    newIsVoting[index] = true;
    setIsVoting(newIsVoting);

    const response = await votePoll(poll.pollID,
      poll.buttons[index].name, user.token);

    if (response.status === 200) {
      showAlert('Success !', 'Your vote has been saved !',
        'success');
    } else {
      if (apiCodeToString(response.data.errorCode) !== null)
        showAlert(response.statusText,
          apiCodeToString(response.data.errorCode), 'alert');
      else
        showAlert(response.statusText, 'An error occured while updating the poll.',
          'alert');
    }
    newIsVoting = [...isVoting];
    newIsVoting[index] = false;
    setIsVoting(newIsVoting);
  };

  if (poll.status === 404) return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10 overflow-auto min-h-screen'>
      <CustomAlert type='warning' title='Poll not found !'
        message='Are you sure you have indicated the right ID. The poll ID corresponds
        to the ID of the message sent by the bot. You can find it in the
        footer of the poll embed.' />
    </div>
  );

  if (isLoading) return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10 overflow-auto min-h-screen'>
      <PollInformationsSkeleton />
    </div>
  );

  return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
      overflow-auto min-h-screen'>
      {shareModal && (
        <ShareModal
          close={() => setShareModal(false)}
          title={getLanguage('share')}
          options={{
            twitter: true,
            mail: true,
            link: document.location.href
          }}
        />
      )}
      <div className='w-full flex flex-col'>
        <SectionTitle title='Poll vote page' subtitle='
          Here you can vote for any option of the poll.' goBack={true} />
        {poll.timestamp < 1683842400 && (
          <CustomAlert type='warning' title='Compatibility problem !'
            message='This poll was created before API v1 was deployed.
            You may therefore encounter problems: display problems,
            incomplete user lists, etc.' />
        )}
        <Container>
          <div className='flex flex-row justify-between'>
            <div>
              <h1 className='font-semiBold text-white text-2xl truncate
                whitespace-normal'>
                {poll.title}
              </h1>
              <h2 className='text-gray-200 font-regular text-base'>
              created by <a className='text-main-500 cursor-pointer underline'
                  href={`/users/${owner?.id || ''}`}>
                  {owner?.username || 'null'}</a> • {time} • {poll.pollID}
              </h2>
            </div>
            <button className='text-gray-100 cursor-pointer
            p-3 bg-tertiary h-fit rounded-lg hover:ring-2 ring-main-500'
            onClick={() => {
              navigate(`/polls/${poll.pollID}/edit`);
            }}>
              <PencilIcon className='w-4 h-4' />
            </button>
          </div>
          {poll.description && (
            <p className='text-gray-100 font-medium text-lg mt-2
            w-full md:w-1/2'>
              {poll.description}
            </p>
          )}
          {poll.image && (
            <div className='relative'>
              <img className='w-full object-cover rounded-xl mt-4'
                src={poll.image} alt='poll image'
                onClick={() => {
                  window.open(poll.image, '_blank');
                }}/>
            </div>
          )}
          <div className='flex flex-row justify-between gap-10 flex-wrap
          md:flex-nowrap'>
            <div className='w-full'>
              <ul className='flex flex-col mt-5 gap-3'>
                {poll.buttons && poll.buttons.map((e, index) => (
                  <li key={index} className='text-white text-base font-regular
                  mb-1 flex flex-row'>
                    <span className='mr-2'>
                      {options[index]}
                    </span>
                    {e.title}
                  </li>
                ))}
              </ul>
              <ul className='flex flex-row gap-3 mt-5'>
                {poll.buttons && poll.buttons.map((e, index) => (
                  <li key={index} className='text-white text-base font-regular
                  mb-1 flex flex-row'>
                    <Button type='primary' onClick={() => voteFor(index)}
                      disabled={isVoting[index] || poll.ended}>
                      {isVoting[index] ? (
                        <ArrowPathIcon className='w-6 h-6 animate-spin' />
                      ) : (
                        options[index]
                      )}
                    </Button>
                  </li>
                ))}
              </ul>
              <hr className='h-px mb-8 mt-4 bg-gray-400 border-0'></hr>
              <div className='flex flex-col gap-3'>
                <ul>
                  <li className='flex flex-row'>
                    {poll.choices_max > 1 ? (
                      <CheckIcon className='w-6 h-6 text-green-500' />
                    ) : (
                      <XMarkIcon className='w-6 h-6 text-red-500' />
                    )}
                    <h1 className='text-white text-base font-regular ml-2'>
                      Multiple choices ({poll.choices_max})
                    </h1>
                  </li>
                  <li className='flex flex-row'>
                    {poll.anonymous ? (
                      <CheckIcon className='w-6 h-6 text-green-500' />
                    ) : (
                      <XMarkIcon className='w-6 h-6 text-red-500' />
                    )}
                    <h1 className='text-white text-base font-regular ml-2'>
                      Anonymous
                    </h1>
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className='mt-4 flex flex-col gap-2'>
            {poll.timestamp_close ? (
              poll.ended === false ? (
                <CustomAlert type='warning' title='This poll is not over yet !'
                  message={`This poll will ends ${endIn}.
                  You can still vote for the moment`} />
              ) : (
                <CustomAlert type='info' title='This poll is over !'
                  message={`This poll ended ${endIn}.
                  You can't vote anymore.`} />
              )
            ) : (
              poll.ended === true && (
                <CustomAlert type='info' title='This poll is over !'
                  message={`This poll ended.
                  You can't vote anymore.`} />
              )
            )}
          </div>
          <div className='flex flex-row justify-between gap-4 mt-4 flex-wrap
          sm:flex-nowrap'>
            <Button type='secondary'
              href={`/polls/${poll.pollID}/results`}
              className='w-full'>
              <ChartBarIcon className='w-6 h-6 mr-1' />
              See results
            </Button>
            <Button type='secondary' className='w-full' onClick={() => {
              setShareModal(true);
            }}>
              <ShareIcon className='w-6 h-6 mr-1' /> {getLanguage('share')}
            </Button>
          </div>
        </Container>
        {alert.message && (
          <CustomAlert message={alert.message} type={alert.type}
            title={alert.title} button='close'
            className='fixed bottom-10 right-4 left-4 z-50' />
        )}
      </div>
    </div>
  );
};
