import React from 'react';
import { getLanguage } from '../../language/getLanguage';
import { Button } from '../Button';
import image from '../../assets/Banner Premium Asset.png';
import { ArrowRightIcon } from '@heroicons/react/24/solid';

export const BannerPremium = () => {
  return (
    <div className='w-full rounded-lg flex justify-between relative
    overflow-hidden bg-gray-700 mb-5'>

      <div className='h-full absolute right-0 top-0 w-full
        bg-gradient-to-r from-premium-500 to-premium-light opacity-100'/>

      <div className='flex-col absolute h-full hidden md:flex right-0'>
        <img src={image} alt='Savings' className='h-full w-full'/>
      </div>
      <div className='flex flex-col relative gap-2 max-w-[500px] p-4'>
        <h1 className='text-xl font-semiBold text-white text-left'>
          {getLanguage('premium_banner_title')}
        </h1>
        <Button href='/usage' type='white' className='w-fit'>
          {getLanguage('get_premium')}
          <ArrowRightIcon className='w-4 h-4 ml-2' />
        </Button>
      </div>
    </div>
  );
};

export default BannerPremium;
