import React from 'react';
import {
  ShoppingBagIcon
} from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';

export const DiscountBadge = ({ className, value }) => {
  DiscountBadge.propTypes = {
    className: PropTypes.string,
    value: PropTypes.number
  };
  return (
    <span className={`text-xs font-medium px-2 py-1
    rounded-full flex items-center w-fit gap-1 bg-red-400/20
      text-red-500 h-fit ${className} whitespace-nowrap`}>
      <div className='shrink-0 h-4 w-4'>
        <ShoppingBagIcon />
      </div>
      -{value}%
    </span>
  );
};

export default DiscountBadge;
