import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  getPollResults,
  updatePoll,
  deletePoll,
  apiCodeToString
} from '../../../utils';
import CustomAlert from '../../Alert';
import PropType from 'prop-types';
import { SectionTitle } from '../../SectionTitle';
import {
  ArrowPathIcon
} from '@heroicons/react/24/solid';
import { DeleteModal } from '../../modal';
import { Button } from '../../Button';
import { getLanguage } from '../../../language/getLanguage';
import { PollEditSkeleton } from './PollEditSkeleton';
import Container from '../../Container';
import Input from '../../Input';

export const PollEditComponent = ({ user }) => {
  PollEditComponent.propTypes = {
    user: PropType.object
  };

  const [poll, setPoll] = useState(null);
  const [updatedPoll, setUpdatedPoll] = useState(null);
  const [saving, setSaving] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [alert, setAlert] = useState({ title: null, message: null, type: null });
  const [isLoading, setIsLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  let { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  if (params.get('id') && !id)
    id = params.get('id');

  useEffect(() => {
    const fetch = async () => {
      const data = await getPollResults(id, user.token);
      setPoll(data);
      if (!updatedPoll)
        setUpdatedPoll(data);
      setIsLoading(false);
    };
    if (!poll)
      fetch();
  }, [user]);

  const handleTitleChange = () => {
    const title = document.getElementById('title').value;
    setUpdatedPoll({
      ...updatedPoll,
      title: title
    });
  };

  const handleDescriptionChange = () => {
    const description = document.getElementById('description').value;
    setUpdatedPoll({
      ...updatedPoll,
      description: description
    });
  };

  const handleImageChange = () => {
    const image = document.getElementById('image').value;
    const input = document.getElementById('image');
    // eslint-disable-next-line max-len, no-useless-escape
    const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    setUpdatedPoll({
      ...updatedPoll,
      image: image
    });
    if(!urlPattern.test(image)) {
      input.classList.add('bg-red-900');
      return input.classList.add('focus:border-red-500');
    } else {
      input.classList.remove('bg-red-900');
      input.classList.remove('focus:border-red-500');
    }
  };

  const handleEndedChange = () => {
    const ended = document.getElementById('ended').checked;
    setUpdatedPoll({
      ...updatedPoll,
      ended: ended
    });
  };

  const handleChanges = async () => {
    setSaving(true);
    const response = await updatePoll(updatedPoll, user.token);
    if (response.status === 200) {
      showAlert(getLanguage('saving_settings'),
        getLanguage('changes_success'), 'success');
      setPoll(response.data.data);
      setUpdatedPoll(response.data.data);
    } else {
      if (apiCodeToString(response.data.errorCode))
        showAlert(response.statusText,
          `${apiCodeToString(response.data.errorCode)}`, 'alert');
      else
        showAlert(response.statusText, `An error occured while deleting the poll.
      ErrorCode: ${response.data.errorCode}`, 'alert');
    }
    setSaving(false);
  };

  const showAlert = (title, message, type) => {
    setAlert({title, message, type });
    setTimeout(() => {
      setAlert({title: null, message: null, type: null });
    }, 8000);
  };

  const handleDeletePoll = async () => {
    setDeleting(true);
    const response = await deletePoll(poll.pollID, user.token);
    if (response.status === 200) {
      showAlert('Deleting poll...', 'Poll deleted successfully !', 'success');
      setDeleteModal(false);
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } else {
      if (apiCodeToString(response.data.errorCode))
        showAlert(response.statusText,
          `${apiCodeToString(response.data.errorCode)}`, 'alert');
      else
        showAlert(response.statusText, `An error occured while deleting the poll.
      ErrorCode: ${response.data.errorCode}`, 'alert');
      setDeleteModal(false);
    }
    setDeleting(false);
  };

  function hasScope(user, scope) {
    const userRoles = user.roles;

    for (let i = 0; i < userRoles.length; i++) {
      const role = userRoles[i];
      if (!role.scopes)
        continue;
      if (role.scopes.includes(scope))
        return true;
    }

    return false;
  }

  if (poll?.status === 404) return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10 overflow-auto min-h-screen'>
      <CustomAlert type='warning' title={getLanguage('poll_not_found_title')}
        message={getLanguage('poll_not_found_description')} />
    </div>
  );

  if (isLoading) return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10 overflow-auto min-h-screen'>
      <PollEditSkeleton />
    </div>
  );

  return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
      overflow-auto min-h-screen'>
      <div className='w-full flex flex-col'>
        <SectionTitle title={getLanguage('poll_information')}
          subtitle={getLanguage('poll_information_subtitle')} goBack={true} />
        {deleteModal && (
          <DeleteModal title={getLanguage('poll_edit_modal_title')}
            subtitle={`"${poll.title}"`} close={() => setDeleteModal(false)}>
            <div className='flex flex-row gap-3 w-full justify-between
            flex-wrap sm:flex-nowrap'>
              <Button disabled={deleting} className='w-full' type='danger'
                onClick={() => handleDeletePoll()} loading={deleting}>
                {getLanguage('poll_edit_delete_btn')}
              </Button>
              <Button className='w-full' disabled={deleting} type='secondary'
                onClick={() => setDeleteModal(false)}>
                {getLanguage('cancel')}
              </Button>
            </div>
          </DeleteModal>
        )}
        <Container>
          <div className='mb-6'>
            <label htmlFor='title' className='block mb-2 text-sm
            font-medium text-white'>
              {getLanguage('title')}
            </label>
            <Input id='title' placeholder={getLanguage('poll_edit_title')}
              maxLength='200' value={updatedPoll.title} type='text'
              onChange={() => handleTitleChange()} disabled={poll.ended} />
          </div>
          <div className='mb-6'>
            <label htmlFor='description' className='block mb-2 text-sm
            font-medium text-white'>
              {getLanguage('description')}
            </label>
            <Input id='description' placeholder={getLanguage('poll_edit_description')}
              maxLength='4000' value={updatedPoll.description || ''} type='text'
              textArea={true} onChange={() => handleDescriptionChange()}
              disabled={poll.ended} rows='2'/>
          </div>
          <div className='mb-6'>
            <label htmlFor='image' className='block mb-2 text-sm
            font-medium text-white'>
              {getLanguage('image_url')}
            </label>
            <Input id='image' placeholder='https://example.com/image.png'
              type='url' onChange={() => handleImageChange()} disabled={poll.ended}
              value={updatedPoll.image || ''} />
          </div>
          <div className='mb-6'>
            <label htmlFor='ended' className='block mb-2 text-sm
            font-medium text-white'>
              {getLanguage('ended')}
            </label>
            <label className='relative inline-flex items-center
              cursor-pointer'>
              <input type='checkbox' className='sr-only peer
              disabled:opacity-50' id='ended' disabled={poll.ended}
              onChange={() => handleEndedChange()}
              checked={updatedPoll.ended} />
              <div className="w-11 h-6 rounded-full peer
              peer-focus:ring-4 peer-focus:ring-main-800 bg-secondary-450
              peer-checked:after:translate-x-full
              peer-checked:after:border-white after:content-['']
              after:absolute after:top-0.5 after:left-[2px] after:bg-white
              after:border-gray-300 after:border after:rounded-full after:h-5
              after:w-5 after:transition-all border-gray-600
              peer-checked:bg-main-600"/>
            </label>
          </div>
          {poll.ended && (
            <CustomAlert type='warning' title={getLanguage('poll_edit_ended_title')}
              message={getLanguage('poll_edit_ended_description')} className='mb-5' />
          )}
          {poll.ownerID !== user.id && !hasScope(user, 'UPDATE_POLLS') && (
            <CustomAlert type='warning' title={getLanguage('poll_edit_owner_title')}
              message={getLanguage('poll_edit_owner_description')} className='mb-5' />
          )}
          <div className='flex flex-col gap-3'>
            <button className='transition-colors duration-150 ease-in-out
            items-center bg-main-500 hover:bg-main-480 active:bg-main-600
            text-white font-medium py-2 px-7 rounded-lg flex flex-row
            justify-center w-full h-10 outline-none disabled:opacity-50
            disabled:hover:bg-main-500'
            onClick={() => handleChanges()} disabled={poll.ended ||
            (poll.ownerID !== user.id && !hasScope(user, 'UPDATE_POLLS'))}>
              {saving ? (
                <ArrowPathIcon className='w-6 h-6 animate-spin' />
              ) : (
                getLanguage('save_changes')
              )}
            </button>
            <div className='flex flex-row gap-3 flex-wrap sm:flex-nowrap'>
              <button className='transition-colors duration-150 ease-in-out
              items-center bg-gray-440 hover:bg-gray-400 active:bg-gray-480
              text-white font-medium py-2 px-7 rounded-lg flex flex-row
              justify-center w-full h-10 outline-none disabled:opacity-50
              disabled:hover:bg-gray-500'
              onClick={() => {
                navigate(`/polls/${poll.pollID}/results`);
              }}>
                {getLanguage('see_poll_page')}
              </button>
              <button className='transition-colors duration-150 ease-in-out
              items-center bg-red-500 hover:bg-red-480 active:bg-red-600
              text-white font-medium py-2 px-7 rounded-lg flex flex-row
              justify-center w-full h-10 outline-none disabled:opacity-50
              disabled:hover:bg-red-500'
              onClick={() => setDeleteModal(true)}
              disabled={poll.ownerID !== user.id &&
              !hasScope(user, 'DELETE_POLLS')}>
                {getLanguage('delete_poll')}
              </button>
            </div>
          </div>
        </Container>
        {alert.message && (
          <CustomAlert message={alert.message} type={alert.type}
            title={alert.title} className='fixed bottom-10 right-4 left-4 z-50' />
        )}
      </div>
    </div>
  );
};
