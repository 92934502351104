import React from 'react';
import PropTypes from 'prop-types';

export const Input = ({
  type, value, placeholder, onChange, className,
  textArea, rows, maxLength, id, required, title
}) => {
  Input.propTypes = {
    type: PropTypes.string.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string,
    onChange: PropTypes.func,
    className: PropTypes.string,
    textArea: PropTypes.bool,
    rows: PropTypes.string,
    maxLength: PropTypes.string,
    id: PropTypes.string.isRequired,
    required: PropTypes.bool,
    title: PropTypes.string
  };

  if (textArea === true) return (
    <>
      {title && (
        <label htmlFor={id} className='block mb-2 text-sm font-medium text-white
        text-opacity-80'>
          {title}
        </label>
      )}
      <textarea
        type={type}
        id={id}
        className={`border text-sm rounded-lg block w-full p-2.5 bg-gray-700
        border-gray-200/10 placeholder-gray-400 text-white outline-none
      focus:ring-main-500 focus:border-main-500 disabled:opacity-50 ${className}`}
        maxLength={maxLength}
        placeholder={placeholder}
        rows={rows}
        value={value}
        onChange={onChange} />
    </>
  );

  return (
    <>
      {title && (
        <label htmlFor={id} className='block mb-2 text-sm font-medium text-white
        text-opacity-80'>
          {title}
          {required && <span className='text-red-500 ml-1'>*</span>}
        </label>
      )}
      <input
        type={type}
        id={id}
        className={`border text-sm rounded-lg block w-full p-2.5 ${className}
        bg-gray-700 border-gray-200/10 placeholder-gray-400 text-white
        focus:ring-main-500 outline-none focus:border-main-500 disabled:opacity-50`}
        placeholder={placeholder}
        value={value}
        onChange={onChange}/>
    </>
  );
};

export default Input;
