import languages from './traductions.json';
import Cookies from 'universal-cookie';

export function getLanguage(textCode) {
  const cookies = new Cookies();
  const languageCode = cookies.get('language');
  let string = null;
  if (languages[textCode]) {
    if (languages[textCode][languageCode])
      string = languages[textCode][languageCode];
    else
      string = languages[textCode]['en'];
  }

  return string || textCode;
}
