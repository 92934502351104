import axios from 'axios';

export async function getUserGuilds(id, token) {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/users/${id}/guilds`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    params: {
      botOn: true
    }
  };

  let response = await axios.request(config).then((response) => {
    return response.data.data;
  }).catch((error) => {
    console.info(error);
    return null;
  });
  return response;
}
