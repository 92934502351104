import React from 'react';
import { Header } from '../components/header';
import { Sidebar } from '../components/sidebar';
import { Footer } from '../components';
import PropTypes from 'prop-types';

export const Layout = ({ children }) => {
  Layout.propTypes = {
    children: PropTypes.node.isRequired
  };
  return (
    <div className='bg-secondary-480 h-fit min-h-screen flex flex-col'>
      <Header />
      <div className='flex flex-row flex-1'>
        <Sidebar />
        {children}
      </div>
      <Footer />
    </div>
  );
};
