import axios from 'axios';

export default function setUserFavorites(token, favorites) {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: 'https://api.coodo.xyz/user/favorites',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    data: {
      favoritesPolls: favorites
    }
  };
  const response = axios.request(config).then((response) => {
    return response;
  }).catch((err) => {
    console.error(err);
    return err.response;
  });
  return response;
}
