import axios from 'axios';

export async function updateRoles(roles, token) {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    data: roles,
    url: 'https://api.coodo.xyz/roles',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  let response = await axios.request(config);
  return response;
}
