import moment from 'moment';
import 'moment/locale/fr';
import 'moment/locale/es';
import 'moment/locale/ru';
import 'moment/locale/ro';

import Cookies from 'universal-cookie';

const cookies = new Cookies();
const language = cookies.get('language') ?? 'en';

moment.locale(language);
moment.relativeTimeThreshold('ss', 0);

export default moment;
