import axios from 'axios';

export async function getUserRecurrentPolls(userId, token) {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/users/${userId}/recurrentpolls`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };
  let response = await axios.request(config).then((response) => {
    return response.data.data;
  }).catch((err) => {
    console.info(err);
    return null;
  });
  return response;
}
