import React from 'react';
import {
  MagnifyingGlassIcon,
  ChevronRightIcon
} from '@heroicons/react/24/solid';
import { BannerPremium, Faq } from '../../components';
import { getLanguage } from '../../language/getLanguage';
import { useNavigate } from 'react-router-dom';

export const GetStarted = () => {
  const faqData = {
    'title': getLanguage('home_faq_title'),
    'questions': [
      {
        'question': getLanguage('home_faq_1_question'),
        'answer': getLanguage('home_faq_1_response')
      }
    ]
  };
  const navigate = useNavigate();
  function handleSubmit(event) {
    event.preventDefault();
    const form = event.target;
    const input = form.querySelector('input[type="search"]');
    const pollId = input.value.trim();
    if (pollId) {
      const searchUrl = `/polls/${pollId}/results`;
      navigate(searchUrl);
    }
  }

  return (
    <div className='mx-auto w-full text-center p-5 lg:p-10 flex flex-col
    items-center min-h-screen gap-32'>
      <div className="inset-0 absolute -top-1/2 w-full h-full
      rounded-full bg-gradient-radial from-[#BDFFFB] via-[#7478FF]
      to-[#7478FF] filter blur-3xl opacity-10"></div>
      <div className=''>
        <a href='user/favorites' className='inline-flex justify-between
          items-center py-1 px-1 pr-4 mb-7 text-sm rounded-full
          ring-1 ring-main-500 text-white transition-all duration-200
          hover:bg-main-500 relative group'>
          <span className='text-xs bg-main-500 rounded-full text-white px-4
            py-1.5 font-regular group-hover:bg-main-600 hidden md:block'>
            {getLanguage('new')}
          </span>
          <span className='text-sm font-medium font-regular text-white ml-3'>
            {getLanguage('new_feature_bookmark')}
          </span>
          <ChevronRightIcon className='w-4 h-4 ml-2' aria-hidden='true' />
        </a>
        <h1 className='mb-4 text-4xl font-extrabold tracking-tight leading-none
        md:text-5xl lg:text-6xl text-white font-bold relative'>
          {getLanguage('search_a_poll')}
        </h1>
        <p className='mb-8 text-lg font-normal lg:text-xl sm:px-16 lg:px-48
        text-gray-100 font-medium relative'>
          {getLanguage('search_a_poll_description')}
        </p>
        <form className='w-full max-w-md mx-auto' onSubmit={handleSubmit}>
          <div className='relative text-white'>
            <div className='absolute inset-y-0 left-0 flex items-center pl-3.5
              pointer-events-none'>
              <MagnifyingGlassIcon className='w-5 h-5' aria-hidden='true' />
            </div>
            <input type='search' id='default-email' className='block w-full p-4
            pl-10 text-sm border rounded-lg
            bg-tertiary border-gray-700 placeholder-gray-300 text-white
            focus:border-main-500 outline-none'
            placeholder={getLanguage('search_a_poll_id')} required></input>
            <button type='submit' className='absolute right-2.5 bottom-2.5
            focus:ring-4 focus:outline-none font-medium rounded-lg text-sm px-4
            py-2 bg-main-600 hover:bg-main-700 focus:ring-main-800'>
              {getLanguage('search')}
            </button>
          </div>
        </form>
      </div>
      <Faq data={faqData} />
      <BannerPremium />
    </div>
  );
};
