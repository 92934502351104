import React from 'react';
import {
  BeakerIcon
} from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { getLanguage } from '../../language/getLanguage';

export const BetaBadge = ({ className }) => {
  BetaBadge.propTypes = {
    className: PropTypes.string
  };
  return (
    <span className={`text-xs font-medium px-2 py-1
    rounded-full bg-opacity-40 flex items-center w-fit gap-1 bg-main-500/20
      text-main-500 h-fit ${className} whitespace-nowrap`}>
      <div className='shrink-0 h-4 w-4'>
        <BeakerIcon />
      </div>
      {getLanguage('beta')}
    </span>
  );
};

export default BetaBadge;
