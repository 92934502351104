import { hexToRGBA } from './hexToRGBA';

export function getGradient(ctx, chartArea, chart, index) {
  const dataset = chart.data.datasets[index].data;
  let highestValue = Math.max(...dataset.filter((value) => value !== undefined));
  let lowestValue = Math.min(...dataset.filter((value) => value !== undefined));
  const top = chart.scales.y.getPixelForValue(highestValue);
  const bottom = chart.scales.y.getPixelForValue(lowestValue) + 20;

  const hexColor = chart.data.datasets[index].borderColor;

  const gradient = ctx.createLinearGradient(0, top, 0, bottom);
  gradient.addColorStop(0, hexToRGBA(hexColor, 0.2));
  gradient.addColorStop(1, hexToRGBA(hexColor, 0));
  return gradient;
}
