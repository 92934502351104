import React from 'react';
import { Typography } from '@material-tailwind/react';
import logo from '../../assets/logo.png';
import iconGithub from '../../assets/github-solid.svg';
import iconDiscord from '../../assets/Discord_Logo.svg';
import { VersionBadge } from '../badges';
import { getLanguage } from '../../language/getLanguage';

const currentYear = new Date().getFullYear();

export const Footer = () => {
  let sections = [
    {
      'name': getLanguage('footer_product'),
      'options': [
        {
          'name': getLanguage('footer_product_dashboard'),
          'href': 'https://dashboard.coodo.xyz'
        },
        {
          'name': getLanguage('footer_product_support'),
          'href': '/usage'
        },
        {
          'name': getLanguage('footer_product_invite'),
          'href': 'http://invite.coodo.xyz'
        }
      ]
    },
    {
      'name': getLanguage('footer_help'),
      'options': [
        {
          'name': getLanguage('footer_help_support'),
          'href': 'http://discord.coodo.xyz'
        },
        {
          'name': getLanguage('footer_help_documentation'),
          'href': 'http://help.coodo.xyz'
        },
        {
          'name': getLanguage('footer_help_status'),
          'href': 'http://status.coodo.xyz'
        },
        {
          'name': getLanguage('footer_help_website'),
          'href': 'https://coodo.xyz'
        }
      ]
    },
    {
      'name': getLanguage('footer_legal'),
      'options': [
        {
          'name': getLanguage('footer_legal_tos'),
          'href': 'http://pp.coodo.xyz/'
        },
        {
          'name': getLanguage('footer_legal_pp'),
          'href': 'http://pp.coodo.xyz/'
        },
        {
          'name': 'Contact - contact@coodo.xyz',
          'href': 'http://contact.coodo.xyz/'
        }
      ]
    }
  ];

  return (
    <footer className='w-full bg-secondary-500 bottom-0 z-40 border-t-2
    sm:border-t-0 border-gray-500'>
      <div className='w-full max-w-7xl mx-auto pt-10 px-10'>
        <div className='flex justify-between flex-wrap items-start gap-5'>
          <div className='flex flex-row items-center gap-3'>
            <img src={logo} alt='StrawPoll logo' className='w-12 rounded-full' />
            <h1 className='text-white text-3xl font-bold m-0'>StrawPoll</h1>
          </div>
          <div className='flex flex-row gap-4 sm:gap-20 justify-between flex-wrap'>
            {sections.map((section) => (
              <ul key={section.name} className='w-max'>
                <h3 className='mb-3 font-medium text-gray-0 text-lg'>
                  {section.name}
                </h3>
                {section.options.map((link) => (
                  <li key={link.name} className='py-1'>
                    <a href={link.href} className='text-gray-200
                    font-regular transition-colors hover:text-gray-300
                    cursor-pointer text-base'>{link.name}</a>
                  </li>
                ))}
              </ul>
            ))}
          </div>
        </div>
        <div className='mt-12 flex w-full flex-col items-center justify-center
        border-t border-blue-gray-50 py-4 md:flex-row md:justify-between'>
          <h3 className='mb-4 text-center text-gray-200 md:mb-0 font-medium'>
            &copy; {currentYear} <a href='https://coodo.xyz/'>Coodo.xyz</a>. {
              getLanguage('footer_all_rights')}
          </h3>
          <div className='flex gap-4 text-blue-gray-900 sm:justify-center'>
            <VersionBadge />
            <Typography as='a' href='https://github.com/Coodo-xyz'
              className='opacity-80 transition-opacity hover:opacity-100'>
              <img src={iconGithub} alt='Github' className='w-6 h-6 filter
              invert-70'/>
            </Typography>
            <Typography as='a' href='http://discord.coodo.xyz'
              className='opacity-80 transition-opacity hover:opacity-100'>
              <img src={iconDiscord}
                alt='Discord' className='w-6 h-6'/>
            </Typography>
          </div>
        </div>
      </div>
    </footer>
  );
};
