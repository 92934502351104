import axios from 'axios';

export async function updateRecurrentPoll(poll, token) {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    data: {
      title: poll.title,
      description: poll.description,
      active: poll.active,
      image: poll.image,
      options: poll.options,
      channel: poll.channel,
      cycle: {
        previous: poll.cycle.previous
      }
    },
    url: `https://api.coodo.xyz/recurrentpolls/${poll.id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  let response = await axios.request(config).then((response) => {
    return response;
  }).catch((error) => {
    return error.response;
  });

  return response;
}
