import React from 'react';
import {
  SparklesIcon
} from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { getLanguage } from '../../language/getLanguage';

export const NewBadge = ({ className }) => {
  NewBadge.propTypes = {
    className: PropTypes.string
  };
  return (
    <span className={`text-xs font-medium px-2 py-1
    rounded-full flex items-center w-fit gap-1 bg-green-400/20
      text-green-500 h-fit ${className} whitespace-nowrap`}>
      <div className='shrink-0 h-4 w-4'>
        <SparklesIcon />
      </div>
      {getLanguage('new')}!
    </span>
  );
};

export default NewBadge;
