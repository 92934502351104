import React, { useEffect  } from 'react';
import PropTypes from 'prop-types';

const AdsComponent = (props) => {
  AdsComponent.propTypes = {
    dataAdSlot: PropTypes.string.isRequired
  };
  const { dataAdSlot } = props;

  useEffect(() => {
    try {
      (window.adsbygoogle = window.adsbygoogle || []).push({});
    } catch (e) {
      console.error('Adsbygoogle error');
    }
  },[]);
  return (
    <>
      <ins className="adsbygoogle"
        style={{ display: 'block' }}
        data-ad-client="ca-pub-1224720259860838"
        data-ad-slot={dataAdSlot}
        data-ad-format="auto"
        data-full-width-responsive="true">
      </ins>
    </>
  );
};

export default AdsComponent;
