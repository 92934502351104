import React from 'react';
import PropTypes from 'prop-types';
import {
  CheckBadgeIcon
} from '@heroicons/react/24/outline';

import { Faq, CustomAlert, Container } from '../../components';
import { DiscountBadge, NewBadge } from '../../components/badges';
import { getLanguage } from '../../language/getLanguage';

export const Plans = ({ user }) => {
  Plans.propTypes = {
    user: PropTypes.object
  };

  const faqData = {
    'title': getLanguage('plans_faq_title'),
    'questions': [
      {
        'question': getLanguage('plans_faq_1_question'),
        'answer': getLanguage('plans_faq_1_response')
      },
      {
        'question': getLanguage('plans_faq_2_question'),
        'answer': getLanguage('plans_faq_2_response')
      },
      {
        'question': getLanguage('plans_faq_3_question'),
        'answer': getLanguage('plans_faq_3_response')
      }
    ]
  };

  const plansData = [
    {
      'name': getLanguage('plans_1_name'),
      'price': '0',
      'description': getLanguage('plans_1_description'),
      'features': getLanguage('plans_1_features')
    },
    {
      'name': getLanguage('plans_2_name'),
      'price': '1.90',
      'discount': 40,
      'priceDescription': getLanguage('plans_2_descriptionprice'),
      'description': getLanguage('plans_2_description'),
      'features': getLanguage('plans_2_features')
    },
    {
      'name': getLanguage('plans_3_name'),
      'price': '9.90',
      'priceDescription': getLanguage('plans_3_descriptionprice'),
      'description': getLanguage('plans_3_description'),
      'features': getLanguage('plans_3_features')
    }
  ];

  const selectPremium = (plan) => {
    // eslint-disable-next-line max-len
    window.open(`https://api.coodo.xyz/payment?userId=${user.id}&plan=${plan}`, '_blank');
  };

  return (
    <Container title={getLanguage('plans_description')}>
      <div className='flex flex-col gap-4'>
        <div className='flex flex-wrap-reverse
        gap-4 justify-around'>
          {plansData.map((plan, i) => (
            <div key={i} className={`flex flex-col flex-1 bg-gray-700
            items-start rounded-lg p-5 max-w-[380px] min-w-[250px] relative
            z-1 min-h-[350px] overflow-hidden hover:scale-101 transition-all
            duration-300 ease-in-out
            ${i === 1 && 'border border-premium-500'}`}>
              <div className={`absolute -bottom-56 -left-3 w-full h-full
              rounded-full bg-gradient-radial
              ${i === 1 && 'from-premium-500'}
              ${i === 2 && 'from-premium-500'}
              to-gray-900 filter blur-3xl opacity-20`}></div>
              <div className='flex justify-between w-full items-center'>
                <h2 className='text-white text-2xl font-bold m-0'>
                  {plan.name}
                </h2>
                {i === 2 && (
                  <NewBadge className='ml-0 sm:ml-2' />
                )}
                {plan.discount && (
                  <DiscountBadge className='ml-0 sm:ml-2' value={plan.discount} />
                )}
              </div>
              <p className='text-gray-200 text-base'>
                {plan.description}
              </p>
              <div className='flex flex-row items-end gap-2 mt-4'>
                {plan.discount && (
                  <p className={`text-4xl font-extraBold bg-clip-text mt-8 z-10
                    ${i === 1 ? ('bg-premium-light text-transparent') : ('text-white')}`}>
                    {Number((plan.price - (plan.price * (plan.discount / 100)))
                      .toFixed(2))}€
                  </p>
                )}
                <p className={`${plan.discount ? 'text-xl' : 'text-4xl'} font-extraBold
                bg-clip-text mt-8 z-10 ${i === 1 && !plan.discount ?
              ('bg-premium-light text-transparent') : ('text-white')}`}>
                  {plan.discount ? (
                    <strike className='opacity-75'>
                      {plan.price}€
                    </strike>
                  ) : (
                    <>
                      {plan.price}€
                    </>
                  )}
                </p>
              </div>
              {plan.priceDescription && (
                <p className='text-gray-200 text-base z-10'>
                  {plan.priceDescription}
                </p>
              )}
              <div className='flex flex-col gap-2 mt-4 mb-6 z-10'>
                {plan.features.map((feature, x) => (
                  <p key={x} className='text-gray-100 text-base flex flex-row'>
                    <CheckBadgeIcon className={`w-6 h-6 mr-1
                    ${i === 0 ? 'text-gray-300' : 'text-green-500'}`} />
                    {feature}
                  </p>
                ))}
              </div>
              <div className='flex flex-row items-center w-full mt-auto z-10
              gap-3'>
                {i === 0 && (
                  <button className='transition-colors duration-150 ease-in-out
                  font-medium py-2 px-7 rounded-lg flex flex-row justify-center
                  bg-main-500 hover:bg-main-480 focus:bg-main-600 text-white
                  disabled:opacity-60 w-full disabled:cursor-not-allowed
                  disabled:hover:bg-main-500'
                  disabled>
                    {getLanguage('get_the')} {plan.name}
                  </button>
                )}
                {i === 2 && (
                  <button className='transition-colors duration-150 ease-in-out
                  font-medium py-2 px-7 rounded-lg flex flex-row justify-center
                  from-premium-500 to-premium-light bg-gradient-to-r
                  focus:to-premium-500 hover:to-premium-500
                  text-black disabled:opacity-60 disabled:cursor-not-allowed
                  w-full disabled:hover:to-premium-light text-black'
                  onClick={() => selectPremium('life')}>
                    {getLanguage('get_the')} {plan.name}
                  </button>
                )}
                {i === 1 && (
                  <button className='transition-colors duration-150 ease-in-out
                  font-medium py-2 px-7 rounded-lg flex flex-row justify-center
                  from-premium-500 to-premium-light bg-gradient-to-r
                  focus:to-premium-500 hover:to-premium-500
                  text-black disabled:opacity-60 disabled:cursor-not-allowed
                  w-full disabled:hover:to-premium-light text-black'
                  disabled={user && user.roles.map(role => role.id).includes(250)}
                  onClick={() => selectPremium('month')}>
                    {getLanguage('get_the')} {plan.name}
                  </button>
                )}
              </div>
            </div>
          ))}
        </div>
        {user && user.premium === true && (
          <CustomAlert message='You already have a premium subscription on patreon!
          Contact us on discord to transfer your subscription and to benefit from a
          price reduction.'
          type='warning' button='link' title='Transfer your subscription'
          href='http://discord.coodo.xyz' buttonTitle='Join our discord server'/>
        )}
        {user && user.roles.map(role => role.id).includes(250) && (
          <CustomAlert message={getLanguage('already_have_nitro')}
            type='info' button='link' title={getLanguage('premium_status')}
            href='/billing' buttonTitle={getLanguage('manage_premium')}
          />
        )}
        {user && !user.roles.map(role => role.id).includes(260) &&
        !user.roles.map(role => role.id).includes(250) &&
        user.premium_type !== 0 && (
          <CustomAlert message={getLanguage('premium_nitro_boost')}
            type='nitro' button='link' title={getLanguage('get_premium_for_free')}
            href='http://discord.coodo.xyz'
            buttonTitle={getLanguage('join_discord_server')}
          />
        )}
      </div>
      <Faq data={faqData} className='mt-20' />
    </Container>
  );
};
