import React from 'react';
import { Router } from './router/router';

function App() {
  return (
    <Router/>
  );
}

export default App;
