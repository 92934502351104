import React, { useEffect, useState } from 'react';

import { Layout } from '../Layout';
import { AuthUser, getUserByToken } from '../../utils';
import { Loading } from '../../components';
import { RecurrentPollEditComponent } from '../../components/polls';

export const RecurrentPollEdit = () => {
  const reactUser = AuthUser();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const data = await getUserByToken(reactUser.token);
      data.token = reactUser.token;
      setUser(data);
    };

    if (!user)
      fetch();
  }, [reactUser]);

  return (
    <Layout>
      <div className='flex flex-col w-full'>
        {user ? (
          <RecurrentPollEditComponent user={user} />
        ) : (
          <Loading />
        )}
      </div>
    </Layout>
  );
};
