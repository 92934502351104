import React, { useEffect, useState } from 'react';
import { AuthUser, getUserBilling, getUserByToken } from '../../utils';
import { Button, Container, CustomAlert, Loading, SectionTitle } from '../../components';
import { Layout } from '../Layout';
import { getLanguage } from '../../language/getLanguage';
import { ArrowDownTrayIcon, EyeIcon } from '@heroicons/react/24/solid';
import moment from 'moment/moment';

export const Billing = () => {
  const reactUser = AuthUser();
  const [user, setUser] = useState(reactUser);
  const [isLoading, setIsLoading] = useState(true);
  const [billing, setBilling] = useState(null);

  useEffect(() => {
    const fetchUser = async () => {
      const user = await getUserByToken(reactUser.token);
      user.token = reactUser.token;
      setUser(user);
      setIsLoading(false);
      const billing = await getUserBilling(user.token);
      setBilling(billing);
    };
    if (reactUser)
      fetchUser();
    else
      setIsLoading(false);
  }, [reactUser]);

  if (isLoading) return (
    <Layout>
      <div className='flex flex-col flex-1 p-5 lg:p-10 min-h-screen'>
        <SectionTitle title={getLanguage('billing')}
          subtitle={getLanguage('billing_description')} />
        <Loading />
      </div>
    </Layout>
  );

  return (
    <Layout>
      <div className='flex flex-col flex-1 p-5 lg:p-10 min-h-screen'>
        <SectionTitle title={getLanguage('billing')}
          subtitle={getLanguage('billing_description')} />
        <Container title={getLanguage('invoices')} className='flex-1'>
          <ul className='flex flex-col gap-4'>
            {billing?.invoices && billing.invoices.map((bill, index) => (
              <li key={index} className='gap-2 flex flex-row justify-between flex-wrap'>
                <p className='text-gray-100 font-regular text-sm text-left
                select-all'>
                  {bill.id}
                </p>
                <div className='flex flex-row gap-2'>
                  <Button type='secondary' href={bill.invoice_pdf} >
                    <ArrowDownTrayIcon className='w-6 h-6' />
                  </Button>
                  <Button type='primary' href={bill.hosted_invoice_url} target='_blank'>
                    <EyeIcon className='w-6 h-6 mr-2' />
                    {getLanguage('view_invoice')}
                  </Button>
                </div>
              </li>
            ))}
          </ul>
          {billing?.invoices.length <= 0 && (
            <CustomAlert type='info' message={getLanguage('no_invoices')}
              title={getLanguage('no_invoices_title')} />
          )}
          {!billing?.invoices && (
            <Loading />
          )}
        </Container>
        <Container title={getLanguage('payments')} className='flex-1'>
          <ul className='flex flex-col gap-4'>
            {billing?.payments && billing.payments.map((payment, index) => (
              <li key={index} className='gap-2 flex flex-row justify-between flex-wrap'>
                <p className='text-gray-100 font-regular text-sm text-left
                select-all'>
                  {payment.id}
                </p>
                <div className='flex flex-row gap-10'>
                  <div className='font-medium text-gray-100 text-left'>
                    {(payment.amount / 100).toLocaleString(user.locale, {
                      style: 'currency',
                      currency: payment.currency
                    })}
                  </div>
                  <div className='font-medium text-gray-100 text-left'>
                    {moment(payment.created * 1000).format('DD/MM/YYYY')}
                  </div>
                </div>
              </li>
            ))}
          </ul>
          {billing?.payments.length <= 0 && (
            <CustomAlert type='info' message={getLanguage('no_payments')}
              title={getLanguage('no_payments_title')} />
          )}
          {!billing?.payments && (
            <Loading />
          )}
        </Container>
        <Container title={getLanguage('subscription')} className='flex-1'>
          {user.roles.find(role => role.id === 250) ? (
            <div className='flex flex-col gap-4'>
              <p className='text-gray-100 text-lg font-regular
              text-left'>
                {getLanguage('billing_description_premium_curently')}
                <span className='font-bold'> Premium</span> plan.
              </p>
              <Button type='primary' name={getLanguage('manage_subscription')}
                // eslint-disable-next-line max-len
                href={`https://billing.stripe.com/p/login/8wM5nkh0n6RYgGk9AA?prefilled_email=${user.email}`} />
              <CustomAlert type='info' message={getLanguage('billing_info')}
                title={getLanguage('billing_info_title')} button='link'
                buttonTitle={getLanguage('join_discord_server')}
                href='http://discord.coodo.xyz'/>
            </div>
          ) : (
            <div className='flex flex-col gap-4'>
              <p className='text-gray-100 text-lg font-regular
              text-left'>
                {getLanguage('billing_description_premium')}
              </p>
              <Button type='primary' name={getLanguage('upgrade_to_premium')}
                href='/usage' />
            </div>
          )}
        </Container>
      </div>
    </Layout>
  );
};
