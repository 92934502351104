import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
  deletePoll,
  getPollResults,
  getUser,
  hasScope,
  setUserFavorites
} from '../../../utils';
import moment from '../../../utils/moment';
import CustomAlert from '../../Alert';
import config from '../../../config.json';
import { PollCanva } from '../PollCanva';
import { Button } from '../../Button';
import {
  CheckIcon,
  XMarkIcon,
  BookmarkIcon,
  PlusCircleIcon,
  ShareIcon,
  DocumentArrowDownIcon,
  PencilIcon,
  TrashIcon
} from '@heroicons/react/24/solid';
import {
  BookmarkIcon as BookmarkIconOutline
} from '@heroicons/react/24/outline';
import { PollUsers } from '../PollUsers';
import PropType from 'prop-types';
import { SectionTitle } from '../../SectionTitle';
import { PollInformationsSkeleton } from './PollInformationsSkeleton';
import { getLanguage } from '../../../language/getLanguage';
import Container from '../../Container';
import { getResultsCSVStats, getResultsCSVUsers } from '../../../utils/getResultsCSV';
import PopHover from '../../Popover';
import { ExportModal, ShareModal } from '../../modal';

// eslint-disable-next-line max-lines-per-function
export const PollInformations = ({ user }) => {
  PollInformations.propTypes = {
    user: PropType.object
  };

  const [poll, setPoll] = useState(null);
  const [owner, setOwner] = useState(null);
  const [isFavorite, setIsFavorite] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  let { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();
  const [shareModal, setShareModal] = useState(false);
  const [exportModal, setExportModal] = useState(false);

  if (params.get('id') && !id)
    id = params.get('id');

  const downloadCSV = async (poll, type) => {
    let csv = '';
    if (type === 'users')
      csv = getResultsCSVUsers(poll);
    if (type === 'stats')
      csv = getResultsCSVStats(poll);
    const hiddenElement = document.createElement('a');
    hiddenElement.href = 'data:text/csv;charset=utf-8,' + encodeURI(csv);
    hiddenElement.target = '_blank';
    hiddenElement.download = `${poll.pollID}.csv`;
    hiddenElement.click();
  };

  useEffect(() => {
    const fetch = async () => {
      const poll = await getPollResults(id, user.token);
      if (!poll.pollID)
        return setPoll(poll);
      setPoll(poll);
      setIsLoading(false);
      const owner = await getUser(poll.ownerID, user.token);
      setOwner(owner);
    };

    if (user?.favoritesPolls?.includes(id))
      setIsFavorite(true);

    if (!poll)
      fetch();
  }, [user]);

  const handleFavorite = async (poll, user) => {
    let favorites = user.favoritesPolls;
    if (isFavorite) {
      favorites = favorites.filter((e) => e !== poll.pollID);
    } else
      favorites.push(poll.pollID);
    setIsFavorite(favorites?.includes(poll.pollID));
    const response = await setUserFavorites(user.token, favorites);
    if (response.status !== 200)
      setIsFavorite(!isFavorite);
  };
  let dateCreate = new Date(parseInt(poll?.timestamp) * 1000);

  let year = dateCreate.getFullYear();
  let month = dateCreate.getMonth();
  let day = dateCreate.getDate();
  let hour = dateCreate.getHours();
  let minute = dateCreate.getMinutes();
  let time = moment([ year, month, day, hour, minute ]).fromNow();

  let countTotal = 0;
  let ids = [];
  let endIn = null;
  if (poll?.buttons) {
    countTotal = poll.buttons.reduce((total, e) => total + e.ids.length, 0);

    poll.buttons.forEach(button => {
      button.ids.forEach(idItem => {
        const id = poll.anonymous ? idItem : idItem.id;
        if (!ids.includes(id))
          ids.push(id);
      });
    });

    if (poll.timestamp_close) {
      let dateClose = new Date(parseInt(poll.timestamp_close) * 1000);
      let year2 = dateClose.getFullYear();
      let month2 = dateClose.getMonth();
      let day2 = dateClose.getDate();
      let hour2 = dateClose.getHours();
      let minute2 = dateClose.getMinutes();
      endIn = moment([ year2, month2, day2, hour2, minute2 ]).fromNow();
    }
  }

  console.info(poll);

  if (poll?.status === 404) return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10 overflow-auto min-h-screen'>
      <div className='w-full flex flex-col'>
        <SectionTitle title={getLanguage('poll_information')}
          subtitle={getLanguage('poll_information_subtitle')} />
        <CustomAlert type='warning' title={getLanguage('poll_not_found_title')}
          message={getLanguage('poll_not_found_description')}
          button='link' buttonTitle={getLanguage('go_home')}
          href='/'/>
      </div>
    </div>
  );

  if (isLoading) return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10 overflow-auto min-h-screen'>
      <PollInformationsSkeleton />
    </div>
  );

  return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
      overflow-auto min-h-screen'>
      {shareModal && (
        <ShareModal
          close={() => setShareModal(false)}
          title={getLanguage('share')}
          options={{
            twitter: true,
            mail: true,
            link: document.location.href
          }}
        />
      )}
      {exportModal && (
        <ExportModal
          close={() => setExportModal(false)}
          poll={poll}
          title={getLanguage('export_results')}
        >
          <div className='flex flex-col w-full text-left'>
            <h3 className='text-lg font-medium text-white text-opacity'>
              {getLanguage('export_results_users')}
            </h3>
            <p className='text-sm font-regular text-white text-opacity-80 mb-2'>
              {getLanguage('export_results_users_description')}
            </p>
            <Button type='primary' onClick={() => {
              downloadCSV(poll, 'users');
            }}>
              {getLanguage('download_csv')}
            </Button>
            <h3 className='text-lg font-medium text-white text-opacity mt-4'>
              {getLanguage('export_results_stats')}
            </h3>
            <p className='text-sm font-regular text-white text-opacity-80 mb-2'>
              {getLanguage('export_results_stats_description')}
            </p>
            <Button type='primary' onClick={() => {
              downloadCSV(poll, 'stats');
            }}>
              {getLanguage('download_csv')}
            </Button>
          </div>
        </ExportModal>
      )}
      <div className='w-full flex flex-col'>
        <SectionTitle title={getLanguage('poll_information')}
          subtitle={getLanguage('poll_information_subtitle')} goBack={true} />
        {poll.timestamp < 1683842400 && (
          <CustomAlert type='warning'
            title={getLanguage('poll_information_problem_title')} className='mb-5'
            message={getLanguage('poll_information_problem_description')} />
        )}
        <Container>
          <div className='flex flex-row justify-between'>
            <div>
              <h1 className='font-semiBold text-white text-2xl truncate
                whitespace-normal'>
                {poll.title}
              </h1>
              {owner ? (
                <h2 className='text-gray-200 font-regular text-base'>
                  {getLanguage('created_by')} <a className='text-main-500
                  cursor-pointer underline' href={`/users/${owner.id || ''}`}>
                    {owner.username}</a> • {time} • {poll.pollID}
                </h2>
              ) : (
                <h2 className='text-gray-200 font-regular text-base'>
                  {getLanguage('created_by')} undefined • {time} • {poll.pollID}
                </h2>
              )}
            </div>
            <PopHover values={[
              {
                name: getLanguage('vote_upp'),
                icon: <PlusCircleIcon className='w-5 h-5' />,
                onClick: () => {
                  navigate(`/polls/${poll.pollID}`);
                }
              },
              {
                name: getLanguage('share'),
                icon: <ShareIcon className='w-5 h-5' />,
                onClick: () => {
                  setShareModal(true);
                }
              },
              {
                name: getLanguage('export_results'),
                icon: <DocumentArrowDownIcon className='w-5 h-5' />,
                onClick: () => {
                  setExportModal(true);
                }
              },
              {
                name: isFavorite ? getLanguage('remove_favorite') :
                  getLanguage('add_favorite'),
                icon: isFavorite ? (
                  <BookmarkIcon className='w-5 h-5' />
                ) : (
                  <BookmarkIconOutline className='w-5 h-5' />
                ),
                onClick: async () => {
                  await handleFavorite(poll, user);
                },
                separator: true
              },
              {
                name: getLanguage('edit'),
                icon: <PencilIcon className='w-5 h-5' />,
                onClick: () => {
                  navigate(`/polls/${poll.pollID}/edit`);
                },
                separator: true
              },
              {
                name: getLanguage('delete'),
                icon: <TrashIcon className='w-5 h-5' />,
                onClick: async () => {
                  const result = await deletePoll(poll.pollID, user.token);
                  if (result.status === 200) {
                    navigate(-1);
                  }
                  return 0;
                },
                hidden: poll.ownerID !== user.id && !hasScope(user, 'DELETE_POLLS'),
                colors: {
                  danger: true
                },
                separator: true
              }
            ]}/>
          </div>
          {poll.description && (
            <p className='text-gray-100 font-medium text-lg mt-2
            w-full md:w-1/2'>
              {poll.description}
            </p>
          )}
          {poll.image && (
            <div className='relative'>
              <img className='w-full object-cover rounded-xl mt-4'
                src={poll.image} alt='poll image'
                onClick={() => {
                  window.open(poll.image, '_blank');
                }}/>
            </div>
          )}
          <div className='flex flex-row justify-between gap-10 flex-wrap
          md:flex-nowrap'>
            <div className='w-full overflow-hidden'>
              <ul className='flex flex-col mt-5 gap-3'>
                {poll.buttons && poll.buttons.map((e, index) => (
                  <li key={index} className='text-white text-base font-regular
                  mb-1'>
                    <div className='flex flex-row justify-between gap-2'>
                      <h1 className='truncate whitespace-nowrap'>
                        {e.title}
                      </h1>
                      <p className='text-gray-200 shrink-0'>
                        {e.ids.length > 0 && (
                          Math.round(e.ids.length * 100 / countTotal) + '%'
                        )} (
                        {e.ids.length} {e.ids.length > 1 ? 'votes' : 'vote'})
                      </p>
                    </div>
                    <div className='w-full bg-secondary-450 rounded-full h-4'>
                      {e.ids.length > 0 && (
                        <div className='h-4 rounded-full'
                          style={{width: `${e.ids.length * 100 / countTotal}%`,
                            backgroundColor: `${config.colors[index]}`}}/>
                      )}
                    </div>
                  </li>
                ))}
              </ul>
              <hr className='h-px my-8 bg-gray-400 border-0'></hr>
              <div className='flex flex-col gap-3'>
                <h1 className='text-white text-base font-regular'>
                  {getLanguage('total_votes')} {countTotal} {countTotal > 1 ?
                    getLanguage('votes') : getLanguage('vote')} ({
                    getLanguage('from')} {ids.length} {ids.length > 1 ?
                    getLanguage('users') : getLanguage('user')})
                </h1>
                <ul>
                  <li className='flex flex-row'>
                    {poll.choices_max > 1 ? (
                      <CheckIcon className='w-6 h-6 text-green-500' />
                    ) : (
                      <XMarkIcon className='w-6 h-6 text-red-500' />
                    )}
                    <h1 className='text-white text-base font-regular ml-2'>
                      {getLanguage('multiples_choices')} ({poll.choices_max})
                    </h1>
                  </li>
                  <li className='flex flex-row'>
                    {poll.anonymous ? (
                      <CheckIcon className='w-6 h-6 text-green-500' />
                    ) : (
                      <XMarkIcon className='w-6 h-6 text-red-500' />
                    )}
                    <h1 className='text-white text-base font-regular ml-2'>
                      {getLanguage('anonymous')}
                    </h1>
                  </li>
                </ul>
              </div>
            </div>
            {ids.length > 0 ? (
              <PollCanva poll={poll} />
            ) : (
              <div className='h-72 w-72 rounded-full bg-secondary-480 flex
              items-center flex-shrink-0'>
                <p className='text-white text-md font-medium text-center
                m-auto p-4'>
                  {getLanguage('poll_information_pie_chart')}
                </p>
              </div>
            )}
          </div>
          <div className='mt-4 flex flex-col gap-2'>
            {poll.timestamp_close ? (
              poll.ended === false ? (
                <CustomAlert type='warning'
                  title={getLanguage('poll_information_over_title')}
                  message={`
                  ${getLanguage('poll_information_over_description_one')}
                  ${endIn}.
                  ${getLanguage('poll_information_over_description_two')}`}
                  button='link'
                  buttonTitle={getLanguage('poll_information_over_vote')}
                  href={`/polls/${poll.pollID}`} />
              ) : (
                <CustomAlert type='info'
                  title={getLanguage('poll_information_isover_title')}
                  message={`
                ${getLanguage('poll_information_isover_description_one')
                } ${endIn}.
                ${getLanguage('poll_information_isover_description_two')}`}/>
              )
            ) : (
              poll.ended === true && (
                <CustomAlert
                  title={getLanguage('poll_information_isover_title')}
                  type='info' message={`
                  ${getLanguage('poll_information_isover_description_one')}.
                  ${getLanguage('poll_information_isover_description_two')}`}/>
              )
            )}
            {poll.hide_results === true && poll.ended === false && (
              <CustomAlert
                title={getLanguage('poll_information_hiden_title')}
                type='info' message={
                  getLanguage('poll_information_hiden_description')} />
            )}
          </div>
          <div className='flex flex-row justify-between gap-4 mt-4 flex-wrap
          sm:flex-nowrap'>
            <Button type='primary' href={`/polls/${poll.pollID}`}
              className='w-full' disabled={poll.ended}>
              <PlusCircleIcon className='w-6 h-6 mr-1' />
              {getLanguage('poll_information_over_vote')}
            </Button>
            <div className='flex flex-row w-full gap-4'>
              <Button type='secondary' className='w-full' onClick={() => {
                setShareModal(true);
              }}>
                <ShareIcon className='w-6 h-6 mr-1' /> {getLanguage('share')}
              </Button>
              <button className='transition-colors duration-150 ease-in-out
              font-medium rounded-lg flex flex-row justify-center h-10
              hover:scale-105
              whitespace-nowrap items-center' onClick={() => {
                handleFavorite(poll, user);
              }}>
                {isFavorite ? (
                  <BookmarkIcon className='w-10 h-10 text-premium-500' />
                ) : (
                  <BookmarkIconOutline className='w-10 h-10
                  text-premium-500'/>
                )}
              </button>
            </div>
          </div>
        </Container>
        {poll.anonymous === false && (poll.hide_results !== true
        || poll.ended === true) && (
          <div className='flex flex-col'>
            <SectionTitle title={getLanguage('poll_users')}
              subtitle={getLanguage('poll_users_description')} />
            <PollUsers poll={poll} />
          </div>
        )}
      </div>
    </div>
  );
};
