import { getUserByToken } from './api/users/getUserByToken';

export async function hasScope(user, scope) {
  let data = user;
  if (!user.roles)
    data = await getUserByToken(user.token);

  if (!data)
    return false;

  const userRoles = data.roles;

  for (let i = 0; i < userRoles.length; i++) {
    const role = userRoles[i];
    if (!role.scopes)
      continue;
    if (role.scopes.includes(scope))
      return true;
  }

  return false;
}
