import axios from 'axios';

export async function getUserPollsCount(id, token) {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/users/${id}/polls/count`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  let response = await axios.request(config).then((response) => {
    return response.data;
  }).catch((error) => {
    return error.response;
  });

  return response;
}
