import React, { Fragment, useState } from 'react';
import PropTypes from 'prop-types';
import { Combobox, Transition } from '@headlessui/react';
import { Bars3BottomRightIcon, CheckIcon } from '@heroicons/react/24/solid';

export const AutoDropdown = ({ value, onChange, values, title, required, disabled }) => {
  AutoDropdown.propTypes = {
    value: PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })).isRequired,
    title: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool
  };
  const [query, setQuery] = useState('');

  const filteredValues = values.filter((item) => {
    return item.text.toLowerCase().includes(query.toLowerCase());
  });

  return (
    <div className={`${disabled ? 'opacity-50' : ''}`}>
      {title && (
        <label className='block mb-2 text-sm font-medium text-white
        text-opacity-80'>
          {title}
          {required && <span className='text-red-500 ml-1'>*</span>}
        </label>
      )}
      <Combobox
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <div className='relative w-full cursor-default outline-none'>
          <Combobox.Input className={`relative w-full rounded-lg bg-gray-700 py-2 pl-3
          pr-10 text-left focus:ring-1 ring-main-450 focus-visible:border-main-500
          text-opacity-80 font-medium sm:text-sm text-white h-[42px] border-gray-200/10
          border outline-none ${required && !value ? 'border-red-500' : ''}`}
          displayValue={(value) => value?.text}
          onChange={(event) => setQuery(event.target.value)}
          />
          <Combobox.Button className='absolute inset-y-0 right-0 flex items-center pr-2
          outline-none'>
            <span className='pointer-events-none absolute inset-y-0 right-0 flex
            items-center pr-2'>
              <Bars3BottomRightIcon
                className='h-5 w-5 text-gray-400'
                aria-hidden='true'
              />
            </span>
          </Combobox.Button>
        </div>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Combobox.Options className='absolute mt-1 max-h-60 w-full overflow-auto
            rounded-md bg-gray-700 p-1 text-base shadow-lg
            focus:outline-none sm:text-sm ring-1 ring-main-500 z-10'>
            {filteredValues.map((e) => (
              <Combobox.Option
                key={e.id} value={e} disabled={e.disabled}
                className={({ active }) =>
                  `relative cursor-pointer select-none pr-4 font-medium
                  text-white text-opacity-80 py-2 pl-3 transition-colors
                  rounded-md ${active ? 'bg-gray-600' : ''}`
                }
              >
                {({ selected }) => (
                  <div className='flex flex-row'>
                    <span className={`block truncate
                      ${selected ? 'font-medium' : 'font-normal'}`}>
                      {e.text}
                    </span>
                    {selected ? (
                      <CheckIcon className='h-5 w-5 inline-block ml-2'
                        aria-hidden='true' />
                    ) : null}
                  </div>
                )}
              </Combobox.Option>
            ))}
            {filteredValues.length === 0 && (
              <span className='block px-4 py-2 text-sm text-white
              text-opacity-80'>No results found</span>
            )}
          </Combobox.Options>
        </Transition>
      </Combobox>
    </div>
  );
};

export default AutoDropdown;
