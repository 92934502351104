import React from 'react';
import PropTypes from 'prop-types';

export const Option = ({ children, tooltip, className }) => {
  Option.propTypes = {
    children: PropTypes.node.isRequired,
    tooltip: PropTypes.string,
    className: PropTypes.string
  };
  return (
    <div className={`flex-col flex-shrink-0 text-center justify-center items-center
    flex w-32 relative ${className}`}>
      <span className='text-white/80 text-sm font-regular rounded-lg peer
      hover:bg-tertiary flex flex-row gap-1 py-1 px-2 items-center transition-all'>
        {children}
      </span>
      {tooltip && (
        <div className='hidden bottom-14 bg-tertiary text-white absolute shadow
        text font-regular rounded py-1 px-2 items-center text-center peer-hover:flex
        whitespace-nowrap'>
          {tooltip}
          <svg className='absolute text-tertiary h-2 top-full left-1/2
          transform -translate-x-1/2' x='0px' y='0px' viewBox='0 0 500 255'
          xmlSpace='preserve' fill='currentColor'>
            <polygon className='fill-current' points='0,0 250,255 500,0' />
          </svg>
        </div>
      )}
    </div>
  );
};

export default Option;
