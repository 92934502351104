import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import {
  BoltIcon, XMarkIcon
} from '@heroicons/react/24/solid';

export const OnlyPremiumModal = ({ children, title, subtitle, close }) => {
  OnlyPremiumModal.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    subtitle: PropTypes.string,
    close: PropTypes.func
  };

  const ref = useRef();

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className='fixed top-0 left-0 right-0 bottom-0
      bg-gray-1000 bg-opacity-50 backdrop-filter backdrop-blur-sm z-50'/>
      <div className='fixed left-0 right-0 top-0 z-50 p-4 animate-fade-in transition-all
      duration-300 w-full h-full flex justify-center'>
        <div className='relative w-fit min-w-0 sm:min-w-[600px] max-w-lg
        max-h-full m-auto' ref={ref}>
          <div className='relative bg-secondary-500 rounded-lg shadow ring-1
          ring-premium-light'>
            {close && (
              <div className='flex flex-row justify-end items-center w-full h-fit'>
                <button className='p-2 text-white text-opacity-80 hover:text-opacity-100
                transition-colors duration-300 rounded-lg'
                onClick={() => close()}>
                  <XMarkIcon className='w-6 h-6'/>
                </button>
              </div>
            )}
            <div className={`${!close && 'pt-6'} pb-6 px-6 text-center flex flex-col
            items-center`}>
              <BoltIcon className='w-16 h-16 text-premium-500'/>
              <h1 className='text-2xl font-bold text-white mt-2'>
                {title}
              </h1>
              <p className='mb-10 text-lg font-normal text-gray-100 w-full
              break-words'>
                {subtitle}
              </p>
              <div className='w-full'>
                {children}
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
