import axios from 'axios';

export async function getUserByToken(token) {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://api.coodo.xyz/user',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  let response = await axios.request(config).then((response) => {
    return response.data.data;
  }).catch((err) => {
    return err.response;
  });
  return response;
}
