import React from 'react';
import { Routes, Route } from 'react-router-dom';

import { Login, Logout } from '../routes';

import { NotFound, Home, Beta, Error, Billing, Usage } from '../pages';

import { PollEdit, PollResults } from '../pages/polls';
import { User, Polls, Favorites, RecurrentPolls } from '../pages/user';
import { Users, SearchUsers } from '../pages/users';

import { Admin, AdminRoles, AdminLogs, AdminStats } from '../pages/admin';

import { Success, Failed } from '../pages/checkout';
import { PollVote } from '../pages/polls/PollVote';
import { RecurrentPollEdit } from '../pages/polls/RecurrentPollEdit';
import { RequireAuth } from 'react-auth-kit';

export const Router = () => {
  return (
    <Routes>
      <Route path='/' element={<Home />} />
      <Route path='*' element={<NotFound />} />
      <Route path='/beta' element={<Beta />} />
      <Route path='/login' element={<Login />} />
      <Route path='/error' element={<Error />} />
      <Route path='/logout' element={<Logout />} />

      <Route path='/results' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <PollResults />
        </RequireAuth>}
      />

      <Route path='/user/polls' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <Polls />
        </RequireAuth>
      } />
      <Route path='/user/recurrentpolls' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <RecurrentPolls />
        </RequireAuth>
      } />
      <Route path='/user/favorites' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <Favorites />
        </RequireAuth>
      } />
      <Route path='/recurrentpolls/:id/edit' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <RecurrentPollEdit />
        </RequireAuth>
      } />
      <Route path='/polls/:id/results' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <PollResults />
        </RequireAuth>
      } />
      <Route path='/polls/:id/edit' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <PollEdit />
        </RequireAuth>
      } />
      <Route path='/polls/:id' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <PollVote />
        </RequireAuth>
      } />
      <Route path='/users/:id' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <Users />
        </RequireAuth>
      } />
      <Route path='/users' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <SearchUsers />
        </RequireAuth>
      } />
      <Route path='/user' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <User />
        </RequireAuth>
      } />
      <Route path='/usage' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <Usage />
        </RequireAuth>
      } />
      <Route path='/billing' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <Billing />
        </RequireAuth>
      } />
      <Route path='/admin' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <Admin />
        </RequireAuth>
      } />
      <Route path='/admin/logs' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <AdminLogs />
        </RequireAuth>
      } />
      <Route path='/admin/roles' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <AdminRoles />
        </RequireAuth>
      } />
      <Route path='/admin/stats' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <AdminStats />
        </RequireAuth>
      } />
      <Route path='/checkout/success' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <Success />
        </RequireAuth>
      } />
      <Route path='/checkout/failed' element={
        <RequireAuth loginPath={`/?state=${window.location.href}`}>
          <Failed />
        </RequireAuth>
      } />
    </Routes>
  );
};
