import React, { useEffect, useState } from 'react';

import { AuthUser, hasScope, getRoles, getScopes, getUserByToken,
  getDiscordRoles } from '../../../utils';

import { Forbidden, Layout } from '../../../pages';
import { Loading } from '../../../components';
import { AdminRolesPermissions } from './AdminRolesPermissions';

export const AdminRoles = () => {
  const reactUser = AuthUser();

  const [user, setUser] = useState(reactUser);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [roles, setRoles] = useState([]);
  const [scopes, setScopes] = useState([]);
  const [discordRoles, setDiscordRoles] = useState([]);

  useEffect(() => {
    const fetch = async () => {
      if (await hasScope(reactUser, 'ADMIN_SETTINGS') === true &&
        await hasScope(reactUser, 'ADMIN_PANEL') === true) {
        setIsAuthorized(true);
        fetchUser();
      } else {
        setIsAuthorized(false);
        setIsLoading(false);
      }
    };

    const fetchUser = async () => {
      const user = await getUserByToken(reactUser.token);
      user.token = reactUser.token;
      setUser(user);
      setIsLoading(false);
    };

    if (!reactUser) {
      setIsAuthorized(false);
      setIsLoading(false);
    } else {
      fetch();
    }
  }, [reactUser]);

  useEffect(() => {
    const fetch = async () => {
      const roles = await getRoles([], reactUser.token);
      setRoles(roles);
      const scopes = await getScopes(reactUser.token);
      setScopes(scopes);
      let discordRoles = await getDiscordRoles(reactUser.token);
      const botRole = discordRoles.find((role) => {
        return role.tags?.bot_id === '823271140410916986';
      });
      const botRolePosition = botRole.position;
      discordRoles = discordRoles.filter((role) => {
        return role.position < botRolePosition &&
        role.position !== 0 && role.managed === false;
      });
      setDiscordRoles(discordRoles);
    };

    if (isAuthorized) {
      fetch();
    }
  }, [isAuthorized]);

  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : isAuthorized ? (
        <AdminRolesPermissions roles={roles} scopes={scopes} user={user}
          serverRoles={discordRoles} />
      ) : (
        <Forbidden />
      )}
    </Layout>
  );
};
