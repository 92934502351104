import React, { useEffect, useState } from 'react';

import { UsageLimits } from '../../components/usage';
import { Layout } from '../Layout';
import { Container, Loading, SectionTitle } from '../../components';
import { AuthUser, getUserByToken } from '../../utils';
import { getLanguage } from '../../language/getLanguage';
import { Plans } from './Plans';

export const Usage = () => {
  const [isLoading, setIsLoading] = useState(true);

  const reactUser = AuthUser();
  const [user, setUser] = useState(reactUser);

  useEffect(() => {
    const fetch = async () => {
      const user = await getUserByToken(reactUser.token);
      user.token = reactUser.token;
      setUser(user);
      setIsLoading(false);
    };
    if (reactUser)
      fetch();
  }, [reactUser]);

  return (
    <Layout>
      <div className='flex flex-col flex-1'>
        {isLoading ? (
          <Loading />
        ) : (
          <div className='flex flex-col flex-1 p-5 lg:p-10 min-h-screen'>
            <SectionTitle title={getLanguage('usage')}
              subtitle={getLanguage('usage_subtitle')} />
            <Plans user={user} />
            <Container title={getLanguage('usage_description')}>
              <UsageLimits user={user} />
            </Container>
          </div>
        )}
      </div>
    </Layout>
  );
};
