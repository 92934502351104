import React, { useEffect, useState } from 'react';
import { useParams, useNavigate } from 'react-router-dom';
import {
  apiCodeToString,
  getRecurrentPoll,
  updateRecurrentPoll,
  deleteRecurrentPoll,
  hasScope,
  getGuildChannels
} from '../../../utils';
import CustomAlert from '../../Alert';
import PropType from 'prop-types';
import { SectionTitle } from '../../SectionTitle';
import { DeleteModal } from '../../modal';
import { Button } from '../../Button';
import { getLanguage } from '../../../language/getLanguage';
import { RecurrentPollEditSkeleton } from './RecurrentPollEditSkeleton';
import Container from '../../Container';
import Input from '../../Input';
import {
  AutoDropdown,
  Dropdown
} from '../../index';
import { CheckCircleIcon, PlusCircleIcon } from '@heroicons/react/24/outline';
import Timezones from './Timezones.json';

const everyValuesArray = [
  { id: 10, text: `10 ${getLanguage('minutes')}`},
  { id: 30, text: `30 ${getLanguage('minutes')}`},
  { id: 60, text: `1 ${getLanguage('hour')}`},
  { id: 120, text: `2 ${getLanguage('hours')}`},
  { id: 180, text: `3 ${getLanguage('hours')}`},
  { id: 240, text: `4 ${getLanguage('hours')}`},
  { id: 300, text: `5 ${getLanguage('hours')}`},
  { id: 360, text: `6 ${getLanguage('hours')}`},
  { id: 720, text: `12 ${getLanguage('hours')}`},
  { id: 1440, text: `1 ${getLanguage('day')}`},
  { id: 2880, text: `2 ${getLanguage('days')}`},
  { id: 4320, text: `3 ${getLanguage('days')}`},
  { id: 5760, text: `4 ${getLanguage('days')}`},
  { id: 7200, text: `5 ${getLanguage('days')}`},
  { id: 8640, text: `6 ${getLanguage('days')}`},
  { id: 10080, text: `1 ${getLanguage('week')}`},
  { id: 20160, text: `2 ${getLanguage('weeks')}`},
  { id: 30240, text: `3 ${getLanguage('weeks')}`},
  { id: 40320, text: `4 ${getLanguage('weeks')}`}
];

export const RecurrentPollEditComponent = ({ user }) => {
  RecurrentPollEditComponent.propTypes = {
    user: PropType.object
  };
  const [every, setEvery] = useState(null);
  const [timezone, setTimezone] = useState(null);
  const [poll, setPoll] = useState(null);
  const [channels] = useState([]);
  const [channel, setChannel] = useState(null);
  const [everyValues] = useState(everyValuesArray);
  const [updatedPoll, setUpdatedPoll] = useState(null);
  const [saving, setSaving] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [alert, setAlert] = useState({ title: null, message: null, type: null });
  const [isLoading, setIsLoading] = useState(true);
  const [deleting, setDeleting] = useState(false);
  let { id } = useParams();
  const params = new URLSearchParams(window.location.search);
  const navigate = useNavigate();

  if (params.get('id') && !id)
    id = params.get('id');

  useEffect(() => {
    const fetch = async () => {
      const pollData = await getRecurrentPoll(id, user.token);
      setPoll(pollData);

      if (!updatedPoll)
        setUpdatedPoll(pollData);

      const channelsData = await getGuildChannels(pollData.guild.id, user.token);
      if (channelsData && channels.length <= 0) {
        setChannel({ id: pollData.channel.id, text: 'undefined' });
        channelsData.forEach(c => {
          if (c.type !== 0)
            return;
          channels.push({ id: c.id, text: '#' + c.name });
          if (c.id === pollData.channel.id)
            setChannel({ id: c.id, text: '#' + c.name });
        });
      }
      if (!timezone)
        setTimezone(Timezones.find(t => t.value === pollData.options.timezone));
      if (!every) {
        const currentRecurrence = pollData.options.recurence.value;
        const every = everyValues.find(e => e.id === currentRecurrence);
        if (every) {
          setEvery(every);
          return setIsLoading(false);
        }
        everyValues.push({
          id: currentRecurrence,
          text: `${currentRecurrence} minutes`
        });
        setEvery(everyValues.find(e => e.id === currentRecurrence));
        setIsLoading(false);
      }
    };

    if (!poll)
      fetch();
  }, [user]);

  useEffect(() => {
    if (!poll || !every || !channel || !timezone)
      return;
    poll.options.recurence.value = every.id;
    setUpdatedPoll({
      ...updatedPoll,
      options: poll.options
    });
    poll.channel.id = channel.id;
    setUpdatedPoll({
      ...updatedPoll,
      channel: poll.channel
    });
    if (timezone) {
      poll.options.timezone = timezone.value;
      setUpdatedPoll({
        ...updatedPoll,
        options: poll.options
      });
    }
  }, [every, channel, timezone]);

  const handleTitleChange = () => {
    const title = document.getElementById('title').value;
    setUpdatedPoll({
      ...updatedPoll,
      title: title
    });
  };

  const handleDescriptionChange = () => {
    const description = document.getElementById('description').value;
    setUpdatedPoll({
      ...updatedPoll,
      description: description
    });
  };

  const handleImageChange = () => {
    const image = document.getElementById('image').value;
    const input = document.getElementById('image');
    // eslint-disable-next-line max-len, no-useless-escape
    const urlPattern = /^https?:\/\/(?:www\.)?[-a-zA-Z0-9@:%._\+~#=]{1,256}\.[a-zA-Z0-9()]{1,6}\b(?:[-a-zA-Z0-9()@:%_\+.~#?&\/=]*)$/;
    setUpdatedPoll({
      ...updatedPoll,
      image: image
    });
    if(!urlPattern.test(image)) {
      input.classList.add('bg-red-900');
      return input.classList.add('focus:border-red-500');
    } else {
      input.classList.remove('bg-red-900');
      input.classList.remove('focus:border-red-500');
    }
  };

  const handleActiveChange = () => {
    const active = document.getElementById('active').checked;
    setUpdatedPoll({
      ...updatedPoll,
      active: active
    });
  };

  const handleChanges = async () => {
    setSaving(true);
    const response = await updateRecurrentPoll(updatedPoll, user.token);

    if (response.status === 200) {
      showAlert(getLanguage('saving_settings'),
        getLanguage('changes_success'), 'success');
      setPoll(response.data.data);
      setUpdatedPoll(response.data.data);
    } else {
      if (apiCodeToString(response.data.errorCode))
        showAlert(response.statusText,
          `${apiCodeToString(response.data.errorCode)}`, 'alert');
      else
        showAlert(response.statusText, `An error occured while deleting the poll.
      ErrorCode: ${response.data.errorCode}`, 'alert');
    }
    setSaving(false);
  };

  const showAlert = (title, message, type) => {
    setAlert({title, message, type });
    setTimeout(() => {
      setAlert({title: null, message: null, type: null });
    }, 8000);
  };

  const handleDeletePoll = async () => {
    setDeleting(true);
    const response = await deleteRecurrentPoll(poll.id, user.token);
    if (response.status === 200) {
      showAlert(getLanguage('deleting'),
        getLanguage('delete_recurent_poll_sucess'), 'success');
      setDeleteModal(false);
      setTimeout(() => {
        navigate(-1);
      }, 2000);
    } else {
      if (apiCodeToString(response.data.errorCode))
        showAlert(response.statusText,
          `${apiCodeToString(response.data.errorCode)}`, 'alert');
      else
        showAlert(response.statusText, `An error occured while deleting the poll.
      ErrorCode: ${response.data.errorCode}`, 'alert');
      setDeleteModal(false);
    }
    setDeleting(false);
  };

  const handleDays = (dayPos) => {
    const days = updatedPoll.options.days;
    days[dayPos] = !days[dayPos];
    setUpdatedPoll({
      ...updatedPoll,
      options: {
        ...updatedPoll.options,
        days: days
      }
    });
  };

  if (poll?.status === 404) return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
      overflow-auto min-h-screen'>
      <CustomAlert type='warning' title={getLanguage('poll_not_found_title')}
        message={getLanguage('poll_not_found_description')} />
    </div>
  );

  if (isLoading) return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
      overflow-auto min-h-screen'>
      <RecurrentPollEditSkeleton />
    </div>
  );

  return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
      overflow-auto min-h-screen'>
      <div className='w-full flex flex-col'>
        <SectionTitle title={getLanguage('recurrentpoll_information')}
          subtitle={getLanguage('recurrentpoll_information_subtitle')} goBack={true} />
        {deleteModal && (
          <DeleteModal title={getLanguage('recurrentpoll_edit_modal_title')}
            subtitle={`"${poll.title}"`} close={() => setDeleteModal(false)}>
            <div className='flex flex-row gap-3 w-full justify-between
            flex-wrap sm:flex-nowrap'>
              <Button disabled={deleting} className='w-full' type='danger'
                onClick={() => handleDeletePoll()} loading={deleting}>
                {getLanguage('recurrentpoll_edit_delete_btn')}
              </Button>
              <Button className='w-full' disabled={deleting} type='secondary'
                onClick={() => setDeleteModal(false)}>
                {getLanguage('cancel')}
              </Button>
            </div>
          </DeleteModal>
        )}
        <Container title={getLanguage('message')} >
          <div className='mb-6'>
            <Input type='text' id='title' placeholder={getLanguage('poll_edit_title')}
              maxLength='200' value={updatedPoll.title} required={true}
              title={getLanguage('title')} onChange={() => handleTitleChange()} />
          </div>
          <div className='mb-6'>
            <Input textArea={true} id='description' type='text'
              maxLength='4000' placeholder={getLanguage('poll_edit_description')}
              rows='2' value={updatedPoll.description || ''}
              onChange={() => handleDescriptionChange()}
              title={getLanguage('description')}/>
          </div>
          <div>
            <Input type='url' id='image' value={updatedPoll.image || ''}
              placeholder='https://example.com/image.png'
              onChange={() => handleImageChange()} title={getLanguage('image_url')} />
          </div>
        </Container>
        <Container title={getLanguage('options')}>
          <div className='mb-6 relative'>
            <Dropdown id='message'
              values={channels} disabled={true}
              value={channel} required={true}
              onChange={setChannel} title={getLanguage('post_in_channel')} />
          </div>
          <div className='mb-6 relative'>
            <Dropdown id='every'
              values={everyValues}
              value={every} required={true}
              onChange={setEvery} title={getLanguage('post_every')} />
          </div>
          <div className='mb-6 relative'>
            <AutoDropdown id='timezone'
              values={Timezones}
              value={timezone}
              required={false}
              onChange={setTimezone}
              title={getLanguage('timezone')} />
          </div>
          <div className='mb-6'>
            <label htmlFor='active' className='block mb-2 text-sm
            font-medium text-white text-opacity-80'>
              {getLanguage('active')}
            </label>
            <label className='relative inline-flex items-center
              cursor-pointer z-0'>
              <input type='checkbox' className='sr-only peer
              disabled:opacity-50' id='active'
              onChange={() => handleActiveChange()}
              checked={updatedPoll.active} />
              <div className="w-11 h-6 rounded-full peer
              peer-focus:ring-4 peer-focus:ring-main-800 bg-secondary-450
              peer-checked:after:translate-x-full
              peer-checked:after:border-white after:content-['']
              after:absolute after:top-0.5 after:left-[2px] after:bg-white
              after:border-gray-300 after:border after:rounded-full after:h-5
              after:w-5 after:transition-all border-gray-600
              peer-checked:bg-main-600"/>
            </label>
          </div>
          <div>
            <label htmlFor='days' className='block mb-2 text-sm
            font-medium text-white text-opacity-80'>
              {getLanguage('recurrentpoll_days')}
            </label>
            <div className='flex flex-row gap-2 flex-wrap md:flex-nowrap'>
              {poll.options.days.map((day, index) => (
                <Button key={index} type={day ? 'selected' : 'secondary'}
                  onClick={() => handleDays(index)}>
                  {day ? <CheckCircleIcon className='w-5 h-5 mr-1' /> :
                    <PlusCircleIcon className='w-5 h-5 mr-1' />}
                  {getLanguage(`recurrentpoll_days_${index}`).slice(0, 3)}.
                </Button>
              ))}
            </div>
          </div>
          {poll.ownerId !== user.id && !hasScope(user, 'UPDATE_POLLS') && (
            <CustomAlert type='warning'
              title={getLanguage('poll_edit_owner_title')}
              message={getLanguage('poll_edit_owner_description')} />
          )}
        </Container>
        <div className='flex flex-row gap-3 flex-wrap md:flex-nowrap'>
          <Button type='primary' className='w-full' onClick={() => handleChanges()}
            disabled={(poll.ownerId !== user.id &&
            !hasScope(user, 'UPDATE_POLLS')) || saving}
            loading={saving}>
            {getLanguage('save_changes')}
          </Button>
          <Button type='danger' className='w-full' onClick={() => setDeleteModal(true)}
            disabled={poll.ownerId !== user.id && !hasScope(user, 'DELETE_POLLS')}>
            {getLanguage('delete_recurrentpoll')}
          </Button>
        </div>
        {alert.message && (
          <CustomAlert message={alert.message} type={alert.type}
            title={alert.title}
            className='fixed bottom-10 right-4 left-4 z-50' />
        )}
      </div>
    </div>
  );
};
