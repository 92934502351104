import React, { useEffect, useState } from 'react';
import { Layout } from '../Layout';
import { AuthUser, getUsers } from '../../utils';
import {
  MagnifyingGlassIcon,
  ChevronRightIcon
} from '@heroicons/react/24/solid';
import { getLanguage } from '../../language/getLanguage';
import { useNavigate } from 'react-router-dom';

export const SearchUsers = () => {
  const user = AuthUser();
  const [search, setSearch] = useState('');
  const [users, setUsers] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  useEffect(() => {
    if (search.length <= 0) {
      setUsers([]);
      return;
    }
    setIsLoading(true);
    getUsers(user.token, search)
      .then((res) => {
        setUsers(res);
        setIsLoading(false);
      })
      .catch((err) => {
        console.error(err);
        setIsLoading(false);
      });
  }, [search]);

  function handleSearch(e) {
    e.preventDefault();
    setSearch(e.target.value);
  }

  return (
    <Layout>
      <div className='mx-auto w-full text-center p-5 lg:p-10 flex flex-col
      items-center min-h-screen'>
        <div className="inset-0 absolute -top-1/2 w-full h-full
        rounded-full bg-gradient-radial from-[#BDFFFB] via-[#7478FF]
        to-[#7478FF] filter blur-3xl opacity-10"></div>
        <div>
          <a href='user' className='inline-flex justify-between
            items-center py-1 px-1 pr-4 mb-7 text-sm rounded-full
            ring-1 ring-main-500 text-white transition-all duration-200
          hover:bg-main-500 relative group'>
            <span className='text-xs bg-main-500 rounded-full text-white px-4
            py-1.5 font-regular group-hover:bg-main-600 hidden md:block'>
              {getLanguage('new')}
            </span>
            <span className='text-sm font-medium font-regular ml-3'>
              {getLanguage('search_user_popup')}
            </span>
            <ChevronRightIcon className='w-4 h-4 ml-2' aria-hidden='true' />
          </a>
          <h1 className='mb-4 text-4xl font-extrabold tracking-tight leading-none
          md:text-5xl lg:text-6xl text-white font-bold relative'>
            {getLanguage('search_user_title')}
          </h1>
          <p className='mb-8 text-lg font-normal lg:text-xl sm:px-16 lg:px-48
          text-gray-100 font-medium relative'>
            {getLanguage('search_user_subtitle')}
            Search a user by their username or id.
          </p>
          <form className='w-full max-w-md mx-auto'>
            <div className='relative text-white'>
              <div className='absolute inset-y-0 left-0 flex items-center pl-3.5
                pointer-events-none'>
                <MagnifyingGlassIcon className='w-5 h-5' aria-hidden='true' />
              </div>
              <input type='search' className='block w-full p-4
              pl-10 text-sm border rounded-lg
              bg-tertiary border-gray-700 placeholder-gray-300 text-white
              focus:border-main-500 outline-none'
              onChange={(e) => handleSearch(e)}
              placeholder={getLanguage('search_user_placeholder')} required>
              </input>
            </div>
          </form>
        </div>
        <div className='w-full mt-10 z-10'>
          {isLoading && (
            <div className='bg-secondary-500 flex rounded-lg h-fit border
            border-secondary-480 transition-all duration-200 border-dotted
            mb-3'>
              <div className='flex h-full w-full p-4 pr-0 flex-row gap-5
              items-center'>
                <div className='animate-pulse flex rounded-full w-12 h-12
                bg-secondary-450 items-center flex-shrink-0'>
                </div>
                <div className='flex flex-col items-start w-full'>
                  <div className='animate-pulse w-11/12 md:w-2/12 h-4
                  bg-secondary-450 mb-2 rounded-full'/>
                  <div className='animate-pulse w-5/12 md:w-1/12 h-4
                  bg-secondary-450 rounded-full'/>
                </div>
              </div>
            </div>
          )}
          <ul className='flex flex-col gap-3'>
            {users.length > 0 && users.slice(0, 15).map((user) => (
              <li key={user.id} className='flex bg-secondary-500
              rounded-lg h-fit border border-secondary-480
              hover:border-main-500 transition-all duration-200
              hover:scale-101 hover:cursor-pointer'>
                <div className='flex h-full w-full p-4 pr-0 flex-row gap-5
                  items-center'
                onClick={() => navigate(`/users/${user.id}`)}>
                  {user.avatarURL ? (
                    <img src={user.avatarURL} alt='avatar'
                      className='w-12 h-12 rounded-full' />
                  ) : (
                    <div className='rounded-full w-12 h-12
                    bg-secondary-480 flex items-center flex-shrink-0'>
                      <p className='text-white text-xl font-bold
                      text-center m-auto'>
                        {user.username[0]}
                      </p>
                    </div>
                  )}
                  <div className='flex flex-col items-start'>
                    <h1 className='text-lg font-medium text-white'>
                      {user.username}
                    </h1>
                    <p className='text-sm font-normal text-gray-300'>
                      {user.id}
                    </p>
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      </div>
    </Layout>
  );
};
