import React from 'react';
import { Button } from '../components';

export const Forbidden = () => {
  return (
    <div className='mx-auto w-full text-center py-20 flex flex-col
    items-center px-4'>
      <h1 className='text-main-500 font-extraBold text-7xl md:text-8xl lg:text-9xl
        mb-6'>
        403
      </h1>
      <h1 className='mb-4 text-4xl font-extrabold tracking-tight leading-none
      md:text-5xl lg:text-6xl text-white font-bold'>
        Forbidden
      </h1>
      <p className='mb-8 text-lg font-normal lg:text-xl sm:px-16 lg:px-48
      text-gray-100 font-medium'>
        The page you are trying to access is forbidden. Please contact the
        support if you think this is a mistake.
      </p>
      <Button
        name='Go back home'
        href='/'
        type='primary'/>
    </div>
  );
};
