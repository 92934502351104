import React from 'react';
import PropType from 'prop-types';
import { PollsTableSkeleton } from '../polls';
import CustomAlert from '../Alert';
import {
  UserGroupIcon,
  MoonIcon
} from '@heroicons/react/24/solid';
import { getLanguage } from '../../language/getLanguage';

export const GuildsTable = ({
  guilds,
  title,
  description,
  emptyMessage,
  loading
}) => {
  GuildsTable.propTypes = {
    guilds: PropType.array,
    title: PropType.string,
    description: PropType.string,
    emptyMessage: PropType.string,
    loading: PropType.bool
  };

  return (
    <div className='flex flex-col flex-1 gap-6 mt-5'>
      <div className='flex flex-col'>
        <h1 className='text-white text-3xl font-bold m-0'>{title}</h1>
        <p className='text-gray-200 text-base'>{description}</p>
      </div>
      {loading ? (
        <PollsTableSkeleton height={2} />
      ) : (
        <div className='justify-center flex flex-col'>
          {guilds && guilds.length < 1 ? (
            <CustomAlert type='info' title={getLanguage('nothing_to_display')}
              message={emptyMessage} />
          ) : (
            guilds && (
              <div>
                <ul className='flex flex-col gap-3'>
                  {guilds.map((guild) => (
                    <li key={guild.id} className='flex bg-secondary-500
                    rounded-lg h-fit border border-secondary-480
                    hover:border-main-500 transition-all duration-200
                    hover:scale-101'>
                      <div className='flex h-full w-full p-4 flex-row gap-2'>
                        {guild.icon ? (
                          <img className='flex-shrink-0 rounded-full
                          w-14 h-14'
                          // eslint-disable-next-line max-len
                          src={`https://cdn.discordapp.com/icons/${guild.id}/${guild.icon}.png`}
                          alt='guild icon' />
                        ) : (
                          <div className='rounded-full w-14 h-14
                          bg-secondary-480 flex items-center flex-shrink-0'>
                            <p className='text-white text-2xl font-bold
                            text-center m-auto'>
                              {guild.name.charAt(0)}
                            </p>
                          </div>
                        )}
                        <div className='flex flex-row-reverse sm:flex-row gap-2
                        justify-between w-full'>
                          <div className='flex flex-col flex-auto gap-2'>
                            <p className='text-white text-base font-medium
                            break-words'>
                              {guild.name}
                            </p>
                            <p className='text-gray-200 text-sm font-medium'>
                              {getLanguage('last_update_on')} {
                                new Date(guild.last_update * 1000)
                                  .toLocaleString()}
                            </p>
                          </div>
                          <div className='flex-col sm:flex-row flex-shrink-0 flex
                          justify-around text-gray-200 text-sm font-medium'>
                            <div className='flex-row flex-shrink-0 text-center
                            justify-center w-fit lg:w-32 items-center flex px-2'>
                              <UserGroupIcon className='w-5 h-5 mr-1' />
                              {guild.approximate_member_count}
                            </div>
                            <div className='flex-row flex-shrink-0 text-center
                            justify-center w-fit lg:w-32 items-center flex px-2'>
                              {guild.approximate_presence_count && (
                                <>
                                  <MoonIcon className='w-5 h-5 mr-1' />
                                  {guild.approximate_presence_count}
                                </>
                              )}
                            </div>
                          </div>
                        </div>
                      </div>
                    </li>
                  ))}
                </ul>
              </div>
            )
          )}
        </div>
      )}
      {!guilds && (
        <CustomAlert type='alert' button='logout'
          title={getLanguage('failed_to_load_guilds_title')}
          message={getLanguage('failed_to_load_guilds_description')} />
      )}
    </div>
  );
};
