import React from 'react';
import { getLanguage } from '../../language/getLanguage';
import { Button } from '../Button';
import image from '../../assets/Banner Recurrent Poll Help Asset.svg';
import PropTypes from 'prop-types';

export const BannerRecurrentPollHelp = () => {
  BannerRecurrentPollHelp.propTypes = {
    user: PropTypes.object
  };

  const handleClick = () => {
    window.open('https://help.coodo.xyz/polls-1', '_blank');
  };

  return (
    <div>
      <div className='w-full rounded-lg flex justify-between relative
      overflow-hidden bg-gray-700 mb-5'>
        <div className='h-full absolute right-0 top-0 w-full
          bg-gradient-to-r from-main-500 to-main-400 opacity-100'/>
        <div className='flex-col absolute h-full hidden md:flex right-0'>
          <img src={image} alt='Savings' className='h-full w-full'/>
        </div>
        <div className='flex flex-col relative gap-2 max-w-[500px] p-4'>
          <h1 className='text-xl font-semiBold text-white text-left'>
            {getLanguage('recurrentpollhelp_banner_title')}
          </h1>
          <Button onClick={() => handleClick()} type='white' className='w-fit'>
            {getLanguage('recurrentpollhelp_banner_button')} &rarr;
          </Button>
        </div>
      </div>
    </div>
  );
};

export default BannerRecurrentPollHelp;
