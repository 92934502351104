import React from 'react';

export const Beta = () => {
  return (
    <div className='mx-auto w-full text-center py-20 flex flex-col items-center
    px-4'>
      <h1 className='mb-4 text-4xl font-extrabold tracking-tight leading-none
      md:text-5xl lg:text-6xl text-black font-bold'>
        Beta
      </h1>
      <p className='mb-8 text-lg font-normal lg:text-xl sm:px-16 lg:px-48
      text-gray-500 font-medium'>
        This dashboard is currently in beta. You need to be invited to use it.
      </p>
    </div>
  );
};
