import axios from 'axios';

export async function getGuild(id, token) {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/guilds/${id}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };
  let response = await axios.request(config).then((response) => {
    return response.data.data;
  }).catch((error) => {
    return error.response;
  });
  return response;
}
