import React, { useEffect, useRef } from 'react';
import PropType from 'prop-types';
import config from '../../config.json';
import { Chart } from 'chart.js/auto';

export const PollCanva = ({ poll }) => {
  PollCanva.propTypes = {
    poll: PropType.object.isRequired
  };

  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');
    let labels = [];
    let data = [];
    for (let i = 0; i < poll.buttons.length; i++) {
      labels.push(poll.buttons[i].title);
      data.push(poll.buttons[i].ids.length);
    }

    const datas = {
      labels: labels,
      datasets: [
        {
          data: data,
          backgroundColor: config.colors.map(color => color + '4D'),
          borderColor: config.colors
        }
      ]
    };

    const chartConfig = {
      type: 'pie',
      data: datas,
      options: {
        plugins: {
          legend: {
            display: false
          }
        }
      }
    };

    const chartInstance = new Chart(ctx, chartConfig);
    return () => {
      chartInstance.destroy();
    };
  }, [poll]);
  return (
    <div>
      <canvas ref={chartRef} className='h-72' />
    </div>
  );
};
