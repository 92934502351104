import React, { useEffect, useState } from 'react';
import PropType from 'prop-types';
import {
  ChartBarIcon
} from '@heroicons/react/24/solid';
import { getUserPollsCount } from '../../utils';
import { getLanguage } from '../../language/getLanguage';

export const UsageLimits = ({ user, data }) => {
  UsageLimits.propTypes = {
    user: PropType.object.isRequired,
    data: PropType.object
  };

  const [limits, setLimits] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const limits = await getUserPollsCount(user.id, user.token);
      setLimits(limits);
    };

    if (data)
      setLimits(data);
    else
      fetch();
  }, [user, data]);

  return (
    <div className='rounded-md border-2 border-gray-500'>
      <div className='bg-gray-500 flex flex-row items-center px-5 py-3'>
        <ChartBarIcon className='h-8 w-8 mr-2 text-white' />
        <div>
          <h1 className='font-medium text-white'>
            {getLanguage('actives_polls')}
          </h1>
          <p className='font-regular text-gray-100'>
            {getLanguage('actives_polls_description')}
          </p>
        </div>
      </div>
      <div className='flex flex-col px-5 py-3 gap-1'>
        <div className='flex flex-row justify-between'>
          <h1 className='text-white font-medium'>
            {getLanguage('current_actives_polls')}
          </h1>
          {limits?.polls ? (
            <h1 className='text-white font-medium'>
              {limits.polls.count}/{limits.polls.max}
            </h1>
          ) : (
            <div className='w-20 h-3 rounded-full bg-gray-400 animate-pulse
            my-auto'/>
          )}
        </div>
        <div className='w-full bg-tertiary h-4 rounded'>
          {limits?.polls ? (
            <div className='h-4 rounded bg-progress-bar bg-main-500'
              style={{width: `${(limits.polls.count * 100 / limits.polls.max) > 100 ?
                100 : (limits.polls.count * 100 / limits.polls.max)}%`}}/>
          ) : (
            <div className='h-4 bg-gray-300 animate-pulse rounded'
              style={{width: '100%'}}/>
          )}
        </div>
      </div>
      <div className='flex flex-col px-5 py-3 gap-1'>
        <div className='flex flex-row justify-between'>
          <h1 className='text-white font-medium'>
            {getLanguage('current_actives_polls_recursives')}
          </h1>
          {limits?.recurrentPolls ? (
            <h1 className='text-white font-medium'>
              {limits.recurrentPolls.count}/{limits.recurrentPolls.max}
            </h1>
          ) : (
            <div className='w-20 h-3 rounded-full bg-gray-400 animate-pulse
            my-auto'/>
          )}
        </div>
        <div className='w-full bg-tertiary h-4'>
          {limits?.recurrentPolls ? (
            <div className='h-4 rounded bg-progress-bar bg-main-500'
              style={{width: `${(limits.recurrentPolls.count * 100 /
              limits.recurrentPolls.max) > 100 ? 100 :
                (limits.recurrentPolls.count * 100 / limits.recurrentPolls.max)}%`}}/>
          ) : (
            <div className='h-4 bg-gray-300 animate-pulse rounded'
              style={{width: '100%'}}/>
          )}
        </div>
      </div>
    </div>
  );
};
