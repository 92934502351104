import axios from 'axios';

export async function getUsers(token, search) {
  let config = {
    method: 'get',
    maxBodyLength: Infinity,
    url: 'https://api.coodo.xyz/users',
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    },
    params: {
      username: search,
      id: search
    }
  };

  let response = await axios.request(config).then((response) => {
    return response.data.data;
  }).catch((error) => {
    console.info(error);
    // eslint-disable-next-line max-len
    return window.location.href = `/error?code=${error.response.status}&message=${error.response.data.errorCode}`;
  });
  return response;
}
