import React from 'react';
import packageJson from '../../../package.json';
import {
  CommandLineIcon
} from '@heroicons/react/24/solid';

export const VersionBadge = () => {
  const { version } = packageJson;

  return (
    <span className='text-xs font-medium px-2 py-1
    rounded-full bg-opacity-40 flex items-center w-fit gap-1 bg-gray-300
      text-gray-300'>
      <div className='shrink-0 h-4 w-4'>
        <CommandLineIcon />
      </div>
      v{version}
    </span>
  );
};

export default VersionBadge;
