import React from 'react';
import PropTypes from 'prop-types';

export const PollsTableSkeleton = ({ height }) => {
  PollsTableSkeleton.propTypes = {
    height: PropTypes.number
  };

  return (
    <div className='flex flex-col gap-3'>
      {Array.from({ length: height }, (_, i) => (
        <div key={i} className='flex bg-secondary-500
        rounded-lg p-4 h-fit border border-secondary-480
        hover:border-main-500 transition-all duration-200
        hover:cursor-wait'>
          <div className='animate-pulse flex items-center
          w-full justify-between'>
            <div>
              <div className='h-2.5 rounded-full bg-gray-300 w-60 md:w-80 mb-9'/>
              <div className='w-36 h-2 rounded-full bg-gray-400'/>
            </div>
            <div className='flex-row justify-between hidden sm:flex gap-10 mr-10'>
              <div className='h-2.5 rounded-full bg-gray-400 w-12'/>
              <div className='h-2.5 rounded-full bg-gray-400 w-12'/>
              <div className='h-2.5 rounded-full bg-gray-400 w-12'/>
              <div className='h-2.5 rounded-full bg-gray-400 w-12'/>
            </div>
          </div>
        </div>
      ))}
      <span className='sr-only'>Loading...</span>
    </div>
  );
};
