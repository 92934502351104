/* eslint-disable max-lines-per-function */
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import ReactCountryFlag from 'react-country-flag';
import moment from '../../utils/moment';
import {
  PlusIcon,
  EyeIcon,
  EyeSlashIcon,
  ArrowPathIcon
} from '@heroicons/react/24/solid';

import {
  deleteUser,
  deleteUserPolls,
  updateUserRole,
  updateUserState
} from '../../utils';
import { Button, Container, CustomAlert } from '../../components';
import { getLanguage } from '../../language/getLanguage';
import { UsageLimits } from '../../components/usage';
import { DeleteModal } from '../../components/modal';

export const UserProfile = ({ profileUser, roles, limits, user }) => {
  UserProfile.propTypes = {
    profileUser: PropTypes.object.isRequired,
    roles: PropTypes.array.isRequired,
    limits: PropTypes.object.isRequired,
    user: PropTypes.object.isRequired
  };

  const dropdownRef = useRef(null);
  const [rolesSelected, setRolesSelected] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [deleteAccount, setDeleteAccount] = useState(false);
  const [deletePolls, setDeletePolls] = useState(false);
  const [deleting, setDeleting] = useState(false);
  const [isDisabled, setIsDisabled] = useState(!profileUser.disabled);
  const [alert, setAlert] = useState({
    message: '',
    type: ''
  });

  useEffect(() => {
    const handleOutsideClick = (event) => {
      if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };

    if (isOpen) {
      document.addEventListener('click', handleOutsideClick);
    }

    return () => {
      document.removeEventListener('click', handleOutsideClick);
    };
  }, [isOpen]);

  useEffect(() => {
    setRolesSelected(initializeRoles());
  }, []);

  const initializeRoles = () => {
    return roles.map((e) => {
      return profileUser.roles.includes(e.id);
    });
  };

  const showAlert = (message, type) => {
    setAlert({ message, type });
    setTimeout(() => {
      setAlert({ message: '', type: '' });
    }, 8000);
  };

  const updateRoles = async (roleId, index) => {
    const newRoles = [...rolesSelected];
    newRoles[index] = !newRoles[index];
    setRolesSelected(newRoles);

    const isChecked = !rolesSelected[index];

    const result = await updateUserRole(roleId, isChecked,
      user.token, profileUser);

    if (result.status === 200) {
      showAlert('Roles updated successfully', 'success');
    } else {
      showAlert('Error updating roles. Message: ' + result.data.error, 'alert');
      newRoles[index] = !newRoles[index];
      setRolesSelected(newRoles);
    }
  };

  const handleDisabled = async () => {
    const newIsDisabled = !isDisabled;
    setIsDisabled(newIsDisabled);

    const result = await updateUserState(isDisabled, user.token, profileUser);

    if (result.status === 200) {
      showAlert('User state updated successfully', 'success');
    } else {
      showAlert('Error updating user state. Message: ' + result.data.error,
        'alert');
      setIsDisabled(!isDisabled);
      console.info(result);
    }
  };

  const handleAccount = async () => {
    setDeleting(true);
    const result = await deleteUser(profileUser.id, user.token);

    if (result.status === 200) {
      showAlert('Account deleted successfully', 'success');
      setDeleting(false);
      setDeleteAccount(false);
    } else {
      showAlert('Error deleting account. Message: ' + result.data.error,
        'alert');
      setDeleting(false);
    }
  };

  const handlePolls = async () => {
    setDeleting(true);
    const result = await deleteUserPolls(profileUser.id, user.token);

    if (result.status === 200) {
      showAlert('Polls deleted successfully', 'success');
      setDeleting(false);
      setDeletePolls(false);
    } else {
      showAlert('Error deleting polls. Message: ' + result.data.error,
        'alert');
      setDeleting(false);
    }
  };

  if (profileUser.roles.length > 0) {
    const role = profileUser.roles.reduce((prev, current) => {
      return (current < prev) ? current : prev;
    });
    const roleObj = roles.find(e => e.id === role);
    if (roleObj?.color) {
      const img = document.getElementById('user_icon');
      if (img)
        img.style.border = '4px solid ' + roleObj.color;
    }
  }

  return (
    <Container>
      {deleteAccount && (
        <DeleteModal title={getLanguage('delete_account_modal_title')}
          subtitle={getLanguage('delete_account_modal_description')}
          close={() => setDeleteAccount(false)}>
          <div className='flex flex-row gap-3 w-full justify-between flex-wrap
          sm:flex-nowrap'>
            <Button type='danger' onClick={() => handleAccount()}
              disabled={deleting} className='w-full'>
              {deleting ? (
                <ArrowPathIcon className='w-6 h-6 animate-spin' />
              ) : (
                getLanguage('delete_account')
              )}
            </Button>
            <Button type='secondary' onClick={() => setDeleteAccount(false)}
              className='w-full' disabled={deleting}>
              {getLanguage('cancel')}
            </Button>
          </div>
        </DeleteModal>
      )}
      {deletePolls && (
        <DeleteModal title={getLanguage('delete_polls_modal_title')}
          subtitle={getLanguage('delete_polls_modal_description')}
          close={() => setDeletePolls(false)}>
          <div className='flex flex-row gap-3 w-full justify-between flex-wrap
          sm:flex-nowrap'>
            <Button type='danger' onClick={() => handlePolls()}
              disabled={deleting} className='w-full'>
              {deleting ? (
                <ArrowPathIcon className='w-6 h-6 animate-spin' />
              ) : (
                getLanguage('delete_polls')
              )}
            </Button>
            <Button type='secondary' onClick={() => setDeletePolls(false)}
              className='w-full' disabled={deleting}>
              {getLanguage('cancel')}
            </Button>
          </div>
        </DeleteModal>
      )}
      <div className='flex flex-col lg:flex-row gap-4'>
        {profileUser.avatarURL ? (
          <img id='user_icon' alt='User avatar'
            className='w-24 h-24 rounded-full p-1' src={profileUser.avatarURL}/>
        ) : (
          <div className='rounded-full w-24 h-24
          bg-secondary-480 flex items-center flex-shrink-0'>
            <p className='text-white text-2xl font-bold
            text-center m-auto'>
              {profileUser.username[0]}
            </p>
          </div>
        )}
        <div className='flex flex-col items-start justify-start'>
          <h1 className='text-3xl font-bold text-left text-white'>
            {profileUser.username}
          </h1>
          <p className='text-lg font-medium text-left text-gray-100
          select-all'>
            {profileUser.id}
          </p>
          <p className='text-base font-regular text-gray-200 text-left'>
            {
              getLanguage('last_seen')
            } {
              moment.unix(profileUser.lastUpdate).fromNow()
            }
          </p>
        </div>
      </div>
      <hr className='h-px my-8 bg-gray-400 border-0'/>
      <div className='flex flex-col gap-5'>
        <div className='flex flex-col gap-4 w-full'>
          <h1 className='text-2xl font-bold text-white'>
            {getLanguage('user_informations_title')}
          </h1>
          <ul className='flex flex-col gap-4'>
            {profileUser.locale && (
              <li className='text-lg text-white flex flex-row items-center
              justify-between gap-1'>
                <h2 className='font-regular'>
                  {getLanguage('user_informations_locale')}
                </h2>
                <ReactCountryFlag countryCode={profileUser.locale.split('-')[1]
                || profileUser.locale} svg style={{
                  width: '2em',
                  height: '2em',
                  borderRadius: '100%',
                  objectFit: 'cover'
                }} title={profileUser.locale}/>
              </li>
            )}
            <li className='text-lg text-white flex flex-row items-center
            justify-between gap-1'>
              <h2 className='font-regular'>
                {getLanguage('user_informations_roles')}
              </h2>
              <p className='text-gray-100 flex flex-row gap-2
              flex-wrap justify-end'>
                {roles
                  .filter((e, index) => rolesSelected[index] === true)
                  .map((e, index) => (
                    <span key={index} className='px-2 rounded-md text-base
                    self-center' style={{ backgroundColor: e.color + 35,
                      color: e.color }}>
                      {e.name}
                    </span>
                  ))}
                {user.roles
                  .some(role => role.scopes.includes('UPDATE_USERS_ROLES'))&& (
                  <div ref={dropdownRef}>
                    <button className='p-1 rounded-md text-base
                  text-white font-bold border border-gray-400
                  hover:bg-gray-700 focus:outline-none focus:shadow-outline'
                    onClick={() => setIsOpen(!isOpen)}>
                      <PlusIcon className='h-5 w-5' />
                    </button>
                    {isOpen && (
                      <div className='absolute z-10 right-0 mr-12 shadow py-2
                      bg-tertiary rounded-md px-3 border border-secondary-480'>
                        <div className='flex flex-col gap-2'>
                          {roles.map((e, index) => (
                            <div key={index} className='flex flex-row
                            items-center justify-between gap-3'>
                              <span className='px-2 rounded-md text-base
                              self-center' style={{ backgroundColor:
                              e.color + 35, color: e.color }}>
                                {e.name}
                              </span>
                              <input type='checkbox' className='form-checkbox
                            w-4 h-4 text-main-600 rounded focus:ring-main-500
                          dark:focus:ring-main-600 ring-offset-gray-800
                            focus:ring-2 bg-gray-700 border-gray-600'
                              checked={rolesSelected[index]}
                              onChange={() => updateRoles(e.id, index)}/>
                            </div>
                          ))}
                        </div>
                      </div>
                    )}
                  </div>
                )}
              </p>
            </li>
            {user.roles
              .some(role => role.scopes.includes('UPDATE_USERS_STATE')) && (
              <li className='text-lg text-white flex flex-row items-center
              justify-between gap-1'>
                <h2 className='font-regular'>
                  {getLanguage('user_informations_state')}
                </h2>
                <label className='relative inline-flex items-center
                  cursor-pointer'>
                  <input type='checkbox' className='sr-only peer'
                    onChange={() => handleDisabled()} checked={isDisabled} />
                  <div className="w-11 h-6 rounded-full peer
                  peer-focus:ring-4 peer-focus:ring-main-800 bg-secondary-450
                  peer-checked:after:translate-x-full
                  peer-checked:after:border-white after:content-['']
                  after:absolute after:top-1 after:left-[2px] after:bg-white
                  after:border-gray-300 after:border after:rounded-full after:h-5
                  after:w-5 after:transition-all border-gray-600
                  peer-checked:bg-main-600"></div>
                  <span className='ml-3 text-gray-100 '>
                    {isDisabled ? getLanguage('enabled') : getLanguage('disabled')}
                  </span>
                </label>
              </li>
            )}
            <li className='text-lg text-white flex flex-row items-center
            justify-between gap-1'>
              <h2 className='font-regular'>
                {getLanguage('user_informations_profile')}
              </h2>
              {profileUser.privateAccount === true ? (
                <p className='text-gray-100 flex flex-row items-center'>
                  <EyeSlashIcon className='h-6 w-6 mr-1'/> {getLanguage('private')}
                </p>
              ) : (
                <p className='text-gray-100 flex flex-row items-center'>
                  <EyeIcon className='h-6 w-6 mr-1'/> {getLanguage('public')}
                </p>
              )}
            </li>
            {profileUser.email && (
              <li className='text-lg text-white flex flex-row items-center
              justify-between'>
                <h2 className='font-regular'>
                  {getLanguage('user_informations_email')}
                </h2>
                <p className='text-gray-100 cursor-pointer blur-sm' id='email'
                  onClick={() => {
                    const email = document.getElementById('email');
                    if (!email)
                      return;
                    if (email.classList.contains('blur-sm'))
                      email.classList.remove('blur-sm');
                    else
                      email.classList.add('blur-sm');
                  }}>
                  {profileUser.email}
                </p>
              </li>
            )}
            {profileUser.stripeCustomerId && (
              <li className='text-lg text-white flex flex-row items-center
              justify-between'>
                <h2 className='font-regular'>
                  {getLanguage('user_informations_stripe_id')}
                </h2>
                <p className='text-gray-100 cursor-pointer blur-sm' id='stripe_id'
                  onClick={() => {
                    const stripeId = document.getElementById('stripe_id');
                    if (!stripeId)
                      return;
                    if (stripeId.classList.contains('blur-sm'))
                      stripeId.classList.remove('blur-sm');
                    else
                      stripeId.classList.add('blur-sm');
                  }}>
                  {profileUser.stripeCustomerId}
                </p>
              </li>
            )}
          </ul>
          {(user.roles.some(role => role.scopes.includes('DELETE_USERS')) ||
          user.roles.some(role => role.scopes.includes('DELETE_POLLS'))) && (
            <>
              <h1 className='text-2xl font-bold text-white'>
                {getLanguage('user_data')}
              </h1>
              <ul className='flex flex-col gap-4'>
                {user.roles.some(role => role.scopes.includes('DELETE_USERS')) && (
                  <li className='text-lg text-white flex flex-row items-center
                  justify-between gap-1'>
                    <h2 className='font-regular'>
                      {getLanguage('user_data_account')}
                    </h2>
                    <Button type='danger' onClick={() => setDeleteAccount(true)}>
                      {getLanguage('delete_account')}
                    </Button>
                  </li>
                )}
                {user.roles.some(role => role.scopes.includes('DELETE_POLLS')) && (
                  <li className='text-lg text-white flex flex-row items-center
                  justify-between gap-1'>
                    <h2 className='font-regular'>
                      {getLanguage('user_data_polls')}
                    </h2>
                    <Button type='danger' onClick={() => setDeleteAccount(true)}>
                      {getLanguage('delete_polls')}
                    </Button>
                  </li>
                )}
              </ul>
            </>
          )}
        </div>
        {alert.message && (
          <CustomAlert message={alert.message} type={alert.type}
            title='Saving settings...'
            className='fixed bottom-10 right-4 left-4 z-50' />
        )}
        <UsageLimits data={limits}/>
      </div>
    </Container>
  );
};
