import React from 'react';
import PropType from 'prop-types';
import {
  ArrowPathIcon,
  ShareIcon
} from '@heroicons/react/24/solid';

export const Button = ({
  name, type, href, className, children, onClick, disabled, loading, target
}) => {
  Button.propTypes = {
    name: PropType.string,
    type: PropType.string.isRequired,
    href: PropType.string,
    className: PropType.string,
    children: PropType.node,
    onClick: PropType.func,
    disabled: PropType.bool,
    loading: PropType.bool,
    target: PropType.string
  };

  const handleClick = (href) => {
    if (type === 'share') {
      let url = document.location.href;
      let Text = document.getElementById('Text');

      Text.innerHTML = 'Copied!';
      navigator.clipboard.writeText(url);
      setTimeout(() => {
        Text.innerHTML = name;
      }, 3500);
      return;
    }
    if (href) {
      if (target === '_blank')
        window.open(href, '_blank');
      else
        window.location.href = href;
    } else if (onClick)
      onClick();
  };

  let style = '';

  switch (type) {
  case 'primary':
    style += 'bg-main-500 hover:bg-main-450 active:bg-main-600 text-white \
    disabled:hover:bg-main-500';
    break;
  case 'selected':
    style += 'bg-green-500 hover:bg-green-450 active:bg-green-600 text-white \
    disabled:hover:bg-green-500';
    break;
  case 'secondary':
    style += 'bg-gray-440 hover:bg-gray-400 active:bg-gray-480 text-white \
    disabled:hover:bg-gray-440';
    break;
  case 'danger':
    style += 'bg-red-500 hover:bg-red-450 active:bg-red-600 text-white \
    disabled:hover:bg-red-500';
    break;
  case 'share':
    style += 'bg-gray-440 hover:bg-gray-400 active:bg-gray-480 text-white \
    disabled:hover:bg-gray-440';
    break;
  case 'premium':
    style += 'bg-premium-light active:bg-premium-light \
    text-black disabled:hover:bg-premium-light from-premium-500 \
    to-premium-light bg-gradient-to-r \
    focus:to-premium-500 hover:to-premium-500';
    break;
  case 'white':
    style += 'bg-white hover:bg-gray-100 active:bg-gray-200 text-black \
    disabled:hover:bg-white';
    break;
  default:
    style += '';
    break;
  }
  return (
    <button className={`items-center
    font-medium py-2 px-4 h-fit rounded-lg flex flex-row justify-center
    disabled:opacity-60 disabled:cursor-not-allowed min-h-10 ${style}
    transition duration-200 ease-in-out
    ${className}`} disabled={disabled || false}
    onClick={() => handleClick(href)}>
      {loading ? (
        <ArrowPathIcon className='w-6 h-6 animate-spin' />
      ) : (
        <>
          {type === 'share' && (
            <ShareIcon className='w-6 h-6 mr-2' />
          )}
          {name && (
            <div id='Text'>{name}</div>
          )}
          {children}
        </>
      )}
    </button>
  );
};
