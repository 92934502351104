import React, { useEffect, useState } from 'react';

import { Layout } from '../Layout';
import { AuthUser, getUserByToken } from '../../utils';
import { PollEditComponent } from '../../components/polls';
import { Loading } from '../../components';

export const PollEdit = () => {
  const reactUser = AuthUser();

  const [user, setUser] = useState(null);

  useEffect(() => {
    const fetch = async () => {
      const data = await getUserByToken(reactUser.token);
      data.token = reactUser.token;
      setUser(data);
    };

    if (!user)
      fetch();
  }, [reactUser]);

  return (
    <Layout>
      <div className='flex flex-col w-full'>
        {user ? (
          <PollEditComponent user={user} />
        ) : (
          <Loading />
        )}
      </div>
    </Layout>
  );
};
