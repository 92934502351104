import React from 'react';
import {
  ChartBarIcon
} from '@heroicons/react/24/solid';
import { getLanguage } from '../../language/getLanguage';
import { Container } from '../../components';

export const UserProfileSkeleton = () => {

  return (
    <Container>
      <div className='flex flex-row gap-4 items-center animate-pulse'>
        <div>
          <div className='h-20 rounded-full bg-gray-300 w-20 mb-2'/>
        </div>
        <div>
          <div className='h-2.5 rounded-full bg-gray-300 w-36 lg:w-60 md:w-80
            mb-3'/>
          <div className='w-32 h-2 rounded-full bg-gray-400 mb-2'/>
          <div className='w-36 h-2 rounded-full bg-gray-400'/>
        </div>
      </div>
      <hr className='h-px my-8 bg-gray-400 border-0'/>
      <div className='flex flex-col gap-5'>
        <div className='flex flex-col gap-4 w-full'>
          <h1 className='text-2xl font-bold text-white'>
            {getLanguage('user_informations_title')}
          </h1>
          <ul className='flex flex-col gap-6 animate-pulse'>
            <div>
              <div className='h-2.5 rounded-full bg-gray-300 w-60 md:w-80 mb-2'/>
              <div className='w-36 h-2 rounded-full bg-gray-400'/>
            </div>
            <div>
              <div className='h-2.5 rounded-full bg-gray-300 w-60 md:w-80 mb-2'/>
              <div className='w-36 h-2 rounded-full bg-gray-400'/>
            </div>
          </ul>
        </div>
        <div className='flex flex-col gap-4 w-full'>
          <div className='rounded-md border-2 border-gray-500'>
            <div className='bg-gray-500 flex flex-row items-center px-5 py-3'>
              <ChartBarIcon className='h-8 w-8 mr-2 text-white' />
              <div>
                <h1 className='font-medium text-white'>
                  {getLanguage('user_informations_polls_count')}
                </h1>
                <p className='font-regular text-gray-100'>
                  {getLanguage('user_informations_polls_count_description')}
                </p>
              </div>
            </div>
            <div className='flex flex-col px-5 py-3 gap-1'>
              <div className='flex flex-row justify-between'>
                <h1 className='text-white font-medium'>
                  {getLanguage('current_actives_polls')}
                </h1>
                <div className='w-20 h-2 rounded-full bg-gray-400 animate-pulse'/>
              </div>
              <div className='w-full bg-tertiary h-4'>
                <div className='h-4 bg-main-500'
                  style={{width: '0%'}}/>
              </div>
            </div>
            <div className='flex flex-col px-5 py-3 gap-1'>
              <div className='flex flex-row justify-between'>
                <h1 className='text-white font-medium'>
                  {getLanguage('current_actives_polls_recursives')}
                </h1>
                <div className='w-20 h-2 rounded-full bg-gray-400 animate-pulse'/>
              </div>
              <div className='w-full bg-tertiary h-4'>
                <div className='h-4 bg-main-500'
                  style={{width: '0%'}}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
};
