import React from 'react';
import {
  IdentificationIcon,
  DocumentTextIcon
} from '@heroicons/react/24/solid';
import moment from '../../utils/moment';
import PropTypes from 'prop-types';

export const AdminLog = ({index, log, types}) => {
  AdminLog.propTypes = {
    index: PropTypes.number.isRequired,
    log: PropTypes.object.isRequired,
    types: PropTypes.array.isRequired
  };

  return (
    <li key={index} className='mb-10 ml-6'>
      <span className={`absolute flex items-center justify-center w-6 h-6
        rounded-full -left-3 ring-12 ring-secondary-480
        ${types[log.action.id - 1]?.colors.bg}`}>
        <span className={`flex items-center justify-center w-6 h-6
        rounded-full ring-8 ring-opacity-25
        ${types[log.action.id - 1]?.colors.ring}
        ${index === 0 ? 'animate-pulse' : ''}`}>
          {types[log.action.id - 1]?.icon}
        </span>
      </span>
      <div className='ml-2'>
        <h2 className='mb-1 text-lg font-semibold text-white'>
          <a href={`/users/${log.user?.id}`} className='text-main-500
          underline'> {log.user?.username || 'N/A'}
          </a> {types[log.action.id - 1]?.text || 'N/A'}
        </h2>
        <p className='block mb-2 text-sm font-medium leading-none
        text-gray-200'>
          {moment.unix(log.timestamp).format('D, MMMM YYYY [at] HH:mm:ss')}
        </p>
      </div>
      <div className='relative border-l-2 border-gray-400 ml-0 sm:ml-4 pl-3
      gap-1 flex flex-col'>
        {log.actions.map((action, index2) => (
          <>
            {action.type === 'pollId' && (
              <div className='flex text-gray-200 font-regular gap-1'>
                <IdentificationIcon className='w-5 h-5 mr-1'/>
                {action.title}
                <a key={index2} className='text-main-500 font-medium
                underline flex flex-row select-all'
                href={`/polls/${action.value}/results`} >
                  {action.value}
                </a>
              </div>
            )}
            {action.type === 'roleId' && (
              <div className='flex text-gray-200 font-regular gap-1'>
                <IdentificationIcon className='w-5 h-5 mr-1'/>
                {action.title}:
                <a key={index2} className='text-main-500 font-medium
                underline flex flex-row select-all'
                href='/admin/roles' >
                  {action.value}
                </a>
              </div>
            )}
            {action.type === 'userId' && (
              <div className='flex text-gray-200 font-regular gap-1'>
                <IdentificationIcon className='w-5 h-5 mr-1'/>
                {action.title}:
                <a key={index2} className='text-main-500 font-medium
                underline flex flex-row select-all'
                href={`/users/${action.value}`} >
                  {action.value}
                </a>
              </div>
            )}
            {action.type === 'string' && (
              <div key={index2} className='flex flex-wrap text-gray-200
              font-regular gap-1'>
                <DocumentTextIcon className='w-5 h-5 mr-1'/>
                <p className='font-regular whitespace-nowrap'>
                  {action.title}:
                </p>
                <p className='font-medium select-all'>
                  {action.value}
                </p>
              </div>
            )}
          </>
        ))}
      </div>
    </li>
  );
};
