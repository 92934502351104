import { Listbox, Transition } from '@headlessui/react';
import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import {
  ChevronUpDownIcon,
  CheckIcon
} from '@heroicons/react/24/solid';

export const Dropdown = ({ value, onChange, values, title, required, disabled }) => {
  Dropdown.propTypes = {
    value: PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    }).isRequired,
    onChange: PropTypes.func.isRequired,
    values: PropTypes.arrayOf(PropTypes.shape({
      id: PropTypes.number.isRequired,
      text: PropTypes.string.isRequired,
      disabled: PropTypes.bool
    })).isRequired,
    title: PropTypes.string,
    required: PropTypes.bool,
    disabled: PropTypes.bool
  };

  return (
    <div className={`${disabled ? 'opacity-50' : ''}`}>
      {title && (
        <label className='block mb-2 text-sm font-medium text-white
        text-opacity-80'>
          {title}
          {required && <span className='text-red-500 ml-1'>*</span>}
        </label>
      )}
      <Listbox
        value={value}
        onChange={onChange}
        disabled={disabled}
      >
        <Listbox.Button className='relative w-full rounded-lg bg-gray-700 py-2 pl-3 pr-10
        text-left focus:ring-1 ring-main-450 focus-visible:border-main-500 text-opacity-80
        font-medium sm:text-sm text-white h-[42px] border-gray-200/10 border'>
          <span className='block truncate'>{value?.text || 'null'}</span>
          <span className='pointer-events-none absolute inset-y-0 right-0 flex
          items-center pr-2'>
            <ChevronUpDownIcon
              className='h-5 w-5 text-gray-400'
              aria-hidden='true'
            />
          </span>
        </Listbox.Button>
        <Transition
          as={Fragment}
          leave='transition ease-in duration-100'
          leaveFrom='opacity-100'
          leaveTo='opacity-0'
        >
          <Listbox.Options className='absolute mt-1 max-h-60 w-full overflow-auto
            rounded-md bg-gray-700 p-1 text-base shadow-lg
            focus:outline-none sm:text-sm ring-1 ring-main-500 z-10'>
            {values.map((e) => (
              <Listbox.Option
                key={e.id} value={e} disabled={e.disabled}
                className={({ active }) =>
                  `relative cursor-pointer select-none pr-4 font-medium
                  text-white text-opacity-80 py-2 pl-3 transition-colors
                  rounded-md ${active ? 'bg-gray-600' : ''}`
                }
              >
                {({ selected }) => (
                  <div className='flex flex-row'>
                    <span className={`block truncate
                      ${selected ? 'font-medium' : 'font-normal'}`}>
                      {e.text}
                    </span>
                    {selected ? (
                      <CheckIcon className='h-5 w-5 inline-block ml-2'
                        aria-hidden='true' />
                    ) : null}
                  </div>
                )}
              </Listbox.Option>
            ))}
          </Listbox.Options>
        </Transition>
      </Listbox>
    </div>
  );
};

export default Dropdown;
