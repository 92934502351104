import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import {
  BriefcaseIcon,
  UserIcon,
  ChartBarIcon,
  Cog6ToothIcon
} from '@heroicons/react/24/solid';
import { updateRoles } from '../../../utils';

import { Button, Container, CustomAlert, SectionTitle } from '../../../components';

export const AdminRolesPermissions = ({ roles, scopes, user, serverRoles }) => {
  AdminRolesPermissions.propTypes = {
    roles: PropTypes.array.isRequired,
    scopes: PropTypes.array.isRequired,
    user: PropTypes.object.isRequired,
    serverRoles: PropTypes.array.isRequired
  };

  const [alert, setAlert] = useState({
    message: '',
    type: ''
  });
  const [permissions, setPermissions] = useState([]);
  const [maxPolls, setMaxPolls] = useState([]);
  const [maxRecurrentPolls, setMaxRecurrentPolls] = useState([]);
  const [colors, setColors] = useState([]);
  const [discordRoles, setDiscordRoles] = useState([]);
  useEffect(() => {
    setPermissions(initializePermissions());
    setMaxPolls(initializeMaxPolls());
    setMaxRecurrentPolls(initializeMaxRecurrentPolls());
    setColors(initializeColors());
    setDiscordRoles(initializeRoles());
  }, [roles, scopes]);

  const showAlert = (message, type) => {
    setAlert({ message, type });
    setTimeout(() => {
      setAlert({ message: '', type: '' });
    }, 8000);
  };

  const initializePermissions = () => {
    return scopes.map((section) => {
      return section.scopes.map((scope) => {
        return roles.map((role) => {
          return role.scopes.includes(scope);
        });
      });
    });
  };

  const initializeMaxPolls = () => {
    return roles.map((role) => {
      return role.max_polls;
    });
  };

  const initializeMaxRecurrentPolls = () => {
    return roles.map((role) => {
      return role.max_recurrent_polls;
    });
  };

  const initializeColors = () => {
    return roles.map((role) => {
      return role.color;
    });
  };

  const initializeRoles = () => {
    return roles.map((role) => {
      return role.discordRole || '';
    });
  };

  const handleChange = (sectionIndex, scopeIndex, roleIndex) => {
    let newPermissions = [...permissions];
    newPermissions[sectionIndex][scopeIndex][roleIndex] =
      !newPermissions[sectionIndex][scopeIndex][roleIndex];
    setPermissions(newPermissions);
  };

  async function savePermissions() {
    let newRoles = [...roles];
    newRoles.forEach((role, roleIndex) => {
      let newScopes = [];
      scopes.forEach((section, sectionIndex) => {
        section.scopes.forEach((scope, scopeIndex) => {
          if (permissions[sectionIndex][scopeIndex][roleIndex]) {
            newScopes.push(scope);
          }
        });
      });
      newRoles[roleIndex].scopes = newScopes;
      newRoles[roleIndex].max_polls = maxPolls[roleIndex];
      newRoles[roleIndex].max_recurrent_polls = maxRecurrentPolls[roleIndex];
      newRoles[roleIndex].color = colors[roleIndex];
      newRoles[roleIndex].discordRole = discordRoles[roleIndex] === '' ?
        null : discordRoles[roleIndex];
    });
    const result = await updateRoles(newRoles, user.token);
    if (result.status === 200) {
      showAlert('Roles updated successfully', 'success');
    } else {
      showAlert('Error updating roles. Message: ' + result.data.message, 'alert');
      console.info(result);
    }
  }

  function hasScope (user, scope) {
    const userRoles = user.roles;

    for (let i = 0; i < userRoles.length; i++) {
      const role = userRoles[i];
      if (!role.scopes)
        continue;
      if (role.scopes.includes(scope))
        return true;
    }

    return false;
  }

  function smallestRole(roles) {
    return roles.reduce((prev, curr) => {
      return prev.id < curr.id ? prev : curr;
    });
  }

  let icons = [
    <BriefcaseIcon key='1' className='w-6 h-6 mr-2 text-main-500' />,
    <UserIcon key='2' className='w-6 h-6 mr-2 text-main-500' />,
    <ChartBarIcon key='3' className='w-6 h-6 mr-2 text-main-500' />,
    <Cog6ToothIcon key='4' className='w-6 h-6 mr-2 text-main-500' />
  ];

  return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
      overflow-auto min-h-screen'>
      <SectionTitle title='Roles permissions' subtitle='Table of all roles
        and their permissions for each scope.' />
      <Container>
        {permissions.length >= 1 && (
          <div className='relative overflow-x-auto shadow-md'>
            <table className='w-full'>
              <thead className='text-xs bg-gray-50'>
                <tr>
                  <th className='text-left text-base text-white px-4 py-3
                  font-regular sticky left-0 bg-secondary-500'>
                    Scopes
                  </th>
                  {roles.map((role, index) => (
                    <th key={index} className='text-center font-medium px-4'>
                      <span key={index} className='px-2 rounded-md text-base'
                        style={{ backgroundColor: role.color + 35,
                          color: role.color }}>
                        {role.name}:{role.id}
                      </span>
                    </th>
                  ))}
                </tr>
              </thead>
              <tbody>
                {scopes.map((section, index) => (
                  <React.Fragment key={index}>
                    <tr>
                      <td colSpan={roles.length + 1} className='text-left text-base
                      border-t border-b py-3 px-4 text-white bg-tertiary
                      border-gray-500 sticky left-0'>
                        <span className='flex flex-row font-semiBold sticky
                        left-0'>
                          {icons[index]} {section.name}
                        </span>
                      </td>
                    </tr>
                    {section.scopes.map((scope, scopeIndex) => (
                      <tr key={scopeIndex}>
                        <td className='text-left text-base py-3 px-4 text-white
                        font-regular sticky left-0 bg-secondary-500'>
                          {scope}
                        </td>
                        {roles.map((role, roleIndex) => (
                          <td key={roleIndex} className='text-center text-base
                          text-gray-200'>
                            <input type='checkbox' className='form-checkbox
                            w-4 h-4 text-main-600 rounded focus:ring-main-500
                            dark:focus:ring-main-600 ring-offset-gray-800
                            focus:ring-2 bg-gray-700 border-gray-600'
                            name={role.name} disabled={smallestRole(user.roles).id
                            >= role.id || !hasScope(user, scope) ||
                            !hasScope(user, 'UPDATE_ROLES')}
                            checked={permissions[index][scopeIndex][roleIndex]}
                            onChange={() => handleChange(index, scopeIndex,
                              roleIndex)}
                            />
                          </td>
                        ))}
                      </tr>
                    ))}
                  </React.Fragment>
                ))}
                <tr>
                  <td colSpan={roles.length + 1} className='text-left text-base
                  border-t border-b py-3 px-4 text-white bg-tertiary
                  border-gray-500 sticky left-0'>
                    <span className='flex flex-row font-semiBold sticky left-0'>
                      {icons[icons.length - 1]} Other settings
                    </span>
                  </td>
                </tr>
                <tr>
                  <td className='text-left text-base py-3 px-4 text-white
                  font-regular sticky left-0 bg-secondary-500'>
                    Max Polls
                  </td>
                  {roles.map((role, roleIndex) => (
                    <td key={roleIndex} className='text-center text-base
                    text-gray-200'>
                      <input type='number' className='bg-gray-50 border
                      text-sm rounded-lg block p-2.5 w-32 mx-auto bg-tertiary
                      border-gray-600
                      placeholder-gray-400 text-white focus:ring-main-500
                      focus:border-main-500' placeholder='Number'
                      name={role.name} value={maxPolls[roleIndex] || undefined}
                      disabled={smallestRole(user.roles).id >= role.id ||
                      !hasScope(user, 'UPDATE_ROLES')}
                      onChange={(e) => {
                        let newMaxPolls = [...maxPolls];
                        newMaxPolls[roleIndex] = e.target.value;
                        setMaxPolls(newMaxPolls);
                      }}
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className='text-left text-base py-3 px-4 text-white
                  font-regular sticky left-0 bg-secondary-500'>
                    Max Recurrent Polls
                  </td>
                  {roles.map((role, roleIndex) => (
                    <td key={roleIndex} className='text-center text-base
                    text-gray-200'>
                      <input type='number' className='bg-gray-50 border
                      text-sm rounded-lg block p-2.5 w-32 mx-auto bg-tertiary
                      border-gray-600
                      placeholder-gray-400 text-white focus:ring-main-500
                      focus:border-main-500' placeholder='Number'
                      name={role.name}
                      value={maxRecurrentPolls[roleIndex] || undefined}
                      disabled={smallestRole(user.roles).id >= role.id ||
                      !hasScope(user, 'UPDATE_ROLES')}
                      onChange={(e) => {
                        let newMaxPolls = [...maxRecurrentPolls];
                        newMaxPolls[roleIndex] = e.target.value;
                        setMaxRecurrentPolls(newMaxPolls);
                      }}
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className='text-left text-base py-3 px-4 text-white
                  font-regular sticky left-0 bg-secondary-500'>
                    Color
                  </td>
                  {roles.map((role, roleIndex) => (
                    <td key={roleIndex} className='text-center text-base
                    text-gray-200'>
                      <input type='color' className='mx-auto bg-tertiary
                      text-white focus:ring-main-500 focus:border-main-500'
                      name={role.name} value={colors[roleIndex]}
                      disabled={smallestRole(user.roles).id >= role.id ||
                      !hasScope(user, 'UPDATE_ROLES')}
                      onChange={(e) => {
                        let newColors = [...colors];
                        newColors[roleIndex] = e.target.value;
                        setColors(newColors);
                      }}
                      />
                    </td>
                  ))}
                </tr>
                <tr>
                  <td className='text-left text-base py-3 px-4 text-white
                  font-regular sticky left-0 bg-secondary-500'>
                    Discord Role
                  </td>
                  {roles.map((role, roleIndex) => (
                    <td key={roleIndex} className='text-center text-base
                    text-gray-200 px-2'>
                      <select className='bg-gray-50 border text-sm rounded-lg block
                      p-2.5 bg-gray-700 border-gray-600 placeholder-gray-400
                      mx-auto text-white focus:ring-main-500 focus:border-main-500
                      w-48'
                      name={role.name} value={discordRoles[roleIndex] || ''}
                      disabled={smallestRole(user.roles).id >= role.id ||
                      !hasScope(user, 'UPDATE_ROLES')}
                      onChange={(e) => {
                        let newRoles = [...discordRoles];
                        newRoles[roleIndex] = e.target.value;
                        setDiscordRoles(newRoles);
                      }}>
                        <option value=''>None</option>
                        {serverRoles.map((discordRole, index) => (
                          <option key={index} value={discordRole.id}>
                            {discordRole.name}
                          </option>
                        ))}
                      </select>
                    </td>
                  ))}
                </tr>
              </tbody>
            </table>
          </div>
        )}
        <Button type='primary' className='mt-5'
          onClick={() => {
            savePermissions();
          }}>
          Update
        </Button>
        {alert.message && (
          <CustomAlert message={alert.message} type={alert.type}
            title='Saving settings...'
            className='fixed bottom-10 right-4 left-4 z-50' />
        )}
      </Container>
    </div>
  );
};

