const SI_SYMBOL = ['', '', 'M', 'B', 'T', 'P', 'E'];

export function formatNumberWithLetter(number) {
  if (number < 10000 )
    return number;

  const absNumber = Math.abs(number);

  const siIndex = Math.floor(Math.log10(absNumber) / 3);

  const suffix = SI_SYMBOL[siIndex];

  let convertedNumber = absNumber / Math.pow(10, siIndex * 3);

  if (number >= 10000)
    convertedNumber = convertedNumber.toFixed(4);

  const result = number < 0 ?
    `-${convertedNumber}${suffix}` : `${convertedNumber}${suffix}`;

  return result;
}
