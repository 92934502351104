import { Popover }  from '@headlessui/react';
import {
  ArrowPathIcon,
  EllipsisHorizontalIcon,
  ExclamationTriangleIcon
} from '@heroicons/react/24/solid';
import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { getLanguage } from '../language/getLanguage';

const initializeState = (values) => {
  return values.map(() => 0);
};

export const PopHover = ({ values }) => {
  PopHover.propTypes = {
    values: PropTypes.arrayOf(PropTypes.shape({
      name: PropTypes.string.isRequired,
      icon: PropTypes.element,
      separator: PropTypes.bool,
      closeDelay: PropTypes.number,
      colors: PropTypes.shape({
        danger: PropTypes.bool,
        success: PropTypes.bool
      }),
      disabled: PropTypes.bool,
      hidden: PropTypes.bool,
      onClick: PropTypes.func.isRequired
    })).isRequired
  };

  const [state, setState] = useState(initializeState(values));
  const [open, setOpen] = useState(false);

  const handleClick = async (index) => {
    const newState = [...state];
    if (state[index] !== 0) {
      newState[index] = 0;
      setState(newState);
      return;
    }
    newState[index] = 1;
    setState(newState);
    const result = await values[index].onClick();

    setState((prevState) => {
      const newState = [...prevState];
      if (result === 0) {
        newState[index] = 2;
      } else {
        setTimeout(() => {
          setOpen(false);
        }, (values[index].closeDelay ?? 1000));
        newState[index] = 0;
      }
      return newState;
    });
  };

  const ref = useRef(null);

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      setOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  });

  return (
    <Popover className='relative' ref={ref}>
      <button className='text-gray-100 cursor-pointer p-1 hover:bg-gray-700
      rounded-lg transition-all duration-100 outline-none'
      onClick={() => setOpen(!open)}>
        <EllipsisHorizontalIcon className='w-6 h-6' />
      </button>
      {open && (
        <Popover.Panel static className='absolute right-0 flex flex-col gap-1 mt-1
        rounded-md bg-gray-700 p-1 text-base shadow-lg h-fit ring-gray-500 ring-1
        focus:outline-none sm:text-sm min-w-[200px] md:min-w-[250px] z-10'>
          {values.filter((value) => !value.hidden).map((value, index) => (
            <div key={index}>
              {value.separator && (
                <hr className='border-gray-500 mb-1 mx-1' />
              )}
              <button key={index} onClick={() => handleClick(index)}
                disabled={state[index] === 1 || value.disabled || value.hidden}
                className={`relative cursor-pointer select-none pr-4 font-medium
                py-2 px-3 flex flex-row gap-2 items-center rounded-md
                hover:bg-gray-600 w-full disabled:hover:bg-gray-700
                disabled:cursor-not-allowed transition-colors disabled:opacity-50
                ${value.colors?.danger ? 'text-red-500' :
              value.colors?.success ? 'text-green-500' : 'text-white/80'} `}>
                {state[index] === 2 && (
                  <>
                    <ExclamationTriangleIcon className='w-5 h-5' />
                    {getLanguage('error')}
                  </>
                )}
                {state[index] === 1 && (
                  <ArrowPathIcon className='w-5 h-5 animate-spin mx-auto' />
                )}
                {state[index] === 0 && (
                  <div className='flex flex-row items-center gap-1'>
                    {value.icon}
                    <p className='whitespace-nowrap'>
                      {value.name}
                    </p>
                  </div>
                )}
              </button>
            </div>
          ))}
        </Popover.Panel>
      )}
    </Popover>
  );
};

export default PopHover;
