import React from 'react';

import { Layout } from '../Layout';
import { PollInformations } from '../../components/polls';

import { AuthUser } from '../../utils';

export const PollResults = () => {
  const user = AuthUser();

  return (
    <Layout>
      <div className='flex flex-col w-full'>
        <PollInformations user={user} />
      </div>
    </Layout>
  );
};
