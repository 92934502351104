import React from 'react';

import { getLanguage } from '../../language/getLanguage';
import { Container, SectionTitle } from '../../components';

export const UserDataSkeleton = () => {

  return (
    <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10 min-h-screen'>
      <SectionTitle title={getLanguage('user_account_title')}
        subtitle={getLanguage('user_account_subtitle')} />
      <Container>
        <div className='flex flex-row gap-4 items-center'>
          <div className='rounded-full w-24 h-24
          bg-gray-480 flex items-center flex-shrink-0
          animate-pulse'>
          </div>
          <div className='flex flex-col items-start w-full'>
            <div className='flex-row justify-between flex gap-10 mr-10
            animate-pulse w-full'>
              <div className='h-2.5 rounded-full bg-gray-400 w-1/3 max-w-[300px]'/>
            </div>
            <div className='flex-row justify-between flex gap-10 mr-10 mt-5
            animate-pulse w-full'>
              <div className='h-2.5 rounded-full bg-gray-480 w-1/4 max-w-[150px]'/>
            </div>
          </div>
        </div>
        <hr className='h-px my-8 bg-gray-400 border-0'/>
        <div className='flex flex-col gap-4'>
          <h1 className='text-2xl font-bold text-white'>
            {getLanguage('user_informations')}
          </h1>
          <ul className='flex flex-col gap-4'>
            <li className='text-lg text-white flex flex-row items-center
            justify-between'>
              <h2 className='font-regular'>
                {getLanguage('user_informations_username')}
              </h2>
              <div className='flex-row justify-between flex gap-10 animate-pulse'>
                <div className='h-2.5 rounded-full bg-gray-400 w-20 sm:w-40'/>
              </div>
            </li>
            <li className='text-lg text-white flex flex-row items-center
            justify-between'>
              <h2 className='font-regular'>
                {getLanguage('user_informations_id')}
              </h2>
              <div className='flex-row justify-between flex gap-10 animate-pulse'>
                <div className='h-2.5 rounded-full bg-gray-400 w-24 sm:w-44'/>
              </div>
            </li>
            <li className='text-lg text-white flex flex-row items-center
            justify-between'>
              <h2 className='font-regular'>
                {getLanguage('user_informations_roles')}
              </h2>
              <div className='flex-row justify-between flex gap-6 animate-pulse'>
                <div className='h-2.5 rounded-full bg-gray-400 w-12'/>
                <div className='h-2.5 rounded-full bg-gray-400 w-14 hidden
                sm:flex'/>
                <div className='h-2.5 rounded-full bg-gray-400 w-8'/>
              </div>
            </li>
            <li className='text-lg text-white flex flex-row items-center
            justify-between'>
              <h2 className='font-regular'>
                {getLanguage('user_informations_email')}
              </h2>
              <div className='flex-row justify-between flex gap-10 animate-pulse'>
                <div className='h-2.5 rounded-full bg-gray-400 w-20 sm:w-40'/>
              </div>
            </li>
            <li className='text-lg text-white flex flex-row items-center
            justify-between'>
              <h2 className='font-regular'>
                {getLanguage('user_informations_stripe_id')}
              </h2>
              <div className='flex-row justify-between flex gap-10 animate-pulse'>
                <div className='h-2.5 rounded-full bg-gray-400 w-16 sm:w-48'/>
              </div>
            </li>
          </ul>
          <h1 className='text-2xl font-bold text-white mt-5'>
            {getLanguage('user_data')}
          </h1>
          <ul className='flex flex-col gap-4'>
            <li className='text-lg text-white flex flex-row items-center
            justify-between'>
              <h2 className='font-regular'>
                {getLanguage('user_data_private')}
              </h2>
              <div className='flex-row justify-between flex gap-10 animate-pulse'>
                <div className='h-8 rounded-full bg-gray-480 w-20 sm:w-20'/>
              </div>
            </li>
            <li className='text-lg text-white flex flex-row items-center
            justify-between'>
              <h2 className='font-regular'>
                {getLanguage('user_data_account')}
              </h2>
              <div className='flex-row justify-between flex gap-10 animate-pulse'>
                <div className='h-8 rounded-full bg-gray-480 w-20 sm:w-40'/>
              </div>
            </li>
            <li className='text-lg text-white flex flex-row items-center
            justify-between'>
              <h2 className='font-regular'>
                {getLanguage('user_data_polls')}
              </h2>
              <div className='flex-row justify-between flex gap-10 animate-pulse'>
                <div className='h-8 rounded-full bg-gray-480 w-20 sm:w-40'/>
              </div>
            </li>
          </ul>
        </div>
      </Container>
    </div>
  );
};
