import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js/auto';
import canvaOptions from './canvaOptions.json';

export const AdminStatsGraph = ({ range, labels, datasets }) => {
  AdminStatsGraph.propTypes = {
    range: PropTypes.number.isRequired,
    labels: PropTypes.array.isRequired,
    datasets: PropTypes.array.isRequired
  };

  const chartRef = useRef(null);

  useEffect(() => {
    const ctx = chartRef.current.getContext('2d');

    const datas = {
      labels: labels.slice(labels.length - range, labels.length),
      datasets: datasets.map((dataset) => {
        return {
          ...dataset,
          data: dataset.data.slice(dataset.data.length - range, dataset.data.length)
        };
      })
    };

    const customBackground = {
      id: 'customCanvasBackgroundColor',
      beforeDraw: (chart, args, options) => {
        const {ctx} = chart;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = options.color || 'white';
        ctx.fillRect(0, 0, chart.width, chart.height);
        ctx.restore();
      }
    };

    const chartConfig = {
      type: 'line',
      data: datas,
      options: canvaOptions,
      plugins: [ customBackground ]
    };

    const chartInstance = new Chart(ctx, chartConfig);
    return () => {
      chartInstance.destroy();
    };
  }, [range]);

  return (
    <div className='flex flex-col bg-secondary-500 p-2 rounded-lg
    ring-1 ring-tertiary gap-1 w-max h-max md:h-96 flex-shrink-0'>
      <canvas ref={chartRef} className='h-full w-full' />
    </div>
  );
};
