import axios from 'axios';

export async function votePoll(pollId, choiceId, token) {
  let config = {
    method: 'put',
    maxBodyLength: Infinity,
    url: `https://api.coodo.xyz/polls/${pollId}/vote/${choiceId}`,
    headers: {
      'Content-Type': 'application/json',
      'Authorization': 'Bearer ' + token
    }
  };

  let response = await axios.request(config).then((response) => {
    return response;
  }).catch((error) => {
    return error.response;
  });

  return response;
}
