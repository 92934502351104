import React, { useState, useEffect } from 'react';

import { Layout } from '../../Layout';
import { PollsTable } from '../../../components/polls';
import { AuthUser, getFavorites } from '../../../utils';
import { getLanguage } from '../../../language/getLanguage';

export const Favorites = () => {
  const [polls, setPolls] = useState([]);
  const user = AuthUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPolls = async () => {
      const polls = await getFavorites(user.token);
      polls.reverse();
      setPolls(polls);
      setIsLoading(false);
    };
    fetchPolls();
  }, [user]);
  return (
    <Layout>
      <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
        overflow-auto min-h-screen'>
        <PollsTable polls={polls} title={getLanguage('favorites_polls')}
          description={getLanguage('favorites_polls_description')}
          emptyMessage={getLanguage('favorites_polls_empty')}
          loading={isLoading} user={user} />
      </div>
    </Layout>
  );
};
