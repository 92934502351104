import React from 'react';
import {
  BoltIcon
} from '@heroicons/react/24/solid';
import PropTypes from 'prop-types';
import { getLanguage } from '../../language/getLanguage';

export const PremiumBadge = ({ className }) => {
  PremiumBadge.propTypes = {
    className: PropTypes.string
  };
  return (
    <span className={`text-xs font-medium px-2 py-1
    rounded-full flex items-center w-fit gap-1 bg-premium-500/20
      text-premium-500 h-fit ${className} whitespace-nowrap`}>
      <div className='shrink-0 h-4 w-4'>
        <BoltIcon />
      </div>
      {getLanguage('premium')}
    </span>
  );
};

export default PremiumBadge;
