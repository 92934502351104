import { useAuthUser } from 'react-auth-kit';

export function AuthUser() {
  const auth = useAuthUser();
  const user = auth();
  const origin = window.location.origin;

  if (!user) {
    if (window.location.pathname !== '/')
      return window.location.replace(origin + '?state=' + window.location.href);
    return null;
  }
  if (!user.id || !user.token) {
    if (window.location.pathname !== '/')
      return window.location.replace(origin + '?state=' + window.location.href);
    return null;
  }

  return user;
}
