import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import {
  DocumentDuplicateIcon,
  ShareIcon,
  XMarkIcon
} from '@heroicons/react/24/solid';
import { getLanguage } from '../../language/getLanguage';
import { Button } from '../Button';

export const ShareModal = ({ children, title, close, options }) => {
  ShareModal.propTypes = {
    children: PropTypes.node,
    title: PropTypes.string.isRequired,
    close: PropTypes.func,
    options: PropTypes.shape({
      twitter: PropTypes.bool,
      mail: PropTypes.bool,
      link: PropTypes.string.isRequired
    })
  };

  const ref = useRef();

  const handleCopy = () => {
    navigator.clipboard.writeText(options.link);
    setCopyText(getLanguage('copied'));
    setTimeout(() => {
      setCopyText(getLanguage('copy_link'));
    }, 3500);
  };

  const [copyText, setCopyText] = useState(getLanguage('copy_link'));

  const handleClickOutside = (event) => {
    if (ref.current && !ref.current.contains(event.target)) {
      close();
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  return (
    <>
      <div className='fixed top-0 left-0 right-0 bottom-0
      bg-gray-1000 bg-opacity-50 backdrop-filter backdrop-blur-sm z-50'/>
      <div className='fixed left-0 right-0 top-0 z-50 p-4 animate-fade-in transition-all
      duration-300 w-full h-full flex justify-center'>
        <div className='relative w-fit min-w-0 sm:min-w-[600px] max-w-lg
        max-h-full m-auto' ref={ref}>
          <div className='relative bg-secondary-500 rounded-lg shadow ring-1
          ring-secondary-450 pb-6 px-6 pt-4 text-center flex flex-col
            items-center'>
            <div className='flex flex-row justify-end items-center w-full h-fit'>
              <div className='flex flex-row w-full items-center'>
                <ShareIcon className='w-6 h-6 text-white mr-2'/>
                <p className='text-lg font-regular text-white'>
                  {title}
                </p>
              </div>
              {close && (
                <div className='flex flex-row justify-end items-center h-fit'>
                  <button className='p-2 text-white text-opacity-80 hover:text-opacity-100
                  transition-colors duration-300 rounded-lg'
                  onClick={() => close()}>
                    <XMarkIcon className='w-6 h-6'/>
                  </button>
                </div>
              )}
            </div>
            <hr className='w-full border-secondary-450 my-4'/>
            <div className='flex flex-col w-full text-left'>
              <h3 className='text-lg font-medium text-white text-opacity'>
                {getLanguage('share_link')}
              </h3>
              <p className='text-sm font-regular text-white text-opacity-80 mb-2'>
                {getLanguage('share_link_desc')}
              </p>
            </div>
            <div className='flex w-full bg-gray-700 rounded-lg'>
              <div className='relative flex w-full'>
                <input type='text' className='text-sm rounded-lg block w-full p-2.5
                bg-gray-700 border-gray-200/10 placeholder-gray-400 text-white
              focus:ring-main-500 outline-none focus:border-main-500 disabled:opacity-50'
                value={options.link} readOnly/>
              </div>
              <button className='-ml-px relative transition-all inline-flex items-center
              px-4 py-2 text-sm font-medium rounded-r-md text-white bg-tertiary
              hover:bg-gray-600 focus:outline-none focus:ring-1 focus:ring-main-500
              focus:border-main-500 flex-shrink-0 border border-tertiary'
              onClick={() => handleCopy()}>
                <DocumentDuplicateIcon className='w-5 h-5 mr-2'/>
                {copyText}
              </button>
            </div>
            <hr className='w-full border-secondary-450 my-4'/>
            <div className='flex flex-col w-full text-left'>
              <h3 className='text-lg font-medium text-white text-opacity'>
                {getLanguage('share_social')}
              </h3>
              <p className='text-sm font-regular text-white text-opacity-80 mb-2'>
                {getLanguage('share_social_desc')}
              </p>
            </div>
            <div className='flex flex-col w-full gap-2'>
              {options.twitter && (
                <Button className='bg-[#1DA1F2] hover:bg-[#1A91DA] w-full text-white'
                  onClick={() => {
                    window.open(`https://twitter.com/intent/tweet?text=${options.link}`);
                  }}>
                  Twitter
                </Button>
              )}
              {options.mail && (
                <Button className='bg-[#D44638] hover:bg-[#C13A2D] w-full text-white'
                  onClick={() => {
                    window.open(`mailto:?subject=${getLanguage('share_mail_subject')}\
                    &body=${options.link}`);
                  }}>
                  Mail
                </Button>
              )}
            </div>
            <div className='w-full'>
              {children}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default ShareModal;
