import React, { useEffect, useState } from 'react';

import {
  AuthUser,
  hasScope,
  getUserByToken,
  getStats,
  getGradient,
  hexToRGBA
} from '../../../utils';

import { Loading, CustomAlert, SectionTitle, Dropdown } from '../../../components';

import { Forbidden } from '../../Forbidden';
import { Layout } from '../../Layout';

import { AdminStatsGraph } from './AdminStatsGraph';
import { AdminStatsCard } from './AdminStatsCard';

const ranges = [
  { id: 7, text: '7 Days' },
  { id: 14, text: '14 Days' },
  { id: 30, text: '30 Days' },
  { id: 60, text: '60 Days' },
  { id: 90, text: '90 Days' }
];

export const AdminStats = () => {
  const reactUser = AuthUser();

  const [user, setUser] = useState(reactUser);
  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [stats, setStats] = useState({});
  const [range, setRange] = useState(ranges[1]);

  useEffect(() => {
    const fetch = async () => {
      if (await hasScope(reactUser, 'ADMIN_PANEL') === true &&
        await hasScope(reactUser, 'ADMIN_STATS') === true) {
        setIsAuthorized(true);
        fetchUser();
      } else {
        setIsAuthorized(false);
        setIsLoading(false);
      }
    };

    const fetchUser = async () => {
      const user = await getUserByToken(reactUser.token);
      user.token = reactUser.token;
      setUser(user);
      fetchStats();
    };

    const fetchStats = async () => {
      let stats = await getStats(user.token);
      stats = stats.reverse();
      setStats(stats);
      setIsLoading(false);
    };

    if (!reactUser) {
      setIsAuthorized(false);
      setIsLoading(false);
    } else {
      fetch();
    }
  }, [reactUser]);

  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : isAuthorized ? (
        <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
        overflow-auto min-h-screen'>
          <SectionTitle title='Coodo.xyz stats' subtitle='Global statistics
          of the platform.' />
          <CustomAlert type='info' title='Stats update'
            message='Stats are updated every day at PM 9:42 UTC'
            button='close' />
          <div className='relative'>
            <Dropdown value={range} onChange={setRange} values={ranges} />
          </div>
          <div className='flex flex-row gap-4 justify-between flex-wrap 2xl:flex-nowrap'>
            <AdminStatsGraph labels={stats.map(e => e.date.slice(0, -14))}
              range={range.id}
              datasets={[
                {
                  label: 'Guilds',
                  data: stats.map((stat) => {
                    return stat.guildsSize;
                  }),
                  borderColor: '#4f4fff',
                  backgroundColor: function(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea)
                      return null;
                    return getGradient(ctx, chartArea, chart, 0);
                  }
                },
                {
                  label: 'Users',
                  hidden: true,
                  data: stats.map((stat) => {
                    return stat.dashbordUsersSize;
                  }),
                  borderColor: '#ed4245',
                  backgroundColor: function(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea)
                      return null;
                    return getGradient(ctx, chartArea, chart, 1);
                  }
                },
                {
                  label: 'Users in Guilds',
                  hidden: true,
                  data: stats.map((stat) => {
                    return stat.usersSize;
                  }),
                  borderColor: '#f47fff',
                  backgroundColor: function(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea)
                      return null;
                    return getGradient(ctx, chartArea, chart, 2);
                  }
                }
              ]} />
            <div className='w-full h-fit flex flex-row gap-4 flex-wrap'>
              <div className='flex flex-row w-full gap-4 flex-wrap 2xl:flex-nowrap'>
                <AdminStatsCard stats={stats.map(stat => stat.dashbordUsersSize)}
                  title='Users Count' range={range.id} />
                <AdminStatsCard stats={stats.map(stat => stat.guildsSize)}
                  title='Guilds Count' range={range.id} />
              </div>
              <div className='flex flex-row w-full gap-4 flex-wrap 2xl:flex-nowrap'>
                <AdminStatsCard stats={stats.map(stat => stat.usersSizeSupport)}
                  title='Support Server Members Count' range={range.id} />
                <AdminStatsCard stats={stats.map(stat => stat.usersSize)}
                  title='Users in Guilds Count' range={range.id} />
              </div>
              <AdminStatsCard stats={stats.map(stat => stat.votesCount)}
                title='Votes Count' range={range.id} />
            </div>
          </div>
          <div className='flex flex-row gap-4 justify-between flex-wrap'>
            <AdminStatsGraph labels={stats.map(e => e.date.slice(0, -14))}
              range={range.id}
              datasets={[
                {
                  label: 'Guilds Added',
                  type: 'bar',
                  data: stats.map((stat) => {
                    return parseInt(stat.guilds?.added);
                  }),
                  borderColor: '#20BF55',
                  borderWidth: 3,
                  borderRadius: 5,
                  backgroundColor: function(context) {
                    const chart = context.chart;
                    const borderColor = chart.data.datasets[0].borderColor;
                    return hexToRGBA(borderColor, 0.2);
                  }
                },
                {
                  label: 'Guilds Removed',
                  type: 'bar',
                  data: stats.map((stat) => {
                    return parseInt(stat.guilds?.removed);
                  }),
                  borderColor: '#ed4245',
                  borderWidth: 3,
                  borderRadius: 5,
                  backgroundColor: function(context) {
                    const chart = context.chart;
                    const borderColor = chart.data.datasets[1].borderColor;
                    return hexToRGBA(borderColor, 0.2);
                  }
                }
              ]} />
            <AdminStatsGraph labels={stats.map(e => e.date.slice(0, -14))}
              range={range.id}
              datasets={[
                {
                  label: 'Support Server Members',
                  hidden: false,
                  data: stats.map((stat) => {
                    return stat.usersSizeSupport;
                  }),
                  borderColor: '#20BF55',
                  backgroundColor: function(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea)
                      return null;
                    return getGradient(ctx, chartArea, chart, 0);
                  }
                },
                {
                  label: 'Premium Users',
                  hidden: true,
                  data: stats.map((stat) => {
                    return stat.premiumSize;
                  }),
                  borderColor: '#FFD24F',
                  backgroundColor: function(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea)
                      return null;
                    return getGradient(ctx, chartArea, chart, 1);
                  }
                },
                {
                  label: 'Votes Count',
                  hidden: true,
                  data: stats.map((stat) => {
                    return stat.votesCount;
                  }),
                  borderColor: '#e67e22',
                  backgroundColor: function(context) {
                    const chart = context.chart;
                    const { ctx, chartArea } = chart;
                    if (!chartArea)
                      return null;
                    return getGradient(ctx, chartArea, chart, 2);
                  }
                }
              ]} />
          </div>
        </div>
      ) : (
        <Forbidden />
      )}
    </Layout>
  );
};
