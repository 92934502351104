import React, { useState, useEffect } from 'react';
import { PollsTable } from '../../../components/polls';
import { getUserPolls, AuthUser } from '../../../utils';
import { Layout } from '../../Layout';
import { getLanguage } from '../../../language/getLanguage';

export const Polls = () => {
  const [polls, setPolls] = useState([]);
  const user = AuthUser();
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchPolls = async () => {
      const polls = await getUserPolls(user.id, user.token);
      polls.reverse();
      setPolls(polls);
      setIsLoading(false);
    };
    fetchPolls();
  }, [user]);
  return (
    <Layout>
      <div className='flex flex-col flex-1 p-5 lg:p-10
        overflow-auto min-h-screen'>
        <PollsTable polls={polls} title={getLanguage('my_polls')}
          description={getLanguage('my_polls_description')}
          emptyMessage={getLanguage('my_polls_empty')} loading={isLoading}
          user={user} />
      </div>
    </Layout>
  );
};
