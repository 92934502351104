import React, { useEffect, useState } from 'react';

import { AuthUser, hasScope } from '../../utils';

import { Button, Container, Loading, SectionTitle } from '../../components';

import { Forbidden, Layout } from '../../pages';

import {
  Cog6ToothIcon,
  ChartPieIcon,
  CommandLineIcon
} from '@heroicons/react/24/solid';

export const Admin = () => {
  const user = AuthUser();

  const [isAuthorized, setIsAuthorized] = useState(false);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetch = async () => {
      if (await hasScope(user, 'ADMIN_PANEL') === true)
        setIsAuthorized(true);
      else
        setIsAuthorized(false);
      setIsLoading(false);
    };

    if (!user) {
      setIsAuthorized(false);
      setIsLoading(false);
    } else {
      fetch();
    }
  }, [user]);

  return (
    <Layout>
      {isLoading ? (
        <Loading />
      ) : isAuthorized ? (
        <div className='flex flex-col flex-1 gap-6 p-5 lg:p-10
        overflow-auto min-h-screen'>
          <SectionTitle title='Admin Panel' subtitle={`Welcome to the admin
          panel, ${user.username}!`} />
          <Container>
            <div className='flex flex-row flex-wrap gap-10 justify-evenly'>
              <div className='flex flex-col bg-secondary-600 rounded-lg gap-1
              min-w-[250px]'>
                <h1 className='text-2xl font-bold text-white flex flex-row
                items-center'>
                  <Cog6ToothIcon className='w-6 h-6 mr-1'/>
                  Roles
                </h1>
                <p className='text-base text-gray-200'>
                  Manage roles and permissions.
                </p>
                <Button href='/admin/roles' type='primary'>
                  Manage Roles
                </Button>
              </div>
              <div className='flex flex-col bg-secondary-600 rounded-lg gap-1
              min-w-[250px]'>
                <h1 className='text-2xl font-bold text-white flex flex-row
                items-center'>
                  <ChartPieIcon className='w-6 h-6 mr-1'/>
                  Statistics
                </h1>
                <p className='text-base text-gray-200'>
                  View statistics about servicies.
                </p>
                <Button href='/admin/stats' type='primary'>
                  View Statistics
                </Button>
              </div>
              <div className='flex flex-col bg-secondary-600 rounded-lg gap-1
              min-w-[250px]'>
                <h1 className='text-2xl font-bold text-white flex flex-row
                items-center'>
                  <CommandLineIcon className='w-6 h-6 mr-1'/>
                  Logs
                </h1>
                <p className='text-base text-gray-200'>
                  View logs of the API.
                </p>
                <Button href='/admin/logs' type='primary'>
                  View Logs
                </Button>
              </div>
            </div>
          </Container>
        </div>
      ) : (
        <Forbidden />
      )}
    </Layout>
  );
};
